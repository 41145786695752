import {
  VIEW_ID as VIEW_ID_ADMIN,
  CLIENT_APP as CLIENT_APP_ADMIN,
} from './admin/app';
import {
  VIEW_ID as VIEW_ID_DEVICE,
  CLIENT_APP as CLIENT_APP_DEVICE,
} from './device/app';
import {
  VIEW_ID as VIEW_ID_FIRMWARE,
  CLIENT_APP as CLIENT_APP_FIRMWARE,
} from './firmware/app';
import {
  VIEW_ID as VIEW_ID_DATA,
  CLIENT_APP as CLIENT_APP_DATA,
} from './data/app';
import {
  VIEW_ID as VIEW_ID_MONITORING,
  CLIENT_APP as CLIENT_APP_MONITORING,
} from './monitoring/app';
import {
  VIEW_ID as VIEW_ID_STUDY,
  CLIENT_APP as CLIENT_APP_STUDY,
} from './study/app';
import {
  VIEW_ID as VIEW_ID_DH_STUDY,
  CLIENT_APP as CLIENT_APP_DH_STUDY,
} from '@/devicehub/apps/dhStudy/app';
import { has } from '@/util/util';

export interface ClientAppEntry {
  name: string;
  path: string;
  icon: string;
  defaultPathName: string;
  store?: {
    name: string;
    module: unknown;
  };
  mockEndpoints?: unknown[];
}

export interface ClientAppRegistry {
  [viewId: string]: ClientAppEntry;
}

const CLIENT_APP_REGISTRY: ClientAppRegistry = {
  [VIEW_ID_ADMIN]: CLIENT_APP_ADMIN,
  [VIEW_ID_DEVICE]: CLIENT_APP_DEVICE,
  [VIEW_ID_FIRMWARE]: CLIENT_APP_FIRMWARE,
  [VIEW_ID_DATA]: CLIENT_APP_DATA,
  [VIEW_ID_MONITORING]: CLIENT_APP_MONITORING,
  [VIEW_ID_STUDY]: CLIENT_APP_STUDY,
  [VIEW_ID_DH_STUDY]: CLIENT_APP_DH_STUDY,
};

export function clientAppList(): { view_id: string; name: string }[] {
  return Object.keys(CLIENT_APP_REGISTRY).map(key => {
    return {
      view_id: key,
      name: CLIENT_APP_REGISTRY[key].name,
    };
  });
}

export function clientAppMockEndpoints(): unknown[] {
  const endpoints: unknown[] = [];

  for (const viewId in CLIENT_APP_REGISTRY) {
    // Register client app mock endpoints
    if (CLIENT_APP_REGISTRY[viewId].mockEndpoints) {
      endpoints.concat(CLIENT_APP_REGISTRY[viewId].mockEndpoints);
    }
  }

  return endpoints;
}

export function clientAppIsKnown(viewId: string): boolean {
  return has(CLIENT_APP_REGISTRY, viewId);
}

export function clientAppRouteName(viewId: string, routeName: string): string {
  if (clientAppIsKnown(viewId)) {
    return `${viewId}-${routeName}`;
  } else {
    throw new Error(`Client app '${viewId}' is not known.`);
  }
}

export function clientAppRegistryGet<T = string>(
  viewId: string,
  property: string,
): T {
  const entry = CLIENT_APP_REGISTRY[viewId];

  if (!entry) {
    throw new Error(`Client app '${viewId}' is not defined in registry.`);
  }
  if (!has(entry, property)) {
    throw new Error(
      `Property '${property}' is not defined for client app ${viewId}.`,
    );
  }
  return entry[property];
}

export function clientAppDefaultRouteName(viewId: string): string {
  if (clientAppIsKnown(viewId)) {
    return `${viewId}-${clientAppRegistryGet(viewId, 'defaultPathName')}`;
  } else {
    throw new Error(`Client app '${viewId}' is not known.`);
  }
}
