export function downloadBlob(blob: Blob, filename: string): void {
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection, Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    // link.style = 'visibility:hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function convertArrayOfObjectsToCSV(args: {
  data: { [key: string]: any }[];
  columnDelimiter?: string;
  lineDelimiter?: string;
}): string {
  let result;
  let ctr;
  let keys;
  let columnDelimiter;
  let lineDelimiter;
  let data;
  data = args.data || null;
  if (data === null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);
  result = `sep=${columnDelimiter}\n`;
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(item => {
    ctr = 0;
    keys.forEach(key => {
      if (ctr > 0) {
        result += columnDelimiter;
      }

      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(args: { csv?: string; filename?: string }) {
  const csv = args.csv || null;
  if (csv === null) {
    return;
  }

  const filename = args.filename || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  downloadBlob(blob, filename);
}
