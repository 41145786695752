export const admin = {
  role: {
    prettyName: 'Role | Roles',
    fields: {
      name: 'Name',
      description: 'Description',
    },
  },
  user: {
    prettyName: 'User | Users',
  },
  objectauthorization: {
    prettyName: 'Object Authorization | Object Authorizations',
    fields: {
      role_name: 'Role',
      group_name: 'Group',
      object_type: 'Object Type',
      object_name: 'Object Name',
      note: 'Note',
    },
  },
  organisation: {
    prettyName: 'Organisation | Organisations',
    fields: {
      name: 'Name',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      object_state: 'State',
      password_policy: {
        version: 'Version',
        min_length: 'Min Length',
        alpha_upper: 'Alpha Upper',
        alpha_lower: 'Alpha Lower',
        numeric: 'Numeric',
        special_char: 'Special Char',
      },
      meta: {
        email: 'E-Mail',
        phone: 'Phone',
        website: 'Website',
      },
    },
  },
  group: {
    prettyName: 'Group | Groups',
    fields: {
      name: 'Name',
      description: 'Description',
      organisation: 'Organisation',
      profiles: 'Profiles',
    },
  },
  profile: {
    prettyName: 'Profile | Profiles',
    fields: {
      username: 'Username',
      email: 'E-Mail',
      remaining_external_pin_requests: 'Remaining External Pin Requests',
      remaining_external_pin_trials: 'Remaining External Pin Trials',
      remaining_internal_pin_trials: 'Remaining Internal Pin Trials',
      pin_trials: 'Pin Trials',
      first_name: 'First Name',
      last_name: 'Last Name',
      organisation: 'Organisation',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      require_vpn_2fa: 'Require VPN 2FA',
      kind: 'Kind',
      is_activated: 'Activated',
      meta: {
        phone: 'Phone',
        gender: 'Gender',
      },
    },
  },
  token: {
    prettyName: 'Token | Tokens',
    fields: {
      profile: 'User',
      label: 'Label',
      enabled: 'Enabled',
      key: 'Key',
      truncated_key_hash: 'Hash',
    },
    revoke: 'Revoke',
    showRevoked: 'Show revoked',
    confirmRevoke: 'Are you sure that you want to revoke this token?',
    newKey: 'This is the key for your new token. Please save it securely.',
    newHash: 'This is the public identifier for your token.',
  },
  groupmembership: {
    prettyName: 'Group Membership | Group Memberships',
    fields: {
      group_name: 'Group',
      profile_username: 'Username',
      profile_email: 'E-Mail',
    },
  },
};
