




























import { Vue, Component, Prop } from 'vue-property-decorator';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeviceState } from '@/models/device/models';
import { getValueByPath } from '@/util/object';
import {
  StateField,
  StateViewConfig,
  transformMap,
} from '@/apps/data/components/device/interfaces';
import { validateSchema } from '@/util/schema';

@Component({})
export default class ConfigurableDeviceStateView extends Vue {
  @Prop({ required: true }) device!: string;
  @Prop({ required: true }) appId!: string;
  @Prop({ required: true }) options!: StateViewConfig;

  state: Record<string, any> | null = null;
  stateFields: StateField[] = [];
  destroySubject = new Subject<void>();

  getValueByPath = getValueByPath;

  async mounted(): Promise<void> {
    await this.validate();
    await this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async validate(): Promise<void> {
    try {
      const StateViewConfigSchema = await import(
        '@/generated/schema/data/StateViewConfig.json'
      );
      validateSchema(this.options, StateViewConfigSchema);
    } catch (error) {
      this.$errorHandler.handleError(
        new Error(
          `Schema error in client app settings "options". ${error.message}`,
        ),
      );
    }
  }

  async load(): Promise<void> {
    try {
      const response = await this.$apiv2.find<DeviceState>(DeviceState, {
        device: this.device,
        application: this.appId,
      });
      if (response.data) {
        this.state = response.data;
      }
      this.stateFields = this.getStateFields(this.state, this.options);
    } catch (error) {
      // Device state does not exist
      console.log(`no device state for device ${this.device}`);
    }
  }

  getStateFields(
    state: Record<string, any> | null,
    config: StateViewConfig,
  ): StateField[] {
    try {
      if (state === null) {
        return [];
      }
      return config.fields.map(field => {
        let value = getValueByPath(state, field.key);
        if (field.transform) {
          value = transformMap[field.transform](value);
        }
        if (typeof value === 'boolean') {
          value = value.toString();
        }
        return {
          key: field.key,
          label: field.label,
          message: field.message,
          value,
        };
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
      return [];
    }
  }
}
