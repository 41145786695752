import { Firmware, Channel } from '../firmware/models';
import { ApiClientV2 } from '@/api/ApiClientV2';
import { getUpdateState } from './updateEventLog';
import {
  DeviceEventLogEventQuery,
  FirmwareVersionEvent,
  UpdateRequestEvent,
  UpdateRequestEventTrigger,
  UpdateRequestState,
} from './interfaces';
import { DeviceEventLog } from './models';

interface VersionInformation {
  version: Promise<string>;
  channel: Promise<string>;
  time: string;
  state: string;
}

interface VersionResult {
  confirmed: VersionInformation;
  unconfirmed: VersionInformation;
  failed: VersionInformation;
}

export function compareFirmwareAndUpdateEvents(
  firmwareResults: FirmwareVersionEvent[],
  updateResults: UpdateRequestEvent[],
  api: ApiClientV2,
): VersionResult {
  let versionResult: VersionResult = {
    confirmed: {
      version: undefined,
      channel: undefined,
      time: '',
      state: '',
    },
    unconfirmed: {
      version: undefined,
      channel: undefined,
      time: '',
      state: '',
    },
    failed: {
      version: undefined,
      channel: undefined,
      time: '',
      state: '',
    },
  };
  // no events
  if (firmwareResults.length === 0 && updateResults.length === 0) {
    throw 'No events found';
  }
  // only firmware-version event exists
  else if (firmwareResults.length > 0 && updateResults.length === 0) {
    versionResult.confirmed = getFromFirmwareEvent(firmwareResults[0]);
  }
  // only update-request event exists
  else if (firmwareResults.length === 0 && updateResults.length > 0) {
    versionResult = analyzeUpdateState(updateResults, api);
  }
  // both events exist, firmware-version event is more recent
  else if (
    new Date(firmwareResults[0].time).getTime() >=
    new Date(updateResults[0].time).getTime()
  ) {
    versionResult.confirmed = getFromFirmwareEvent(firmwareResults[0]);
  }
  // update-request more recent, but firmware is up-to-date
  // comparing firmware IDs
  else if (
    firmwareResults[0].payload.fields.firmware ===
    updateResults[0].payload.fields.target_firmware
  ) {
    versionResult.confirmed = getFromFirmwareEvent(firmwareResults[0]);
  }
  // unconfirmed or failed update, also render "old" confirmed version
  else {
    versionResult = analyzeUpdateState(updateResults, api);
    versionResult.confirmed = getFromFirmwareEvent(firmwareResults[0]);
  }
  return versionResult;
}

function analyzeUpdateState(
  results: UpdateRequestEvent[],
  api: ApiClientV2,
): VersionResult {
  const versionResult: VersionResult = {
    confirmed: {
      version: undefined,
      channel: undefined,
      time: '',
      state: '',
    },
    unconfirmed: {
      version: undefined,
      channel: undefined,
      time: '',
      state: '',
    },
    failed: {
      version: undefined,
      channel: undefined,
      time: '',
      state: '',
    },
  };
  // ignore cleanup
  results = results.filter(result => {
    return (
      result.payload.fields.event_trigger !== UpdateRequestEventTrigger.CLEANUP
    );
  });
  const state = getUpdateState(results);
  versionResult[state] = getFromUpdateEvent(results[0], api);
  return versionResult;
}

export async function getFirmwareVersionFromId(
  id: string,
  api: ApiClientV2,
): Promise<string> {
  const firmware = await api.get<Firmware>(Firmware, id);
  return firmware.version;
}

export async function getChannelIdText(
  id: string,
  api: ApiClientV2,
): Promise<string> {
  const channel = await api.get<Channel>(Channel, id);
  return channel.id_text;
}

function getFromFirmwareEvent(result: FirmwareVersionEvent) {
  return {
    version: Promise.resolve(result.payload.fields.firmware_version),
    channel: Promise.resolve(result.payload.fields.channel_id_text),
    time: result.time,
    state: UpdateRequestState.CONFIRMED,
  };
}

function getFromUpdateEvent(result: UpdateRequestEvent, api: ApiClientV2) {
  return {
    version: getFirmwareVersionFromId(
      result.payload.fields.target_firmware,
      api,
    ),
    channel: getChannelIdText(result.payload.fields.target_channel, api),
    time: result.time,
    state: result.payload.fields.state,
  };
}

export async function getFirmwareVersionFromDeviceVersionInfoEvent(
  deviceId: string,
): Promise<string | undefined> {
  const query: DeviceEventLogEventQuery = {
    device: deviceId,
    page: 1,
    page_size: 1,
    identity: 'device-version-info',
  };
  const event = await DeviceEventLog.queryEvents(query);
  return event.results[0]?.payload?.info?.fw_version;
}
