


















import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';
import { getLocationPreservingState } from '../../routingUtils';

import { deviceRouteName } from '../app';

import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'main-menu',
  components: {
    'main-menu-entry': MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-chart-pie',
        text: 'Dashboard',
        location: { name: deviceRouteName('dashboard') },
      },
      {
        iconName: 'mdi-file-document',
        text: this.$tc('device.product.prettyName', 2),
        location: getLocationPreservingState(
          deviceRouteName('product-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-puzzle',
        text: this.$tc('device.model.prettyName', 2),
        location: getLocationPreservingState(
          deviceRouteName('model-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-tag',
        text: this.$tc('device.classification.prettyName', 2),
        location: getLocationPreservingState(
          deviceRouteName('classification-root'),
          this.$router,
        ),
        isChild: true,
      },
      {
        iconName: 'mdi-cog-box',
        text: this.$tc('device.deviceSettingKind.prettyName', 2),
        location: getLocationPreservingState(
          deviceRouteName('device-setting-kind-root'),
          this.$router,
        ),
        isChild: true,
      },
      {
        iconName: 'mdi-cellphone-link',
        text: this.$tc('device.device.prettyName', 2),
        location: getLocationPreservingState(
          deviceRouteName('device-root'),
          this.$router,
        ),
      },
    ];
  }
}
