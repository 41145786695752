import {
  BackgroundTask,
  ObjectAuthorization,
  Role,
  Group,
  GroupMembership,
  Attachment,
  Propagation,
} from '@/models/core/models';
import { TRANSIENT_BASE_OBJECT_DEFAULT } from '@/models/core/base';
import { Organisation } from './organisation';
import { Product, Model, DeviceSettingKind } from '../device/models';
import { Profile } from './profile';
import { Channel, DeliveryMethod, Firmware } from '../firmware/models';
import { ClientApp, ClientAppSettingKind } from '../client/models';
import { DataApplication } from '../data/models';

export const OBJECT_AUTHORIZATION_DEFAULT: ObjectAuthorization = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  object_type: '',
  object_id: '',
  role: '',
  note: '',
  propagation: Propagation.BOTH,
  group: '',
};

export const GROUP_DEFAULT: Group = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  organisation: '',
};

export const GROUP_MEMBERSHIP_DEFAULT: GroupMembership = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  group: '',
  profile: '',
};

export const BACKGROUND_TASK_DEFAULT: BackgroundTask = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  state: '',
  description: '',
};

// TODO: remove and use model class instead
export const PRETTY_NAMES = {
  'delivery-method': 'Delivery Method',
  'delivery-procedure': 'Delivery Procedure',
  'delivery-attachment': 'Delivery Attachment',
  'object-authorization': 'Object Authorisation',
  'profile': 'User',
  'organisation': 'Organisation',
  'product': 'Product',
  'model': 'Model',
  'classification': 'Classification',
  'device': 'Device',
  'channel': 'Channel',
  'data/application': 'Data Application',
  'role': 'Role',
  'group': 'Group',
  'device-setting-kind': 'Device Setting Kind',
};

export const PERMISSIONS_DEFAULT = {
  'core.organisation': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    link_profile: false,
    add_product: false,
    add_role: false,
    add_group: false,
    view_object_authorization: false,
    edit_object_authorization: false,
    add_data_application: false,
    add_client_app: false,
    reorder_client_apps: false,
  },
  'core.profile': {
    view: false,
    edit: false,
    authorize: false,
    block: false,
    add_token: false,
    reset_pin_counter: false,
    update_2fa: false,
  },
  'core.role': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
  },
  'core.group': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
  },
  'core.token': {
    view: false,
    edit: false,
    revoke: false,
  },
  'client.clientapp': {
    view: false,
    edit: false,
    delete: false,
    add_setting_kind: false,
    authorize: false,
  },
  'client.clientappsettingkind': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    view_setting: false,
    edit_setting: false,
    access_setting: false,
  },
  'device.device': {
    view: false,
    view_device_history: false,
    assign_application: false,
  },
  'device.devicesettingkind': {
    access_setting: false,
    authorize: false,
    delete: false,
    edit: false,
    edit_setting: false,
    owner: false,
    view: false,
    view_setting: false,
  },
  'device.model': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    add_channel: false,
    add_delivery_method: false,
    add_device: false,
    edit_device: false,
    generate_key256: false,
    list_devices: false,
    view_device_history: false,
    add_classification: false,
    view_classification: false,
    edit_classification: false,
    delete_classification: false,
    view_device_setting: false,
    edit_device_setting: false,
    add_device_setting_kind: false,
  },
  'device.product': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    add_model: false,
  },
  'firmware.channel': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    create_firmware: false,
  },
  'firmware.deliverymethod': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    add_attachment: false,
    delete_attachment: false,
    update_attachment: false,
    add_procedure: false,
  },
  'firmware.deliveryprocedure': {
    view: false,
    edit: false,
    delete: false,
    use: false,
    view_stages: false,
  },
  'firmware.firmware': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    download: false,
    change_publish_state: false,
    remove_download: false,
    add_download: false,
  },
  'firmware.firmwareupdate': {
    view: false,
    cleanup: false,
    confirm: false,
    process: false,
  },
  'firmware.key256': {
    load: false,
    add_subkey: false,
  },
  'data.application': {
    view: false,
    edit: false,
    delete: false,
    feed_data: false,
    authorize: false,
    view_dashboard_config: false,
    view_device_relation: false,
    list_device_relations: false,
    edit_device_relation: false,
    add_stream: false,
    add_device_session_config: false,
    view_device_state: false,
    add_patient: false,
    list_patients: false,
    add_data_source_template: false,
    add_data_label: false,
  },
  'data.stream': {
    view: false,
    edit: false,
    delete: false,
    add_output: false,
  },
  'data.output': {
    view: false,
    edit: false,
    delete: false,
    view_config: false,
  },
  'data.devicesessionconfig': {
    view: false,
    edit: false,
    delete: false,
    use: false,
    authorize: false,
    view_device_session: false,
    list_device_sessions: false,
  },
  'data.datasourcetemplate': {
    view: false,
    edit: false,
    delete: false,
    authorize: false,
    view_config: false,
    view_data: false,
  },
  'clinic.patient': {
    view: false,
    edit: false,
    delete: false,
    assign_device_data: false,
    view_device_assignment: false,
    update_device_assignment: false,
    authorize: false,
    view_device_data: false,
  },
  'clinic.datalabel': {
    view: false,
    edit: false,
    delete: false,
  },
};

export const ROLE_DEPENDENCY_TREE = [
  {
    name: 'Organisation',
    contentType: 'core.organisation',
    dependencies: [
      'core.profile',
      'device.product',
      'core.role',
      'core.group',
      'client.clientapp',
      'data.application',
    ],
    children: [
      {
        name: 'Profile',
        contentType: 'core.profile',
        dependencies: ['core.token'],
        children: [
          {
            name: 'Token',
            contentType: 'core.token',
          },
        ],
      },
      {
        name: 'Product',
        contentType: 'device.product',
        dependencies: ['device.model'],
        children: [
          {
            name: 'Model',
            contentType: 'device.model',
            dependencies: [
              'device.device',
              'device.devicesettingkind',
              'firmware.firmware',
              'firmware.channel',
              'firmware.deliverymethod',
              'firmware.key256',
            ],
            children: [
              {
                name: 'Device',
                contentType: 'device.device',
              },
              {
                name: 'Device Setting Kind',
                contentType: 'device.devicesettingkind',
              },
              {
                name: 'Channel',
                contentType: 'firmware.channel',
                dependencies: ['firmware.firmware'],
                children: [
                  {
                    name: 'Firmware',
                    contentType: 'firmware.firmware',
                  },
                ],
              },
              {
                name: 'Delivery Method',
                contentType: 'firmware.deliverymethod',
                dependencies: ['firmware.deliveryprocedure'],
                children: [
                  {
                    name: 'Delivery Procedure',
                    contentType: 'firmware.deliveryprocedure',
                    dependencies: ['firmware.firmwareupdate'],
                    children: [
                      {
                        name: 'Firmware Update',
                        contentType: 'firmware.firmwareupdate',
                      },
                    ],
                  },
                ],
              },
              {
                name: 'Key 256',
                contentType: 'firmware.key256',
              },
            ],
          },
        ],
      },
      {
        name: 'Data Application',
        contentType: 'data.application',
        dependencies: ['data.stream', 'data.devicesessionconfig'],
        children: [
          {
            name: 'Stream',
            contentType: 'data.stream',
            dependencies: ['data.output'],
            children: [
              {
                name: 'Output',
                contentType: 'data.output',
              },
            ],
          },
          {
            name: 'Device Session Config',
            contentType: 'data.devicesessionconfig',
          },
          {
            name: 'Data Source Template',
            contentType: 'data.datasourcetemplate',
          },
          {
            name: 'Participant',
            contentType: 'clinic.patient',
          },
          {
            name: 'Data Label',
            contentType: 'clinic.datalabel',
          },
        ],
      },
      {
        name: 'Role',
        contentType: 'core.role',
      },
      {
        name: 'Group',
        contentType: 'core.group',
      },
      {
        name: 'Client Application',
        contentType: 'client.clientapp',
        dependencies: ['client.clientappsettingkind'],
        children: [
          {
            name: 'Client App Setting Kind',
            contentType: 'client.clientappsettingkind',
          },
        ],
      },
    ],
  },
];

export const contentTypes = [
  {
    prettyName: 'Organisation',
    apiName: 'organisation',
    name: 'core.organisation',
    key: 'name',
    collection: null,
    modelClass: Organisation,
  },
  {
    prettyName: 'Product',
    apiName: 'product',
    name: 'device.product',
    key: 'name',
    collection: null,
    modelClass: Product,
  },
  {
    prettyName: 'Model',
    apiName: 'model',
    name: 'device.model',
    key: 'name',
    collection: null,
    modelClass: Model,
  },
  {
    prettyName: 'Profile',
    apiName: 'profile',
    name: 'core.profile',
    key: 'username',
    collection: null,
    modelClass: Profile,
  },
  {
    prettyName: 'Channel',
    apiName: 'channel',
    name: 'firmware.channel',
    key: 'name',
    collection: null,
    modelClass: Channel,
  },
  {
    prettyName: 'Delivery Method',
    apiName: 'delivery-method',
    name: 'firmware.deliverymethod',
    key: 'name',
    collection: null,
    modelClass: DeliveryMethod,
  },
  {
    prettyName: 'Firmware',
    apiName: 'firmware',
    name: 'firmware.firmware',
    key: 'version',
    collection: null,
    modelClass: Firmware,
  },
  {
    prettyName: 'Setting Kind',
    apiName: 'device-setting-kind',
    name: 'device.devicesettingkind',
    key: 'handle',
    collection: null,
    modelClass: DeviceSettingKind,
  },
  {
    prettyName: 'Client App',
    apiName: 'client/app',
    name: 'client.clientapp',
    key: 'name',
    collection: null,
    modelClass: ClientApp,
  },
  {
    prettyName: 'Client App Setting Kind',
    apiName: 'client/app-setting-kind',
    name: 'client.clientappsettingkind',
    key: 'name',
    collection: null,
    modelClass: ClientAppSettingKind,
  },
  {
    prettyName: 'Data Application',
    apiName: 'data/application',
    name: 'data.application',
    key: 'name',
    collection: null,
    modelClass: DataApplication,
  },
];

export const ROLE_DEFAULT: Role = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  organisation: '',
  permissions: PERMISSIONS_DEFAULT,
};

export const ATTACHMENT_DEFAULT: Attachment = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  kind: '',
  mime_type: '',
  encoding: '',
  file: '',
};
