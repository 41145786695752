import { ClientAppEntry, clientAppRouteName } from '../clientAppRegistry';

export const VIEW_ID = 'web-monitoring';

export const CLIENT_APP: ClientAppEntry = {
  name: 'Monitoring',
  path: 'monitoring',
  icon: 'mdi-playlist-check',
  defaultPathName: 'dashboard-root',
};

export function monitoringRouteName(routeName: string): string {
  return clientAppRouteName(VIEW_ID, routeName);
}
