import { TransientBaseObject, ModelClass } from '@/models/core/base';
import {
  Device,
  Model,
  Product,
  Classification,
  DeviceSettingKind,
} from '@/models/device/models';
import {
  DataApplication,
  Stream,
  DataSourceTemplate,
  DeviceSessionConfig,
  Output,
  DeviceRelation,
} from '@/models/data/models';
import { Role, Group, ObjectAuthorization } from './core/models';

import { Organisation } from './core/organisation';
import {
  Channel,
  DeliveryMethod,
  DeliveryProcedure,
  Firmware,
} from './firmware/models';
import { Profile, Token } from './core/profile';
import { ClientApp, ClientAppSettingKind } from './client/models';
import { has } from '@/util/util';
import { Participant, DeviceAssignment, DataLabel } from './study/models';

interface ObjectAncestor {
  objectType: string;
  mainParent?: boolean;
  requiredForFilter?: boolean;
}

interface ObjectRegistry {
  [objectType: string]: {
    apiUrl?: string;
    store?: string;
    defaultViewId?: string;
    ancestors?: ObjectAncestor[];
    modelClass?: ModelClass;
  };
}
// moved to function to avoid circular dependency issues
export function getObjectRegistry(): ObjectRegistry {
  const OBJECT_REGISTRY: ObjectRegistry = {
    /* CORE */
    'organisation': {
      modelClass: Organisation,
    },
    'profile': {
      modelClass: Profile,
    },
    'token': {
      modelClass: Token,
    },
    'role': {
      modelClass: Role,
    },
    'group': {
      modelClass: Group,
      defaultViewId: 'web-admin',
    },
    'object-authorization': {
      modelClass: ObjectAuthorization,
      defaultViewId: 'web-admin',
      ancestors: [
        {
          objectType: 'role',
        },
      ],
    },
    // TODO: cleanup object registry and use proper names from backend
    'client-app': {
      modelClass: ClientApp,
      apiUrl: 'client/app',
    },
    /* CLIENT */
    'client.clientapp': {
      modelClass: ClientApp,
      apiUrl: 'client/app',
    },
    'clientappsettingkind': {
      modelClass: ClientAppSettingKind,
    },
    'background-task': {},
    /* DATA */
    'application': {
      defaultViewId: 'web-data',
      modelClass: DataApplication,
    },
    'data-source-template': {
      modelClass: DataSourceTemplate,
    },
    'device-session-config': {
      modelClass: DeviceSessionConfig,
      defaultViewId: 'web-data',
    },
    'stream': {
      defaultViewId: 'web-data',
      modelClass: Stream,
    },
    'output': {
      modelClass: Output,
    },
    'device-relation': {
      modelClass: DeviceRelation,
    },
    /* DEVICE */
    'product': {
      defaultViewId: 'web-device',
      modelClass: Product,
    },
    'model': {
      defaultViewId: 'web-device',
      modelClass: Model,
      ancestors: [
        {
          objectType: 'product',
          mainParent: true,
        },
      ],
    },
    'classification': {
      modelClass: Classification,
      defaultViewId: 'web-device',
      ancestors: [
        {
          objectType: 'product',
        },
        {
          objectType: 'model',
          mainParent: true,
          requiredForFilter: true,
        },
      ],
    },
    'device': {
      defaultViewId: 'web-device',
      modelClass: Device,
      ancestors: [
        {
          objectType: 'product',
          requiredForFilter: true,
        },
        {
          objectType: 'model',
          mainParent: true,
        },
        {
          objectType: 'classification',
        },
      ],
    },
    'device-setting-kind': {
      modelClass: DeviceSettingKind,
      defaultViewId: 'web-device',
      ancestors: [
        {
          objectType: 'product',
        },
        {
          objectType: 'model',
          mainParent: true,
          requiredForFilter: true,
        },
      ],
    },
    /* FIRMWARE */
    'channel': {
      defaultViewId: 'web-firmware',
      modelClass: Channel,
      ancestors: [
        {
          objectType: 'product',
          requiredForFilter: true,
        },
        {
          objectType: 'model',
          mainParent: true,
          requiredForFilter: true,
        },
        {
          objectType: 'classification',
        },
      ],
    },
    'delivery-method': {
      defaultViewId: 'web-firmware',
      modelClass: DeliveryMethod,
      ancestors: [
        {
          objectType: 'product',
          requiredForFilter: true,
        },
        {
          objectType: 'model',
          mainParent: true,
          requiredForFilter: true,
        },
      ],
    },
    'delivery-procedure': {
      defaultViewId: 'web-firmware',
      modelClass: DeliveryProcedure,
      ancestors: [
        {
          objectType: 'product',
          requiredForFilter: true,
        },
        {
          objectType: 'model',
          requiredForFilter: true,
        },
        {
          objectType: 'delivery-method',
          mainParent: true,
          requiredForFilter: true,
        },
      ],
    },
    'firmware': {
      modelClass: Firmware,
      defaultViewId: 'web-firmware',
      ancestors: [
        {
          objectType: 'product',
          requiredForFilter: true,
        },
        {
          objectType: 'model',
          requiredForFilter: true,
        },
        {
          objectType: 'channel',
          mainParent: true,
        },
      ],
    },
    /* STUDY */
    'clinic.patient': {
      modelClass: Participant,
    },
    'clinic.deviceassignment': {
      modelClass: DeviceAssignment,
    },
    'clinic.datalabel': {
      modelClass: DataLabel,
    },
  };

  // if (OPTION_XXX) {
  //   OBJECT_REGISTRY = {
  //     ...OBJECT_REGISTRY,
  //     /* XXX */
  //   }
  // }

  return OBJECT_REGISTRY;
}

function objectRegistryGet(objectType: string) {
  const entry = getObjectRegistry()[objectType];
  if (!entry) {
    throw new Error(`Object type '${objectType}' is not defined in registry.`);
  }
  return entry;
}

export function objectTypeHasProperty(objectType: string, property: string) {
  const entry = objectRegistryGet(objectType);
  return has(entry, property);
}

export function objectRegistryGetProperty(
  objectType: string,
  property: string,
) {
  const entry = objectRegistryGet(objectType);

  if (!has(entry, property)) {
    throw new Error(
      `Property '${property}' is not defined for object type ${objectType}.`,
    );
  }
  return entry[property];
}

export function getApiUrl(objectType: string): string {
  try {
    objectRegistryGet(objectType);
    if (objectTypeHasProperty(objectType, 'apiUrl')) {
      const apiUrl = objectRegistryGetProperty(objectType, 'apiUrl');
      if (typeof apiUrl === 'string') {
        return apiUrl;
      }
    }
    return objectType;
  } catch (error) {
    return objectType;
  }
}

export function getStoreName(objectType: string): string {
  let store;
  try {
    store = objectRegistryGetProperty(objectType, 'store');
  } catch (error) {
    store = 'global';
  }
  return store;
}

export function getViewId(objectType: string) {
  return objectRegistryGetProperty(objectType, 'defaultViewId');
}

function getMainParent(objectType: string) {
  if (objectTypeHasProperty(objectType, 'ancestors')) {
    const ancestors = objectRegistryGetProperty(objectType, 'ancestors');
    return ancestors.find(ancestor => ancestor.mainParent === true);
  } else {
    return null;
  }
}

export function getPathUp(objectType: string): ObjectAncestor[] {
  const up = getMainParent(objectType);
  if (up) {
    // return list starting with upmost ancestor
    return [...getPathUp(up.objectType), up];
  } else {
    return [];
  }
}

export function getObjectTypesUp(objectType: string): string[] {
  return getPathUp(objectType).map(ancestor => ancestor.objectType);
}

export function getDependencies(objectType: string) {
  const dependencies: string[] = [];

  for (const checkedObjectType in getObjectRegistry()) {
    if (objectTypeHasProperty(checkedObjectType, 'ancestors')) {
      const ancestors = objectRegistryGetProperty(
        checkedObjectType,
        'ancestors',
      );
      if (ancestors.some(ancestor => ancestor.objectType === objectType)) {
        dependencies.push(checkedObjectType);
      }
    }
  }
  return dependencies;
}

export function getModelClass(objectType: string): ModelClass {
  return objectRegistryGetProperty(objectType, 'modelClass') as ModelClass;
}

export function getPrettyName(objectType: string): string {
  return getModelClass(objectType).prettyName();
}

export function getAllAncestors(objectType: string) {
  if (objectTypeHasProperty(objectType, 'ancestors')) {
    return objectRegistryGetProperty(objectType, 'ancestors');
  } else if (objectTypeHasProperty(objectType, 'modelClass')) {
    const modelClass = getModelClass(objectType);
    if (modelClass && modelClass.ancestors) {
      const out = [];
      modelClass.ancestors.forEach(ancestor =>
        out.push({
          objectType: ancestor.modelClass.objectType,
        }),
      );
      return out;
    } else {
      return [];
    }
  } else {
    return [];
  }
}
