/**
 * Get value of an object property/path even if it's nested
 * Copied from https://github.com/buefy/buefy/blob/dev/src/utils/helpers.js
 */
export function getValueByPath(
  obj: Record<string, any> | null | undefined,
  path: string,
): any {
  return path.split('.').reduce((o, i) => (o ? o[i] : undefined), obj);
}
