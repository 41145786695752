import { render, staticRenderFns } from "./BaseListV2.vue?vue&type=template&id=a0e50442&scoped=true&"
import script from "./BaseListV2.vue?vue&type=script&lang=ts&"
export * from "./BaseListV2.vue?vue&type=script&lang=ts&"
import style0 from "./BaseListV2.vue?vue&type=style&index=0&id=a0e50442&lang=scss&scoped=true&"
import style1 from "./BaseListV2.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0e50442",
  null
  
)

export default component.exports