











import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'formly_checkbox-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class CheckboxField extends Vue {
  get trueValue() {
    if (this.$props.field.properties.trueValue !== undefined) {
      return this.$props.field.properties.trueValue;
    } else {
      return 1;
    }
  }

  get falseValue() {
    if (this.$props.field.properties.falseValue !== undefined) {
      return this.$props.field.properties.falseValue;
    } else {
      return 0;
    }
  }
}
