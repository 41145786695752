
























































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    payload: {
      required: true,
    },
  },
  data() {
    return {
      payloadData: null,
    };
  },
})
export default class DeviceData extends Vue {
  payloadData: any;

  mounted() {
    if (this.$props.payload.data_json) {
      this.payloadData = JSON.parse(this.$props.payload.data_json);
    }
  }
}
