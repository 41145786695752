import jsonschema, { Schema } from 'jsonschema';

/**
 * Validates an object with a JSON schema, raises an error in case of validation error
 * @param object
 * @param schema
 */
export function validateSchema(object: any, schema?: Schema): void {
  if (!schema) {
    return;
  }
  const schemaResult = jsonschema.validate(object, schema);
  if (!schemaResult.valid) {
    throw new Error(
      'Schema error: ' +
        schemaResult.errors.map(err => err.toString()).join('. '),
    );
  }
}
