import { common } from '@/lang/nl/common';
import { auth } from '@/lang/nl/auth';
import { client } from '@/lang/nl/client';
import { admin } from '@/lang/nl/admin';
import { device } from '@/lang/nl/device';
import { data } from '@/lang/nl/data';
import { monitoring } from '@/lang/nl/monitoring';
import { study } from '@/lang/nl/study';
import { dhStudy } from '@/lang/nl/dhStudy';

export const nl = {
  common,
  auth,
  client,
  admin,
  device,
  data,
  monitoring,
  study,
  dhStudy,
};
