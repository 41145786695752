import { GlobalModule } from './GlobalStore';

import Vue from 'vue';
import Vuex from 'vuex';
import { deepCopy } from '@/util/util';

Vue.use(Vuex);

// some modules are registered later with `dynamic: true`
const store = new Vuex.Store({
  modules: {
    global: new GlobalModule<any>(),
  },
});

export default store;

export const initialStateCopy = deepCopy(store.state);
