export class EverionSettings {
  algo_mode: number;
  gsr_on: boolean;
  user_height?: number;
  user_weight?: number;
  user_gender?: number;
  user_age?: number;
  storage_time?: {
    signals: number;
    aggregates: number;
  };
}

export const EVERION_SETTINGS_DEFAULT: EverionSettings = {
  algo_mode: null,
  gsr_on: true,
  user_height: null,
  user_weight: null,
  user_gender: null,
  user_age: null,
  storage_time: null,
};

export const AlgoModeOptions = [
  {
    value: null,
    name: 'Undefined',
  },
  {
    value: 0,
    name: 'Vital Mode',
  },
  {
    value: 1,
    name: 'Vital Capped Mode',
  },
  {
    value: 5,
    name: 'Raw Data HR Only',
  },
  {
    value: 9,
    name: 'Mixed Vital Raw',
  },
  {
    value: 11,
    name: 'Green Only',
  },
];

export const StorageTimeSignalsOptions = [
  {
    value: null,
    name: 'Undefined',
  },
  {
    value: 0,
    name: '1 s',
  },
  {
    value: 1,
    name: '2 s',
  },
  {
    value: 2,
    name: '3 s',
  },
  {
    value: 3,
    name: '4 s',
  },
  {
    value: 4,
    name: '5 s',
  },
  {
    value: 5,
    name: '6 s',
  },
  {
    value: 6,
    name: '8 s',
  },
  {
    value: 7,
    name: '10 s',
  },
  {
    value: 8,
    name: '15 s',
  },
  {
    value: 9,
    name: '20 s',
  },
  {
    value: 10,
    name: '30 s',
  },
  {
    value: 11,
    name: '1 min',
  },
  {
    value: 12,
    name: '2 min',
  },
  {
    value: 13,
    name: '3 min',
  },
  {
    value: 14,
    name: '4 min',
  },
  {
    value: 15,
    name: '5 min',
  },
];

export const StorageTimeAggregatedOptions = [
  {
    value: null,
    name: 'Undefined',
  },
  {
    value: 0,
    name: '1 min',
  },
  {
    value: 1,
    name: '2 min',
  },
  {
    value: 2,
    name: '3 min',
  },
  {
    value: 3,
    name: '4 min',
  },
  {
    value: 4,
    name: '5 min',
  },
  {
    value: 5,
    name: '10 min',
  },
  {
    value: 6,
    name: '15 min',
  },
  {
    value: 7,
    name: '30 min',
  },
  {
    value: 8,
    name: '1 h',
  },
  {
    value: 9,
    name: '2 h',
  },
  {
    value: 10,
    name: '3 h',
  },
  {
    value: 11,
    name: '4 h',
  },
  {
    value: 12,
    name: '6 h',
  },
  {
    value: 13,
    name: '8 h',
  },
  {
    value: 14,
    name: '12 h',
  },
  {
    value: 15,
    name: '24 h',
  },
];
