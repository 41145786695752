



















































import Vue from 'vue';
import Component from 'vue-class-component';

import { Profile } from '@/models/core/profile';
import { CollectionFilter } from '@/api/ApiClient';

@Component({
  data() {
    return {
      kind: this.$route.query.kind ? this.$route.query.kind : 'human',
      organisations: this.$store.state.global.context.organisations,
      selectedOrg: this.$route.query.organisation
        ? this.$route.query.organisation
        : this.$store.getters['global/organisation'].id,
      profileFilter: null,
      usernameSearch: this.$route.query.search
        ? this.$route.query.search
        : null,
    };
  },
})
export default class ProfileList extends Vue {
  profileFilter: CollectionFilter;
  selectedOrg: string;
  kind: string;
  usernameSearch: string;
  Profile = Profile;

  created() {
    this.filterUsers();
  }

  filterUsers() {
    this.profileFilter = {
      organisation: this.selectedOrg,
      kind: this.kind,
      search: this.usernameSearch,
    };
  }
}
