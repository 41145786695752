







































import { Component, Vue, Watch } from 'vue-property-decorator';
import SearchField from '@/components/common/lists/SearchField.vue';
import UpdateRequestSequence from '@/apps/device/components/events/UpdateRequestSequence.vue';
import AutoRefresh from '@/apps/device/components/AutoRefresh.vue';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeviceEventLog } from '@/models/device/models';
import {
  UpdateRequestEvent,
  DeviceEventType,
} from '@/models/device/interfaces';

@Component({
  props: {
    device: {
      required: true,
    },
  },
  components: {
    SearchField,
    UpdateRequestSequence,
    AutoRefresh,
  },
})
export default class UpdateRequestList extends Vue {
  current = 1;
  results: UpdateRequestEvent[] = [];
  identity = '';
  updateEventSequences: UpdateRequestEvent[][] = [];
  destroySubject = new Subject<void>();

  mounted() {
    this.getUpdateEvents();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getUpdateEvents();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get total() {
    if (this.results.length === 10) {
      return this.current + 1;
    } else {
      return this.current;
    }
  }

  onPageChange(value: number) {
    this.current = value;
    this.getUpdateEvents();
  }

  async getUpdateEvents() {
    this.updateEventSequences = this.updateEventSequences.map(s => []);

    // get overlapping results
    const queryUpdate1 = {
      device: this.$props.device,
      page: this.current,
      page_size: 5,
      event_type: DeviceEventType.UPDATE_REQUEST,
    };
    const queryUpdate2 = {
      device: this.$props.device,
      page: this.current + 1,
      page_size: 5,
      event_type: DeviceEventType.UPDATE_REQUEST,
    };

    try {
      const events = await Promise.all([
        DeviceEventLog.queryEvents<UpdateRequestEvent>(queryUpdate1),
        DeviceEventLog.queryEvents<UpdateRequestEvent>(queryUpdate2),
      ]);

      this.results = [...events[0].results, ...events[1].results];

      this.updateEventSequences = this.getUpdateRequestSequences(this.results);
      // if page > 1, drop first sequence because of overlap
      if (this.current > 1) {
        this.updateEventSequences.splice(0, 1);
      }
    } catch (err) {
      console.log(this.$errorHandler.errorToString(err));
    }
  }

  getUpdateRequestSequences(
    results: UpdateRequestEvent[],
  ): UpdateRequestEvent[][] {
    // find update-request events that belong together:
    // start with event_trigger 'request'
    // same 'firmware_update' ID

    const sequences: UpdateRequestEvent[][] = [];
    let previousSequenceStart = -1;
    let sequenceStart = 0;

    // while there are still events with trigger 'request'
    while (sequenceStart >= 0) {
      const currentSequence: UpdateRequestEvent[] = [];
      // ignore cleanup
      results = results.filter(result => {
        return result.payload.fields.event_trigger !== 'cleanup';
      });

      // find first event with trigger 'request'
      sequenceStart = results.findIndex((result, index) => {
        return (
          // only search for events that haven't been found yet
          index > previousSequenceStart &&
          result.payload.fields.event_trigger === 'request'
        );
      });
      if (sequenceStart >= 0) {
        const firmware_update =
          results[sequenceStart].payload.fields.firmware_update;
        // check if all events belong to same update sequence
        for (let i = sequenceStart; i > previousSequenceStart; i--) {
          if (results[i].payload.fields.firmware_update === firmware_update) {
            currentSequence.push(results[i]);
          }
        }
      }
      // if a sequence was found, push it to the results array
      if (currentSequence.length) {
        sequences.push(currentSequence);
      }
      previousSequenceStart = sequenceStart;
    }
    return sequences;
  }
}
