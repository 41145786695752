































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { mapState } from 'vuex';
import { ClientApp } from '@/models/client/models';
import {
  clientAppIsKnown,
  clientAppRegistryGet,
} from '@/apps/clientAppRegistry';
import { CollectionSubscriber } from '@/api/ApiClient';
import { getLocationPreservingState } from '@/apps/routingUtils';
import { clientAppDefaultRouteName } from '@/apps/clientAppRegistry';
import { Location } from 'vue-router';

@Component({
  name: 'client-app-menu',
  computed: {
    ...mapState('global', ['context']),
  },
})
export default class MenuClientApps extends Vue {
  clientApps: CollectionSubscriber | null = null;

  created(): void {
    this.clientApps = this.$store.getters['global/collection']('client-app');
    this.setHideClientAppsMenu();
  }

  @Watch('clientAppsList')
  onClientAppsListChanged(): void {
    this.setHideClientAppsMenu();
  }

  setHideClientAppsMenu(): void {
    // hide client apps menu if less than two are available and no permission to create
    const list = this.clientAppsList;
    if (list.length < 2 && !this.canCreateClientApp) {
      this.$store.commit('global/setHideClientAppsMenu', {
        value: true,
      });
    } else {
      this.$store.commit('global/setHideClientAppsMenu', {
        value: false,
      });
    }
  }

  get clientAppsList(): ClientApp[] {
    let list = [];
    if (this.clientApps?.objects) {
      list = this.clientApps.objects.filter(app => this.isAppKnown(app));
    }
    return list;
  }

  collapse(): void {
    this.$store.commit('global/setNavigationCollapsed', { value: true });
    // hide client app menu also on mobile
    this.$store.commit('global/setReaderMode', { value: true });
  }

  switchOrganisationSelector(): void {
    if (this.isCollapsed) {
      this.$store.commit('global/setNavigationCollapsed', { value: false });
      this.$store.commit('global/setSelectionCollapsed', { value: false });
    } else {
      this.collapse();
    }
  }

  get isCollapsed(): boolean {
    return this.$store.getters['global/navigationIsCollapsed'];
  }

  isAppKnown(app: ClientApp): boolean {
    return clientAppIsKnown(app.view_id);
  }

  get canCreateClientApp(): boolean {
    return this.$store.getters['global/organisation']['_permissions'][
      'add_client_app'
    ];
  }

  getAppLocation(app: ClientApp): RawLocation {
    // If the application is already active, just open the push sidebar
    if (this.isActive(app)) {
      return {
        name: this.$route.name ?? clientAppDefaultRouteName(app.view_id),
        params: {
          app_handle: app.handle,
        },
      };
    } else {
      return getLocationPreservingState(
        clientAppDefaultRouteName(app.view_id),
        this.$router,
        app.handle,
      );
    }
  }

  getAppIcon(app: ClientApp): string {
    const icon = clientAppRegistryGet(app.view_id, 'icon');
    if (icon.startsWith('fa-')) {
      return `fa ${icon}`;
    }
    return `mdi ${icon} mdi-24px`;
  }

  isActive(app: ClientApp): boolean {
    return this.$route.params.app_handle === app.handle;
  }

  selectClientApp(app: ClientApp): void {
    if (this.isActive(app) && !this.isCollapsed) {
      this.$store.commit('global/setNavigationCollapsed', { value: true });
    } else {
      this.$store.commit('global/setNavigationCollapsed', { value: false });
    }
  }

  get clientAppBootstrapLink(): Location {
    return {
      name: 'bootstrap-client-app-create',
      params: {
        org_slug: this.$route.params.org_slug,
      },
    };
  }
}
