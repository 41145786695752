
















































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { sortJsonObject } from '@/util/util';
import { DeviceSetting, DeviceSettingKind } from '@/models/device/models';
import { ClientAppSetting, ClientAppSettingKind } from '@/models/client/models';
import { validateSchema } from '@/util/schema';
import { Schema } from 'jsonschema';

@Component({})
export default class SettingForm extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ required: true }) setting!: DeviceSetting | ClientAppSetting;
  @Prop({ default: () => [] }) settingKinds!:
    | DeviceSettingKind[]
    | ClientAppSettingKind[];
  @Prop({ default: 'device' }) apiUrl!: string;
  @Prop({ required: false }) schema?: Schema;

  settingString = '';
  originalSetting = '';
  settingKind: string | undefined = '';
  originalKind: string | undefined = '';
  hasError = false;
  errorMessages: string[] = [];

  mounted(): void {
    const value = sortJsonObject(this.setting.value);
    this.settingString = JSON.stringify(value, null, 2);
    this.originalSetting = this.settingString;
    this.originalKind = this.setting.kind;
    this.settingKind = this.setting.kind;
  }

  @Watch('$props.setting.value')
  valueChanged(): void {
    const value = sortJsonObject(this.setting.value);
    this.settingString = JSON.stringify(value, null, 2);
    this.originalSetting = this.settingString;
  }

  @Watch('$props.setting.kind')
  kindChanged(): void {
    this.originalKind = this.setting.kind;
    this.settingKind = this.setting.kind;
  }

  get isDirty(): boolean {
    return (
      this.originalSetting !== this.settingString ||
      this.originalKind !== this.settingKind
    );
  }

  get isNew(): boolean {
    return this.setting.id === '0';
  }

  async saveSetting(key: string, kind: string): Promise<void> {
    const loadingComponent = this.$buefy.loading.open({});

    try {
      const value = JSON.parse(this.settingString);
      validateSchema(value, this.schema);
      let newSetting: {
        key: string;
        kind: string;
        value: string;
        object_seq?: number;
      } = {
        key,
        kind,
        value,
      };
      if (this.setting.object_seq) {
        newSetting = {
          ...newSetting,
          object_seq: this.setting.object_seq,
        };
      }
      await this.$apiv2.customPost(
        `${this.apiUrl}/${this.id}/setting/`,
        newSetting,
      );
      this.originalSetting = this.settingString;
      this.originalKind = this.settingKind;
      this.hasError = false;
      this.$emit('settingSaved');
    } catch (error) {
      this.$errorHandler.handleError(error);
      this.errorMessages = this.$errorHandler.errorToStrings(error);
      this.hasError = true;
    }
    loadingComponent.close();
  }

  deleteSetting(): void {
    this.$buefy.dialog.confirm({
      message: this.$tc('common.confirmDelete'),
      onConfirm: async () => {
        this.$emit('deleteSetting', this.setting);
      },
    });
  }
}
