









































import Vue from 'vue';
import Component from 'vue-class-component';
import { adminRouteName } from '../app';

@Component({
  methods: {
    adminRouteName,
  },
  props: {
    collection: {
      required: true,
    },
    name: {
      required: true,
    },
    createRoute: {
      required: false,
    },
    listRoute: {
      required: true,
    },
  },
})
export default class DashboardCollectionTile extends Vue {}
