import { CLIENT_APP_DEFAULT } from '@/models/client/defaults';
import {
  TransientBaseObject,
  TRANSIENT_BASE_OBJECT_DEFAULT,
  ListModelField,
  CellType,
  ModelField,
} from '@/models/core/base';
import Vue from 'vue';
import { ApiClientV2, RelatedAnnotation } from '@/api/ApiClientV2';
import { Organisation } from '../core/organisation';
import { deepCopy } from '@/util/util';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';
import { globalStore } from '@/store/modules/global';

export class ClientApp extends TransientBaseObject {
  organisation: string;
  handle: string;
  name: string;
  description: string;
  view_id: string;

  static apiUrl = 'client/app';
  static langPath = 'client.clientapp';
  static objectType = 'client-app';
  static listFields: ListModelField[] = [
    {
      key: 'name',
    },
    {
      key: 'description',
    },
    {
      key: 'handle',
    },
    {
      key: 'view_id',
    },
    {
      key: 'organisation_name',
    },
  ];

  static get joins(): RelatedAnnotation<ClientApp>[] {
    return [
      {
        relatedModelClass: Organisation,
        relatedObjectProperty: 'name',
      },
    ];
  }

  static get defaultModel() {
    return deepCopy(CLIENT_APP_DEFAULT);
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static async getSettings(
    apiClient: ApiClientV2,
    clientAppId: string,
  ): Promise<ClientAppSetting[]> {
    const response = await apiClient.customGet(
      `client/app/${clientAppId}/setting/`,
    );
    return response.results;
  }

  static async getSetting(
    apiClient: ApiClientV2,
    clientAppId: string,
    key: string,
  ): Promise<ClientAppSetting> {
    const response = await apiClient.customGet(
      `client/app/${clientAppId}/setting/${key}/`,
    );
    return response;
  }

  static async setSetting(
    apiClient: ApiClientV2,
    clientAppId: string,
    key: string,
    value: any,
    kind: string = undefined,
    object_seq: number = undefined,
  ): Promise<void> {
    try {
      await apiClient.customPost(`client/app/${clientAppId}/setting/`, {
        key,
        value,
        kind,
        object_seq,
      });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.kind) {
        // kind required for new setting
        const clientAppSettingKind: ClientAppSettingKind =
          await apiClient.getOrCreate<ClientAppSettingKind>(
            ClientAppSettingKind,
            {
              ...CLIENT_APP_SETTING_KIND_DEFAULT,
              client_app: clientAppId,
              handle: 'default',
              name: 'default',
            },
            ['client_app', 'handle'],
          );
        // post again with kind
        await apiClient.customPost(`client/app/${clientAppId}/setting/`, {
          key,
          value,
          kind: clientAppSettingKind.id,
          object_seq,
        });
      } else {
        throw error;
      }
    }
  }

  static async deleteSetting(
    apiClient: ApiClientV2,
    clientAppId: string,
    key: string,
    object_seq: number,
  ): Promise<void> {
    await apiClient.customDelete(
      `client/app/${clientAppId}/setting/${key}/?object_seq=${object_seq}`,
    );
  }

  static insertBefore(
    vm: Vue,
    insert: ClientApp,
    before: ClientApp,
  ): Promise<void> {
    return vm.$apiv2.customPost(`client/app/${insert.id}/insert-before`, {
      other: before && before.id,
    });
  }
}

export class ClientAppSettingKind extends TransientBaseObject {
  name: string;
  description: string;
  client_app: string;
  handle: string;

  static apiUrl = 'client/app-setting-kind';
  static langPath = 'client.clientappsettingkind';
  static objectType = 'clientappsettingkind';

  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'handle',
      cellType: CellType.CODE,
    },
    {
      key: 'description',
    },
  ];

  static fields: ModelField[] = [
    {
      key: 'client_app',
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        editable: false,
        relatedObjectProperty: 'name',
        modelClass: ClientApp.objectType,
        class: 'width-400',
      },
    },
    {
      key: 'name',
      formProperties: {
        editable: true,
        class: 'width-400',
      },
    },
    {
      key: 'handle',
      formProperties: {
        editable: false,
        class: 'width-400',
      },
    },
    {
      key: 'description',
      required: false,
      formProperties: {
        editable: true,
        class: 'width-400',
      },
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(CLIENT_APP_SETTING_KIND_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: deepCopy(CLIENT_APP_SETTING_KIND_DEFAULT),
    };
  }
}

export class ClientAppSetting extends TransientBaseObject {
  kind?: string;
  key: string;
  value: any;
  last_modified?: string;
  client_app?: string;
  object_seq?: number;

  static apiUrl = 'client/app/~/setting';
  static langPath = 'client.clientappsetting';
  static objectType = 'clientappsetting';

  static get defaultModel() {
    return deepCopy(CLIENT_APP_SETTING_DEFAULT);
  }

  static customCreateUrl(setting: ClientAppSetting): string {
    return `client/app/${setting.client_app}/setting/`;
  }

  static customUpdateUrl(setting: ClientAppSetting): string {
    return `client/app/${setting.client_app}/setting/${setting.key}/`;
  }
}

export const CLIENT_APP_SETTING_KIND_DEFAULT: ClientAppSettingKind = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  name: '',
  description: '',
  client_app: '',
  handle: '',
};

export const CLIENT_APP_SETTING_DEFAULT: ClientAppSetting = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  client_app: '',
  kind: '',
  key: '',
  value: {},
};
