














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Chart, {
  ChartConfiguration,
  ChartData,
  ChartDataset,
} from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import zoomPlugin, { resetZoom } from 'chartjs-plugin-zoom';

export type TimeSeriesDataPoint = { x: string; y: number };
export type TimeSeriesChartDataset = ChartDataset<
  'line',
  TimeSeriesDataPoint[]
>;
export type TimeSeriesChartData = ChartData<'line', TimeSeriesDataPoint[]>;

Chart.register(zoomPlugin);

@Component({
  components: {},
})
export default class TimeSeriesChart extends Vue {
  @Prop({ required: true }) start!: Date;
  @Prop({ required: true }) end!: Date;
  @Prop({ required: true }) datasets!: TimeSeriesChartDataset[];

  chart: Chart<'line', TimeSeriesDataPoint[]> | null = null;

  @Watch('datasets')
  onDatasetsChanged(): void {
    this.updateChart();
  }

  @Watch('start')
  onStartChanged(): void {
    this.setMinMax();
  }

  mounted(): void {
    this.setupChart();
  }

  setMinMax(): void {
    if (this.chart?.options.scales?.x) {
      this.chart.options.scales.x.min = this.start.valueOf();
      this.chart.options.scales.x.max = this.end.valueOf();
      this.updateChart();
    }
  }

  updateChart(): void {
    console.log('TimeSeriesChart: updateChart');
    if (this.chart) {
      this.chart.data.datasets = this.datasets;
      this.chart.stop(); // make sure animations are not running
      this.chart.update('none');
    }
  }

  resetZoom(): void {
    if (this.chart) {
      resetZoom(this.chart as unknown as Chart);
    }
  }

  onRangeChanged({ chart }: { chart: Chart }): void {
    const { min, max } = chart.scales.x;
    this.$emit('range-change', { min, max });
  }

  setupChart(): void {
    console.log('TimeSeriesChart: setupChart');
    const chartData: TimeSeriesChartData = {
      datasets: this.datasets,
    };
    const config: ChartConfiguration<'line', TimeSeriesDataPoint[]> = {
      type: 'line',
      data: chartData,
      options: {
        scales: {
          // adapters: {},
          x: {
            position: 'bottom',
            min: this.start.valueOf(),
            max: this.end.valueOf(),
            type: 'time',
            ticks: {
              autoSkip: true,
              autoSkipPadding: 50,
              maxRotation: 0,
            },
            time: {
              displayFormats: {
                hour: 'HH:mm',
                minute: 'HH:mm',
                second: 'HH:mm:ss',
              },
            },
          },
          y: {
            type: 'linear',
            position: 'left',
          },
        },
        plugins: {
          zoom: {
            limits: {
              x: {
                // min: 'original',
                // max: 'original',
                minRange: 60 * 1000, // 1 min
              },
            },
            pan: {
              enabled: true,
              mode: 'x',
              modifierKey: 'ctrl',
              onPanComplete: this.onRangeChanged,
            },
            zoom: {
              // wheel: {
              //   enabled: true,
              // },
              drag: {
                enabled: true,
              },
              // pinch: {
              //   enabled: true,
              // },
              mode: 'x',
              onZoomComplete: this.onRangeChanged,
            },
          },
        },
      },
    };
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    this.chart = new Chart<'line', TimeSeriesDataPoint[]>(ctx, config);
  }
}
