import { RouteConfig } from 'vue-router';
import { clientAppRegistryGet } from '@/apps/clientAppRegistry';
import { VIEW_ID, studyRouteName } from './app';

import RouterPassThrough from '@/components/common/RouterPassThrough.vue';

import MainMenu from '@/apps/study/components/MainMenu.vue';
import { DataApplication } from '@/models/data/models';
import { apiClientV2 } from '@/api/ApiClientV2';
import { globalStore } from '@/store/modules/global';
import { studyStore } from '@/store/modules/study';
import { ErrorHandler } from '@/api/ErrorHandler';

async function beforeEnterStudyApp(): Promise<void> {
  // set context selection to data application from client app settings
  try {
    // fetch client app settings and keep in store
    await globalStore.fetchClientAppSettings();
    const settingData = globalStore.clientAppSetting('data_application');
    const dataClientAppId = settingData?.value?.data_client_app_id;
    await studyStore.fetchDataAppSettings(dataClientAppId);
    const setting = studyStore.dataAppSetting('data_application');
    const appId = setting?.value?.data_application_id;
    if (!appId) {
      throw new Error('No data application ID in client app settings.');
    }
    const app = await apiClientV2.get<DataApplication>(DataApplication, appId);
    if (!app) {
      throw new Error('No data application found.');
    }
    globalStore.setSelectedObject({ objectType: 'application', object: app });
  } catch (error) {
    globalStore.setSelectedObject({ objectType: 'application', object: null });
    ErrorHandler.handleError(error);
  }
}

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: studyRouteName('participant-root') },
  components: {
    'default': RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterStudyApp,
  },
  children: [
    {
      path: 'participant',
      name: studyRouteName('participant-root'),
      redirect: { name: studyRouteName('clinic.patient-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: studyRouteName('clinic.patient-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "participant-list" */ './components/ParticipantList.vue'
            ),
        },
        {
          path: 'create',
          name: studyRouteName('clinic.patient-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "participant-form" */ './components/ParticipantForm.vue'
            ),
        },
        {
          path: ':id',
          name: studyRouteName('clinic.patient-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "participant-detail" */ './components/ParticipantDetail.vue'
            ),
        },
      ],
    },
  ],
};

export default route;
