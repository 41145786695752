var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.dataSourceParams),function(params,paramsId){return _c('div',{key:paramsId,staticClass:"box"},[_c('h2',[_c('b',[_vm._v(_vm._s(params.name))])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-third"},[_vm._v(" Processing state: "),_c('div',{staticClass:"mb-1"},[(_vm.bgTasks[paramsId])?_c('TaskState',{attrs:{"state":_vm.bgTasks[paramsId].state}}):_vm._e()],1),(_vm.bgTasks[paramsId] && _vm.bgTasks[paramsId].progress)?_c('TaskProgress',{staticClass:"mb-3",attrs:{"progress":_vm.progress(paramsId)}}):_vm._e(),(
            !_vm.bgTasks[paramsId] ||
            _vm.bgTasks[paramsId].state !== _vm.BackgroundTaskStates.RUNNING
          )?_c('b-button',{attrs:{"size":"is-small","type":"is-primary","icon-left":"refresh"},on:{"click":function($event){return _vm.refreshDataSource(paramsId)}}},[_vm._v(" Refresh Data Source ")]):_vm._e(),_vm._m(0,true)],1),_c('div',{staticClass:"column is-one-third"},[_vm._v(" Processing state: "),_c('div',{staticClass:"mb-1"},[(_vm.exportBgTasks[paramsId])?_c('TaskState',{attrs:{"state":_vm.exportBgTasks[paramsId].state}}):_vm._e()],1),(_vm.exportBgTasks[paramsId] && _vm.exportBgTasks[paramsId].progress)?_c('TaskProgress',{staticClass:"mb-3",attrs:{"progress":_vm.exportProgress(paramsId)}}):_vm._e(),(
            !_vm.exportBgTasks[paramsId] ||
            _vm.exportBgTasks[paramsId].state !== _vm.BackgroundTaskStates.RUNNING
          )?_c('b-button',{attrs:{"size":"is-small","type":"is-primary","icon-left":"refresh"},on:{"click":function($event){return _vm.refreshExportDataSource(paramsId)}}},[_vm._v(" Refresh CSV Export ")]):_vm._e(),(_vm.downloadLink(paramsId))?_c('div',{staticClass:"mt-1"},[_c('a',{staticClass:"button is-small is-primary",attrs:{"href":_vm.downloadLink(paramsId),"download":""}},[_vm._m(1,true),_c('span',[_vm._v("Download CSV Export")])])]):_c('div',[_c('i',{staticClass:"is-size-7"},[_vm._v(" Download is empty. ")])])],1),_c('div',{staticClass:"column is-one-third"},[_vm._v(" Processing state: "),_c('div',{staticClass:"mb-1"},[(_vm.hdf5BgTasks[paramsId])?_c('TaskState',{attrs:{"state":_vm.hdf5BgTasks[paramsId].state}}):_vm._e()],1),(_vm.hdf5BgTasks[paramsId] && _vm.hdf5BgTasks[paramsId].progress)?_c('TaskProgress',{staticClass:"mb-3",attrs:{"progress":_vm.hdf5Progress(paramsId)}}):_vm._e(),(
            !_vm.hdf5BgTasks[paramsId] ||
            _vm.hdf5BgTasks[paramsId].state !== _vm.BackgroundTaskStates.RUNNING
          )?_c('b-button',{attrs:{"size":"is-small","type":"is-primary","icon-left":"refresh"},on:{"click":function($event){return _vm.refreshHdf5DataSource(paramsId)}}},[_vm._v(" Refresh HDF5 Export ")]):_vm._e(),(_vm.downloadHdf5Link(paramsId))?_c('div',{staticClass:"mt-1"},[_c('a',{staticClass:"button is-small is-primary",attrs:{"href":_vm.downloadHdf5Link(paramsId),"download":""}},[_vm._m(2,true),_c('span',[_vm._v("Download HDF5 Export")])])]):_c('div',[_c('i',{staticClass:"is-size-7"},[_vm._v(" Download is empty. ")])])],1)])])}),(_vm.errorMessages.length > 0)?_c('b-message',{staticClass:"validation-message",attrs:{"title":"Errors","type":"is-danger"}},_vm._l((_vm.errorMessages),function(errorMessage,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(errorMessage)+" ")])}),0):_vm._e(),_c('InstructionsCard',{staticClass:"mb-3"}),_c('time-series-explorer',{ref:"timeseriesExplorer",attrs:{"date":_vm.startDate,"label-source-id":_vm.labelSourceId,"time-series-selection":_vm.timeSeriesSelection},on:{"selection":_vm.timeSeriesSelectionChanged}})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('i',{staticClass:"is-size-7"},[_vm._v(" Refreshing the data source will take some time to finish. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-download"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon is-small"},[_c('i',{staticClass:"fa fa-download"})])}]

export { render, staticRenderFns }