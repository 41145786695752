
































import { CommonContext } from '@/store/GlobalStore';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations, mapState } from 'vuex';
import { Organisation } from '@/models/core/organisation';

@Component({
  name: 'organisation-selector',
  computed: {
    ...mapState('global', ['context']),
  },
})
export default class OrganisationSelector extends Vue {
  context: CommonContext;

  selectOrganisation(organisation: Organisation) {
    if (!this.$store.getters['global/navigationIsActive']) {
      // when the state changes the value of the dropdown is updated which
      // triggers the @input handler which is not wanted
      if (
        organisation &&
        this.context.selection.organisation !== organisation
      ) {
        this.$store.commit('global/clearContextSelection');
        this.$router.push({
          name: 'organisation',
          params: {
            org_slug: organisation.slug,
          },
        });
      }
    }
  }
}
