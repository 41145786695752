import { Organisation } from '@/models/core/organisation';

import { Profile } from '@/models/core/profile';

import { ApiClientV2 } from '@/api/ApiClientV2';

export function getAvatarAnnotation(
  obj: Organisation | Profile,
  api: ApiClientV2,
) {
  const response = {
    id: obj.id,
    annotations: {
      avatar_img: '/img/empty_avatar.png',
    },
  };
  if (obj.avatar !== '' && obj.avatar !== null) {
    response.annotations.avatar_img = api.getAttachmentUrl(obj.avatar);
  }
  return Promise.resolve(response);
}

export function hasPinTrials(...args: any): boolean {
  return args.every((arg: number) => !!arg !== false);
}
