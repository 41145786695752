




import Vue from 'vue';
import Component from 'vue-class-component';

import { Organisation } from '@/models/core/organisation';

@Component({})
export default class OrganisationList extends Vue {
  Organisation = Organisation;
}
