function fallbackCopyTextToClipboard(text): boolean {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let successful = true;
  try {
    successful = document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
    successful = false;
  }

  document.body.removeChild(textArea);
  return successful;
}

declare let navigator: any;

/**
 * Copies text to clipboard. Returns true if successful, false otherwise
 * @param text Text to copy
 */
export function copyTextToClipboard(text: string): Promise<boolean> {
  if (!navigator.clipboard) {
    return Promise.resolve(fallbackCopyTextToClipboard(text));
  }
  return navigator.clipboard
    .writeText(text)
    .then(() => {
      return true;
    })
    .catch(err => {
      console.error('Async: Could not copy text: ', err);
      return false;
    });
}
