import { Participant, ParticipantDetail } from '@/generated/api/study';
import { defineStore } from 'pinia';
import { handleError } from '@/devicehub/utils/error';
import { useStudyApi } from './studyApi';

export enum State {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface ParticipantState {
  loading: boolean;
  participants: ParticipantDetail[];
  currentParticipant?: Participant;
  searchTerm: string;
  filterByState?: State | null;
  participantDashboardUrl: string;
}

export const useParticipantStore = defineStore({
  id: 'participant',
  state: (): ParticipantState => ({
    loading: false,
    participants: [],
    searchTerm: '',
    filterByState: null,
    participantDashboardUrl: '',
  }),
  getters: {
    filteredParticipants(state): ParticipantDetail[] {
      return state.participants
        .filter(p => {
          if (state.filterByState) {
            return p.is_active === (state.filterByState === State.ACTIVE);
          } else {
            return true;
          }
        })
        .filter(p => {
          return JSON.stringify(p)
            .toLowerCase()
            .includes(state.searchTerm.trim().toLowerCase());
        });
    },
  },
  actions: {
    async getParticipants(): Promise<void> {
      this.loading = true;
      const studyApi = useStudyApi();
      try {
        const response =
          await studyApi.participantApi.getParticipantsDetailParticipantsDetailGet();
        this.participants = response.data;
      } catch (error) {
        this.participants = [];
        handleError(error);
      }
      this.loading = false;
    },
    async createParticipant(
      participant_id: string,
    ): Promise<Participant | null> {
      this.loading = true;
      const studyApi = useStudyApi();
      try {
        const response =
          await studyApi.participantApi.createParticipantParticipantsPost(
            participant_id,
          );
        this.loading = false;
        return response.data;
      } catch (error) {
        handleError(error);
        this.loading = false;
        return null;
      }
    },
    async getParticipantById(
      participant_id: string,
    ): Promise<Participant | null> {
      this.loading = true;
      const studyApi = useStudyApi();
      try {
        const response =
          await studyApi.participantApi.getParticipantWithIdParticipantsParticipantIdGet(
            participant_id,
          );
        this.loading = false;
        this.currentParticipant = response.data;
        return response.data;
      } catch (error) {
        this.currentParticipant = undefined;
        handleError(error);
        this.loading = false;
        return null;
      }
    },
  },
});
