import { RouteConfig } from 'vue-router';
import { clientAppRegistryGet } from '@/apps/clientAppRegistry';
import { VIEW_ID, dataRouteName } from './app';

import RouterPassThrough from '@/components/common/RouterPassThrough.vue';

import MainMenu from '@/apps/data/components/MainMenu.vue';
import { updateStateForRoute } from '../routingUtils';
import { DataApplication } from '@/models/data/models';
import { apiClientV2 } from '@/api/ApiClientV2';
import store from '@/store/index';
import { globalStore } from '@/store/modules/global';
import { ErrorHandler } from '@/api/ErrorHandler';

async function beforeEnterDataApp(to): Promise<any> {
  // set context selection to data application from client app settings
  try {
    // fetch client app settings and keep in store
    await globalStore.fetchClientAppSettings();
    const settingData = globalStore.clientAppSetting('data_application');
    const appId = settingData?.value?.data_application_id;
    if (!appId) {
      throw new Error('No data application ID in client app settings.');
    }
    const app = await apiClientV2.get<DataApplication>(DataApplication, appId);
    if (!app) {
      throw new Error('No data application found.');
    }
    globalStore.setSelectedObject({ objectType: 'application', object: app });
    store.commit('global/setContextSelection', {
      objectType: DataApplication.objectType,
      object: app,
    });
  } catch (error) {
    globalStore.setSelectedObject({ objectType: 'application', object: null });
    store.commit('global/setContextSelection', {
      objectType: DataApplication.objectType,
      object: null,
    });
    ErrorHandler.handleError(error);
  }
  const objectList = ['stream'];
  return updateStateForRoute(to, objectList);
}

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: dataRouteName('device-relation-root') },
  components: {
    'default': RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterDataApp,
  },
  children: [
    {
      path: 'device',
      name: dataRouteName('device-relation-root'),
      redirect: { name: dataRouteName('device-relation-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: dataRouteName('device-relation-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "device-relation-list" */ './components/DeviceRelationList.vue'
            ),
        },
        {
          path: 'create',
          name: dataRouteName('device-relation-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "device-relation-form" */ './components/DeviceRelationForm.vue'
            ),
        },
        {
          path: ':id',
          name: dataRouteName('device-relation-detail'),
          props: route => ({
            id: route.params.id,
            mode: 'deviceRelation',
          }),
          component: () =>
            import(
              /* webpackChunkName: "device-detail" */ './components/DeviceDetail.vue'
            ),
        },
        {
          path: 'device/:id',
          name: dataRouteName('device-relation-detail-device'),
          props: route => ({
            id: route.params.id,
          }),
          component: () =>
            import(
              /* webpackChunkName: "device-detail-device" */ './components/DeviceDetailDevice.vue'
            ),
        },
      ],
    },
    {
      path: 'device-session-config',
      name: dataRouteName('device-session-config-root'),
      redirect: { name: dataRouteName('device-session-config-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: dataRouteName('device-session-config-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "device-session-config-list" */ './components/DeviceSessionConfigList.vue'
            ),
        },
        {
          path: 'create',
          name: dataRouteName('device-session-config-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "device-session-config-form" */ './components/DeviceSessionConfigForm.vue'
            ),
        },
        {
          path: ':id',
          name: dataRouteName('device-session-config-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "device-session-config-form" */ './components/DeviceSessionConfigForm.vue'
            ),
        },
      ],
    },
    {
      path: 'stream',
      name: dataRouteName('stream-root'),
      redirect: { name: dataRouteName('stream-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: dataRouteName('stream-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "stream-list" */ './components/DataStreamList.vue'
            ),
        },
        {
          path: 'create',
          name: dataRouteName('stream-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "stream-form" */ './components/DataStreamForm.vue'
            ),
        },
        {
          path: ':id',
          name: dataRouteName('stream-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "stream-form" */ './components/DataStreamForm.vue'
            ),
        },
      ],
    },
    {
      path: 'output',
      name: dataRouteName('output-root'),
      redirect: { name: dataRouteName('output-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: dataRouteName('output-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "output-list" */ './components/OutputList.vue'
            ),
          meta: {
            hasQuery: {
              stream: true,
            },
          },
        },
        {
          path: 'create',
          name: dataRouteName('output-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "output-form" */ './components/OutputForm.vue'
            ),
        },
        {
          path: ':id',
          name: dataRouteName('output-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "output-form" */ './components/OutputForm.vue'
            ),
        },
      ],
    },
    {
      path: 'data-source-template',
      name: dataRouteName('data-source-template-root'),
      redirect: { name: dataRouteName('data-source-template-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: dataRouteName('data-source-template-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "data-source-template-list" */ './components/DataSourceTemplateList.vue'
            ),
        },
        {
          path: 'create',
          name: dataRouteName('data-source-template-create'),
          props: { id: '0' },
          component: () =>
            import(
              /* webpackChunkName: "data-source-template-form" */ './components/DataSourceTemplateForm.vue'
            ),
        },
        {
          path: ':id',
          name: dataRouteName('data-source-template-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "data-source-template-form" */ './components/DataSourceTemplateForm.vue'
            ),
        },
      ],
    },
    {
      path: 'explorer',
      name: dataRouteName('explorer'),
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "explorer" */ './components/DataExplorer.vue'
        ),
    },
  ],
};

export default route;
