import { data as dataEn } from '@/lang/en/data';

// TODO: translate everything

export const data: typeof dataEn = {
  application: {
    prettyName: 'Data Application | Data Applications',
    fields: {
      name: 'Name',
      handle: 'Handle',
      description: 'Description',
    },
  },
  deviceRelation: {
    prettyName: 'Device | Devices',
    menu: 'Source Devices',
    fields: {
      device_device_id: 'Device ID',
      device_name: 'Device Name',
      role: 'Model',
      model_name: 'Model',
      device_ids: 'Device IDs',
      health: 'Health',
      last_seen: 'Zuletzt gesehen',
      assigned_pid: 'Assigned Participant',
      assigned_role: 'Role',
    },
  },
  deviceSessionConfig: {
    prettyName: 'Device Session Configuration | Device Session Configurations',
    menu: 'Sessions',
    fields: {
      name: 'Name',
      description: 'Description',
      handle: 'Handle',
      expiration_policy: {
        closed: 'Expiration Policy: closed [s]',
        expired: 'Expiration Policy: expired [s]',
      },
    },
  },
  stream: {
    prettyName: 'Stream | Streams',
    fields: {
      handle: 'Handle',
      stream_id_filter: 'Stream Id Filter',
      role_filter: 'Role Filter',
      model_filter: 'Model Filter',
    },
  },
  output: {
    prettyName: 'Output | Outputs',
    fields: {
      handle: 'Handle',
      config: 'Configuration',
      plugin_id: 'Plugin ID',
      config_valid: 'Valid',
    },
  },
  dataSourceTemplate: {
    prettyName: 'Data Source Template | Data Source Templates',
    menu: 'Data Templates',
    fields: {
      handle: 'Handle',
      plugin_id: 'Plugin ID',
      config: 'Configuration',
    },
  },
  dataExplorer: {
    menu: 'Data Explorer',
    timeSeriesSettings: 'Time Series Einstellungen',
    maxPoints: 'Max Punkte',
    showMinMax: 'Min Max anzeigen',
    drawLines: 'Linien anzeigen',
    drawPoints: 'Punkte anzeigen',
  },
};
