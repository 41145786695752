



























import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardCollectionTile from './DashboardCollectionTile.vue';

@Component({
  name: 'device-dashboard',
  components: {
    DashboardCollectionTile,
  },
  data() {
    return {
      products: this.$api.subscribeListInComponent(this, 'product', {
        organisation: this.$store.getters['global/organisation'].id,
      }),
      models: this.$api.subscribeListInComponent(this, 'model', {
        organisation: this.$store.getters['global/organisation'].id,
      }),
    };
  },
})
export default class DeviceDashboard extends Vue {}
