




































import { Component, Vue } from 'vue-property-decorator';
import { getModelClass } from '@/models/objectRegistry';
import { ModelClass } from '@/models/core/base';

@Component({
  name: 'formly_related-model-field',
  props: ['form', 'field', 'model', 'to'],
  data() {
    return {
      modelClass: getModelClass(this.$props.field.modelClass),
      showList: false,
      relatedObjectProperty: null,
      selection: null,
      originalRelatedModel: null,
      loading: false,
    };
  },
})
export default class RelatedModelField extends Vue {
  modelClass: ModelClass;
  showList = false;
  relatedObjectProperty: any;
  selection: any;
  originalRelatedModel: any;
  loading = false;
  selectionId: string;

  get preselectedRows(): string[] {
    let id;
    if (this.$props.model[this.$props.field.key]) {
      id = this.$props.model[this.$props.field.key];
    } else if (this.$route.query[this.$props.field.key]) {
      id = this.$route.query[this.$props.field.key];
      this.$props.model[this.$props.field.key] = id;
    }
    if (id) {
      return [id];
    } else {
      return [];
    }
  }

  async mounted() {
    let id;
    if (this.$props.model[this.$props.field.key]) {
      id = this.$props.model[this.$props.field.key];
    } else if (this.$route.query[this.$props.field.key]) {
      id = this.$route.query[this.$props.field.key];
      this.$props.model[this.$props.field.key] = id;
    }

    if (id) {
      this.loading = true;
      try {
        this.originalRelatedModel = await this.$apiv2.get(this.modelClass, id);
        this.relatedObjectProperty =
          this.originalRelatedModel[this.$props.field.relatedObjectProperty];
        this.loading = false;
      } catch (err) {
        // TODO: Error msg!
        this.loading = false;
      }
    }
  }

  get collectionFilter() {
    let collectionFilter = {};
    if (this.$props.field.filters) {
      collectionFilter = this.$props.field.filters;
    }
    return collectionFilter;
  }

  get isEditable() {
    if (this.$props.field.editable === undefined) {
      return true;
    } else {
      if (
        this.$props.field.editable === false &&
        this.$props.model.id !== '0'
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  onSelect(selection) {
    this.showList = false;
    if (selection.length > 0) {
      this.selection = selection[0];
      Vue.set(this.$props.model, this.$props.field.key, this.selection.id);
      this.relatedObjectProperty = null;
      if (this.$props.field.relatedObjectProperty) {
        this.relatedObjectProperty =
          selection[0][this.$props.field.relatedObjectProperty];
      }
    } else {
      this.selection = null;
    }
  }

  openListSelection() {
    this.showList = true;
  }

  get prettyName(): string {
    let prettyName = this.$props.field?.label || this.modelClass.prettyName();
    if (this.$props.field && this.$props.field.required) {
      // add star for required field
      prettyName += ' *';
    }
    return prettyName;
  }
}
