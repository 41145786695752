
































import moment from 'moment';
import parser from 'moment-parser';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'duration-input',
  props: {
    duration_value: {
      required: true,
    },
    label: {
      required: true,
    },
    label_id: {
      required: true,
    },
    loading: {
      required: true,
    },
    min_duration: {
      default: 0,
    },
    max_duration: {},
  },
  data: function () {
    return {
      durationSeconds: this.$props.duration_value,
      fieldType: '',
      fieldMessage: '',
      durationText: '',
      fieldLabel: this.$props.label,
      tooltipMessage: '',
    };
  },
})
export default class DurationInput extends Vue {
  durationText: string;
  fieldType: string;
  durationSeconds: number;
  fieldLabel: string;
  fieldMessage: string;
  minDuration: number;
  maxDuration: number;
  tooltipMessage: string;
  tooltipMessageTemplate =
    "Duration format e.g. '1d';, '2m';, '3s' or ISO 8601 string.";

  mounted() {
    this.minDuration = this.$props.min_duration;
    this.maxDuration = this.$props.max_duration;
    this.tooltipMessage =
      this.tooltipMessageTemplate +
      ' Min duration: ' +
      this.getReadableTime(this.minDuration) +
      ', Max duration: ' +
      this.getReadableTime(this.maxDuration);
    this.durationText = this.getReadableTime(this.durationSeconds);
  }

  getReadableTime(seconds) {
    // Ensure that human readable time is also parsable by moment parser
    let machineTime = moment.duration(seconds, 'seconds');
    if (machineTime.asSeconds() < 60) {
      if (machineTime.asSeconds() === 0) {
        const newTime = this.minDuration;
        machineTime = moment.duration(newTime, 'seconds');
      }
      return machineTime.asSeconds() + ' seconds';
    } else {
      return machineTime.humanize().replace('a ', '1 ');
    }
  }

  get durationValue() {
    return this.durationText;
  }

  set durationValue(newValue) {
    this.durationText = newValue;
    try {
      const duration = parser.parseDuration(newValue);
      if (
        duration.asSeconds() < this.minDuration ||
        duration.asSeconds() > this.maxDuration
      ) {
        throw new RangeError('Duration out of range.');
      }
      this.durationSeconds = duration.asSeconds();
      this.fieldType = '';
      this.fieldMessage = '\n';
      const d = {
        durationSeconds: this.durationSeconds,
        durationLabel: this.$props.label_id,
      };
      this.$emit('updateDuration', d);
    } catch (e) {
      this.fieldType = 'is-danger';
      if (e instanceof RangeError) {
        this.fieldMessage =
          'Invalid value. Value has to be greater than ' +
          this.minDuration +
          's and less than ' +
          moment.duration(this.maxDuration, 'seconds').humanize() +
          '.';
      } else {
        this.fieldMessage =
          'Invalid format. Accepts e.g. "1d", "2m", "3s" or ISO 8601 string';
      }
    }
  }
}
