import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  TRANSIENT_BASE_OBJECT_DEFAULT,
  CellType,
} from '@/models/core/base';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';
import { Organisation } from '@/models/core/organisation';
import { ApiClientV2, Annotation, apiClientV2 } from '@/api/ApiClientV2';
import { getAvatarAnnotation, hasPinTrials } from '@/models/core/util';
import { translate } from '@/lang/setup';
import { deepCopy } from '@/util/util';

export class Profile extends TransientBaseObject {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  organisation?: string;
  avatar?: string | null;
  require_vpn_2fa: boolean;
  kind: string;
  is_activated: boolean;
  pin_trials?: string;
  remaining_external_pin_requests?: number;
  remaining_external_pin_trials?: number;
  remaining_internal_pin_trials?: number;
  meta?: {
    [key: string]: any;
  };

  static apiUrl = 'profile';
  static langPath = 'admin.profile';
  static objectType = 'profile';
  static filters = [
    {
      key: 'organisation',
      objectType: 'core.organisation',
    },
    {
      type: 'parent-selector',
      objectType: 'core.product',
    },
    {
      key: 'kind',
    },
    {
      key: 'search',
    },
  ];
  static listFields: ListModelField[] = [
    { key: 'username' },
    { key: 'email' },
    { key: 'first_name' },
    { key: 'last_name' },
    {
      key: 'pin_trials',
      cellType: CellType.TAG,
      class: (row: Profile): string => {
        return hasPinTrials(
          row.remaining_external_pin_requests,
          row.remaining_external_pin_trials,
          row.remaining_internal_pin_trials,
        )
          ? 'is-success'
          : 'is-danger';
      },
    },
    {
      key: 'is_activated',
      cellType: CellType.TAG,
      class: (row: Profile): string => {
        return row.is_activated ? 'is-success' : 'is-danger';
      },
      transform: (is_activated: boolean) => {
        if (is_activated) {
          return translate('common.yes').toUpperCase();
        } else {
          return translate('common.no').toUpperCase();
        }
      },
    },
    {
      key: 'kind',
      cellType: CellType.ICON,
      transform: (kind: string) => {
        if (kind === 'human') {
          return 'mdi mdi-account';
        } else if (kind === 'machine') {
          return 'mdi mdi-laptop';
        }
        return 'mdi mdi-help';
      },
    },
    { key: 'avatar_img', cellType: CellType.IMAGE },
  ];

  static fields: ModelField[] = [
    {
      key: 'organisation',
      formFieldType: FormFieldType.RELATED_MODEL_SELECT,
      formProperties: {
        editable: false,
        relatedObjectProperty: 'name',
        modelClass: Organisation.objectType,
      },
    },
    {
      key: 'email',
    },
    { key: 'first_name', required: false },
    { key: 'last_name', required: false },
    {
      key: 'kind',
      formFieldType: FormFieldType.SELECTION,
      formProperties: {
        options: [
          { text: 'Any', value: null },
          { text: 'Human', value: 'human' },
          { text: 'Machine', value: 'machine' },
        ],
      },
    },
  ];

  static get annotations(): Annotation<Profile>[] {
    return [
      {
        key: 'avatar_img',
        callback: getAvatarAnnotation,
      },
      {
        key: 'pin_trials',
        callback: async (profile: Profile, api: ApiClientV2) => {
          return {
            id: profile.id,
            annotations: {
              pin_trials: hasPinTrials(
                profile.remaining_external_pin_requests,
                profile.remaining_internal_pin_trials,
                profile.remaining_external_pin_trials,
              )
                ? 'OK'
                : 'Used Up',
            },
          };
        },
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...PROFILE_DEFAULT },
    };
  }

  static get defaultModel() {
    return deepCopy(PROFILE_DEFAULT);
  }

  static async unblockAll(apiClient: ApiClientV2, organisationId: string) {
    await this.blockAll(apiClient, organisationId, true);
  }

  static async blockAll(
    apiClient: ApiClientV2,
    organisationId: string,
    unblock = false,
  ) {
    await apiClient.customPost(`profile/block-all`, {
      organisation: organisationId,
      unblock,
    });
  }

  static async blockUser(apiClient: ApiClientV2, profile: Profile) {
    await apiClient.customPost(`profile/${profile.id}/block`);
  }

  static async unblockUser(apiClient: ApiClientV2, profile: Profile) {
    await apiClient.customPost(`profile/${profile.id}/block`, {
      unblock: true,
    });
  }
}

export const PROFILE_DEFAULT: Profile = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  username: '',
  email: '',
  first_name: '',
  last_name: '',
  kind: 'human',
  require_vpn_2fa: false,
  meta: {},
  is_activated: false,
};

export class Token extends TransientBaseObject {
  profile!: string;
  label?: string;
  enabled?: boolean;
  key?: string;
  truncated_key_hash?: string;

  static apiUrl = 'token';
  static langPath = 'admin.token';
  static objectType = 'token';
  static listFields: ListModelField[] = [
    { key: 'label', cellType: CellType.EDITABLE_CELL },
    {
      key: 'enabled',
      cellType: CellType.TAG,
      class: (token: Token): string => {
        return token.enabled ? 'is-success' : 'is-danger';
      },
      transform: (enabled: boolean) => {
        if (enabled) {
          return translate('common.yes').toUpperCase();
        } else {
          return translate('common.no').toUpperCase();
        }
      },
    },
    { key: 'truncated_key_hash' },
  ];

  static async revoke(id: string) {
    await apiClientV2.customPost(`token/${id}/revoke`);
  }

  static get defaultModel() {
    return deepCopy(TOKEN_DEFAULT);
  }
}

export interface TokenCreate {
  profile: string;
  label?: string;
}

export interface TokenNew {
  id: string;
  create_time: string;
  creator: string;
  label: string;
  profile: string;
  enabled: true;
  key: string;
  truncated_key_hash: string;
}

export const TOKEN_DEFAULT: Token = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  profile: '',
};
