














































































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { isEqual, get, set } from 'lodash';

import {
  LifeCycleState,
  BaseObject,
  ModelField,
  ModelClass,
} from '@/models/core/base';
import ObjectStateTag from '../ObjectStateTag.vue';
import {
  routeHasClientApp,
  getLocationPreservingState,
  getClientAppOfRoute,
} from '@/apps/routingUtils';
import { clientAppRouteName } from '@/apps/clientAppRegistry';
import ParentSelectorsV2 from '@/components/common/lists/ParentSelectorsV2.vue';
import FormBuilder from './FormBuilder.vue';
import { FormBuilderConfig } from './formBuilderHelper';
import { parseDotString, has } from '@/util/util';
import ObjectBreadcrumbsV2 from './ObjectBreadcrumbsV2.vue';
import { deepCopy } from '@/util/util';
import { RawLocation } from 'vue-router';
import { prepareUpdateObject } from './formUtil';

@Component({
  components: {
    ObjectStateTag,
    ParentSelectorsV2,
    ObjectBreadcrumbsV2,
    FormBuilder,
  },
})
export default class BaseFormV2 extends Vue {
  @Prop({ required: true }) modelClass!: ModelClass;
  @Prop({ required: true }) id: string;
  @Prop({ required: false }) templateId: string;
  @Prop({ default: 'is-12' }) columnWidth: string;
  @Prop({ default: false }) hasError: boolean;
  @Prop({ default: '' }) errorMessage: string;
  @Prop({ default: true }) hasLifeCycle: boolean;
  @Prop({ default: undefined }) beforeSaveHook: (data: any) => Promise<any>;
  @Prop({ default: undefined }) afterSaveHook: (data: any) => Promise<any>;
  @Prop({ default: () => [] }) warningMessages: string[];
  // whether to navigate back when saving form
  @Prop({ default: true }) navigateOnSave: boolean;
  @Prop({ default: null }) completedLocation: RawLocation | false;
  @Prop({ default: null }) customCreate: (data: any) => Promise<any>;
  @Prop({ default: true }) editable: boolean;
  @Prop({ default: null }) customConfig: FormBuilderConfig;
  // specify either customConfig or customFields, not both
  @Prop({ default: null }) customFields: ModelField[];
  @Prop({ required: false }) parentSelectorFilter: { [key: string]: string };
  @Prop({ default: true }) showSelectors: boolean;
  @Prop({ default: true }) hasTitle: boolean;
  @Prop({ default: false }) canDelete: boolean;
  @Prop({ default: null }) customDelete: (data: any) => Promise<any>;
  @Prop({ default: true }) showSaveButton: boolean;

  $refs: {
    FormBuilder: FormBuilder;
  };
  originalDataObject: any = null;
  formValidation: { isValid: boolean; errorMessages: string[] } = {
    isValid: true,
    errorMessages: [],
  };
  countOriginalChange = 0;
  modelLoaded = false;
  form: {
    $errors?: any;
    $valid?: boolean;
    loading: boolean;
  } = {
    // $valid: true,
    loading: true,
  };
  config: FormBuilderConfig | null = null;

  getConfig(): FormBuilderConfig {
    if (this.customConfig !== null) {
      return this.customConfig;
    }
    if (this.customFields !== null) {
      return {
        fields: this.modelClass.defaultFormFields(
          this.modelClass.langPath,
          this.customFields,
        ),
        model: this.modelClass.formConfig()['model'],
      };
    }
    return this.modelClass.formConfig();
  }

  get isFromTemplate() {
    return this.templateId !== undefined && this.templateId !== '';
  }

  get isNew() {
    return this.id === undefined || this.id === '0';
  }

  get prettyName() {
    return this.modelClass.prettyName();
  }

  get formValid() {
    return this.form.$valid;
  }

  @Watch('id')
  async onIdChange() {
    await this.loadForm();
  }

  async mounted() {
    await this.loadForm();
  }

  parseModel(model: any) {
    let fields = this.modelClass.fields;
    if (this.customFields !== null) {
      fields = this.customFields;
    }
    return this.modelClass.parseModel(model, fields);
  }

  setLoading(loading: boolean) {
    this.form.loading = loading;
  }

  async loadForm() {
    this.config = this.getConfig();
    if (this.isNew && !this.isFromTemplate) {
      // New object, nothing else to do
      this.originalDataObject = this.parseModel(this.config.model);
      this.$emit('loaded', this.originalDataObject);
      this.modelLoaded = true;
    } else {
      this.form.loading = true;
      const id = this.isFromTemplate ? this.templateId : this.id;
      try {
        const response = await this.$apiv2.get(this.modelClass, id);
        this.$emit('getobject', response);
        this.originalDataObject = this.parseModel(response as any);
        if (this.isFromTemplate) {
          this.originalDataObject.id = '0';
        }
        this.$emit('loaded', this.originalDataObject);
        this.config.model = deepCopy(this.originalDataObject);
        this.form.loading = false;
        this.modelLoaded = true;
      } catch (error) {
        this.handleError(error);
      }
    }
  }

  isDirty() {
    return !isEqual(
      this.$refs.FormBuilder.model,
      this.parseModel(this.originalDataObject),
    );
  }

  navigateWhenCompleted() {
    this.$emit('completed');
    if (this.completedLocation === false) {
      // do nothing
      return;
    } else if (this.completedLocation !== null) {
      // a location was given via props
      this.$router.push(this.completedLocation);
      return;
    } else {
      // try to find a decent route
      if (routeHasClientApp(this.$route)) {
        const clientApp = getClientAppOfRoute(this.$route);
        const routeName = clientAppRouteName(
          clientApp.view_id,
          this.modelClass.objectType + '-list',
        );
        const location = getLocationPreservingState(routeName, this.$router);
        if (this.$router.resolve(location).route.matched.length > 0) {
          // only go there if the location matches something
          this.$router.push(location);
          return;
        }
      }
    }
    // go somewhere at least
    this.$router.back();
  }

  async save() {
    let dataObject: any = parseDotString(this.$refs.FormBuilder.model);
    // Remove 'virtual' fields (fields that are shown in form but do not belong to model itself)
    this.modelClass.fields.forEach(field => {
      if (field.virtual && has(dataObject, field.key)) {
        delete dataObject[field.key];
      }
      if (field.display === 'false') {
        // TODO: this does not apply if a condition was set in field.display
        // Field was not displayed, reset to original
        try {
          set(dataObject, field.key, get(this.originalDataObject, field.key));
        } catch (err) {
          console.log(err);
        }
      }
    });

    const loadingComponent = this.$buefy.loading.open({});
    try {
      if (this.beforeSaveHook !== undefined) {
        await this.beforeSaveHook(dataObject);
      }

      if (!dataObject.id || dataObject.id === '0' || dataObject.id === '') {
        if (this.customCreate !== null) {
          await this.customCreate(dataObject);
        } else {
          await this.$apiv2.create(this.modelClass, dataObject);
        }
      } else {
        // Only send updated properties
        const updateDiff = prepareUpdateObject(
          dataObject,
          this.originalDataObject,
        );
        dataObject = await this.$apiv2.update(this.modelClass, updateDiff);
      }
      this.originalDataObject = deepCopy(dataObject);

      if (this.afterSaveHook !== undefined) {
        await this.afterSaveHook(dataObject);
      }

      if (this.isNew) {
        this.$emit('created', dataObject);
      } else {
        this.$emit('updated', dataObject);
      }

      if (this.navigateOnSave) {
        this.navigateWhenCompleted();
      }
    } catch (error) {
      this.handleError(error);
    }
    loadingComponent.close();
  }

  confirmDelete() {
    this.$buefy.dialog.confirm({
      message: this.$tc('common.confirmDelete'),
      onConfirm: async () => {
        if (this.customDelete !== null) {
          await this.customDelete(this.originalDataObject);
          return;
        } else {
          await this.deleteObject(this.originalDataObject);
        }
      },
    });
  }

  async deleteObject(object: BaseObject) {
    let purge = false;
    if (object.object_state === LifeCycleState.Deleted) {
      purge = true;
    }
    try {
      await this.$apiv2.delete(this.modelClass, object.id, purge);
      this.$buefy.toast.open({
        message: this.$tc('common.deleteSuccess'),
        type: 'is-success',
      });
      this.navigateWhenCompleted();
    } catch (error) {
      this.handleError(error);
    }
  }

  clearError() {
    this.formValidation.errorMessages = [];
    this.formValidation.isValid = true;
  }

  handleError(error: Error) {
    this.formValidation.errorMessages =
      this.$errorHandler.errorToStrings(error);
    this.formValidation.isValid = false;
    this.$errorHandler.handleError(error, false);
  }
}
