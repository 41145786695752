import { ToastProgrammatic as Toast } from 'buefy';
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';

export function handleError(
  error: unknown,
  showToast = true,
  logInSentry = true,
): void {
  console.error(error);

  if (showToast) {
    Toast.open({
      message: errorToString(error),
      type: 'is-danger',
      duration: 5000,
    });
  }

  if (logInSentry) {
    Sentry.captureException(error);
  }
}

export function errorToString(error: Error | AxiosError | unknown): string {
  if (
    'isAxiosError' in (error as AxiosError) &&
    (error as AxiosError).response?.data
  ) {
    return JSON.stringify((error as AxiosError).response?.data);
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return 'Unknown error';
  }
}
