






























import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device/models';
import { DataSourceOutputType, DataSourceTemplate } from '@/models/data/models';
import moment from 'moment';
import DataSourceHandler from '@/apps/data/components/explorer/DataSourceHandler.vue';

@Component({
  components: {
    DataSourceHandler,
  },
})
export default class DeviceDataExplorer extends Vue {
  @Prop({ required: true }) device!: string;
  @Prop({ required: true }) appId!: string;
  /**
   * options.template: data template ID, or comma separated list of data template IDs
   */
  @Prop({ required: true }) options!: { template: string };

  $refs!: {
    dataSourceHandler: DataSourceHandler;
  };
  errorMessages: string[] = [];
  startDate: Date = new Date();
  deviceObject: Device | null = null;

  @Watch('$route.query')
  onRouteChanged() {
    this.load();
  }

  async mounted() {
    await this.load();
  }

  async load() {
    const loading = this.$buefy.loading.open({});
    try {
      const dateString = this.$routerHandler.query('')['date'];
      if (dateString) {
        this.startDate = new Date(dateString);
      }
      this.deviceObject = await this.$apiv2.get<Device>(Device, this.device);
      const templateIds: string[] = this.options.template
        .split(',')
        .map(t => t.trim());
      for (const templateId of templateIds) {
        this.$refs.dataSourceHandler.addDataSourceTemplate({
          template: templateId,
          parameters: this.parameters,
          output_type: DataSourceOutputType.TIME_SERIES,
        });
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  get parameters() {
    const startDate = moment(this.startDate);
    const startT = startDate.startOf('day').toISOString();
    const endT = startDate.add(1, 'days').startOf('day').toISOString();
    return {
      start_time: startT,
      end_time: endT,
      device: this.device,
    };
  }

  paramsChanged() {
    this.$routerHandler.updateQuery('', { date: this.startDate.toISOString() });
    // Prepare data source request
    this.$refs.dataSourceHandler.setTemplateParameters(this.parameters);
  }
}
