

































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BackgroundTaskStates } from '@/api/BackgroundTaskHandler';
import {
  DataSource,
  DataSourceOutputType,
  DataSourceParams,
  TimeSeriesSelection,
} from '@/models/data/models';
import { BackgroundTask } from '@/models/core/models';
import TimeSeriesExplorer from './TimeSeriesExplorer.vue';
import TaskState from './TaskState.vue';
import TaskProgress from './TaskProgress.vue';
import InstructionsCard from './InstructionsCard.vue';
import moment from 'moment';
import { deepCopy, has } from '@/util/util';

@Component({
  components: {
    TimeSeriesExplorer,
    TaskState,
    TaskProgress,
    InstructionsCard,
  },
})
export default class DataSourceHandler extends Vue {
  @Prop({ required: true }) startDate!: Date;
  @Prop({ required: true }) labelSourceId: string;
  @Prop({ default: undefined }) exportFileName: string;
  @Prop({ default: false }) hasHdf5: boolean;

  $refs: {
    timeseriesExplorer: TimeSeriesExplorer;
  };
  dataSourceParams: { [templateId: string]: DataSourceParams } = {};
  dataSources: { [templateId: string]: DataSource } = {};
  exportDataSources: { [templateId: string]: DataSource } = {};
  hdf5DataSources: { [templateId: string]: DataSource } = {};
  taskWatcherHandles: { [templateId: string]: number } = {};
  exportTaskWatcherHandles: { [templateId: string]: number } = {};
  hdf5TaskWatcherHandles: { [templateId: string]: number } = {};
  bgTasks: { [templateId: string]: BackgroundTask } = {};
  exportBgTasks: { [templateId: string]: BackgroundTask } = {};
  hdf5BgTasks: { [templateId: string]: BackgroundTask } = {};
  errorMessages: string[] = [];

  BackgroundTaskStates = BackgroundTaskStates;
  timeSeriesSelection: TimeSeriesSelection = {
    startDate: moment(this.startDate).startOf('day'),
    endDate: moment(this.startDate).add(1, 'days').startOf('day'),
  };

  loading = false;
  intervalId: number;

  mounted() {
    this.intervalId = setInterval(() => this.updateBgTasks(), 8000);
  }

  destroyed() {
    clearInterval(this.intervalId);
  }

  public setTemplateParameters(templateParams: any) {
    for (const templateId in this.dataSourceParams) {
      const params: DataSourceParams = this.dataSourceParams[templateId];
      params.parameters = templateParams;
      this.addDataSourceTemplate(params);
    }
  }

  public addDataSourceTemplate(params: DataSourceParams) {
    const templateId = params.template;
    Vue.set(this.dataSourceParams, templateId, params);
    Vue.set(this.bgTasks, templateId, undefined);
    Vue.set(this.exportBgTasks, templateId, undefined);
    Vue.set(this.hdf5BgTasks, templateId, undefined);

    // Get or create data source and prepare the timeseries data
    this.createDataSource(params)
      .then((dataSource: DataSource) => {
        Vue.set(this.dataSources, templateId, dataSource);
        if (!dataSource.is_prepared) {
          this.prepareDataSource(templateId, dataSource);
        } else {
          this.onDataSourcePrepared(templateId, dataSource);
        }
      })
      .catch(error => {
        this.handleError(error);
      });

    const exportParams = deepCopy(params);
    exportParams.output_type = DataSourceOutputType.CSV_DOWNLOAD;
    this.createDataSource(exportParams)
      .then((dataSource: DataSource) => {
        Vue.set(this.exportDataSources, templateId, dataSource);
      })
      .catch(error => {
        this.handleError(error);
      });

    const hdf5Params = deepCopy(params);
    hdf5Params.output_type = DataSourceOutputType.HDF5_DOWNLOAD;
    this.createDataSource(hdf5Params)
      .then((dataSource: DataSource) => {
        Vue.set(this.hdf5DataSources, templateId, dataSource);
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  removeDataSourceTemplate(templateId: string) {
    Vue.delete(this.dataSources, templateId);
    Vue.delete(this.exportDataSources, templateId);
    Vue.delete(this.hdf5DataSources, templateId);
    if (
      has(this.taskWatcherHandles, templateId) &&
      this.taskWatcherHandles[templateId] > -1 &&
      has(this.bgTasks, templateId) &&
      this.bgTasks[templateId] !== undefined
    ) {
      this.$bgTaskHandler.unWatchTask(
        this.bgTasks[templateId].id,
        this.taskWatcherHandles[templateId],
      );
    }
    Vue.delete(this.bgTasks, templateId);

    if (
      has(this.exportTaskWatcherHandles, templateId) &&
      this.exportTaskWatcherHandles[templateId] > -1 &&
      has(this.exportBgTasks, templateId) &&
      this.exportBgTasks[templateId] !== undefined
    ) {
      this.$bgTaskHandler.unWatchTask(
        this.exportBgTasks[templateId].id,
        this.exportTaskWatcherHandles[templateId],
      );
    }
    Vue.delete(this.exportBgTasks, templateId);

    if (
      has(this.hdf5TaskWatcherHandles, templateId) &&
      this.hdf5TaskWatcherHandles[templateId] > -1 &&
      has(this.hdf5BgTasks, templateId) &&
      this.hdf5BgTasks[templateId] !== undefined
    ) {
      this.$bgTaskHandler.unWatchTask(
        this.hdf5BgTasks[templateId].id,
        this.hdf5TaskWatcherHandles[templateId],
      );
    }
    Vue.delete(this.hdf5BgTasks, templateId);

    this.$refs.timeseriesExplorer.clearTimeseries(templateId);
    delete this.dataSourceParams[templateId];
  }

  removeAllDataSourceTemplate() {
    for (const templateId in this.dataSourceParams) {
      this.removeDataSourceTemplate(templateId);
    }
  }

  createDataSource(params: DataSourceParams): Promise<DataSource> {
    return this.$api.create('data/data-source', params).then(response => {
      return response.data as DataSource;
    });
  }

  downloadLink(templateId: string): string | undefined {
    const dataSource: DataSource = this.exportDataSources[templateId];
    if (dataSource === undefined || dataSource.content === null) {
      return undefined;
    }
    if (dataSource.content.empty) {
      return undefined;
    } else {
      const date = moment(dataSource.parameters.start_time).format(
        'YYYY_MM_DD',
      );
      let filename = '';
      if (this.exportFileName) {
        filename = this.exportFileName;
      }
      return (
        dataSource.content.download_link +
        '?filename=' +
        date +
        '_' +
        filename +
        '.csv'
      );
    }
  }

  downloadHdf5Link(templateId: string): string | undefined {
    const dataSource: DataSource = this.hdf5DataSources[templateId];
    if (dataSource === undefined || dataSource.content === null) {
      return undefined;
    }
    if (dataSource.content.empty) {
      return undefined;
    } else {
      const date = moment(dataSource.parameters.start_time).format(
        'YYYY_MM_DD',
      );
      let filename = '';
      if (this.exportFileName) {
        filename = this.exportFileName;
      }
      return (
        dataSource.content.download_link +
        '?filename=' +
        date +
        '_' +
        filename +
        '.hdf5'
      );
    }
  }

  /**
   * Update background tasks by creating the data sources again and getting the background task id from the response.
   * This functions is called regularly by a setInterval setup in the mounted lifecycle hook.
   * The information from the background tasks is displayed in the state tags and progress bars.
   */
  updateBgTasks() {
    for (const templateId in this.dataSourceParams) {
      const params = this.dataSourceParams[templateId];
      this.createDataSource(params)
        .then(data => {
          if (data.bg_task) {
            return this.$api
              .get('background-task', data.bg_task)
              .then(bgTask => {
                if (has(this.bgTasks, templateId)) {
                  Vue.set(this.bgTasks, templateId, bgTask);
                }
              });
          }
        })
        .catch(err => {
          if (has(this.bgTasks, templateId)) {
            Vue.set(this.bgTasks, templateId, undefined);
          }
          console.log('Fetch background task error: ', err);
        });

      const exportParams = deepCopy(params);
      exportParams.output_type = DataSourceOutputType.CSV_DOWNLOAD;
      this.createDataSource(exportParams)
        .then(data => {
          if (data.bg_task) {
            return this.$api
              .get('background-task', data.bg_task)
              .then(bgTask => {
                if (has(this.exportBgTasks, templateId)) {
                  Vue.set(this.exportBgTasks, templateId, bgTask);
                }
              });
          }
        })
        .catch(err => {
          if (has(this.exportBgTasks, templateId)) {
            Vue.set(this.exportBgTasks, templateId, undefined);
          }
          console.log('Fetch background task error: ', err);
        });

      const hdf5Params = deepCopy(params);
      hdf5Params.output_type = DataSourceOutputType.HDF5_DOWNLOAD;
      this.createDataSource(hdf5Params)
        .then(data => {
          if (data.bg_task) {
            return this.$api
              .get('background-task', data.bg_task)
              .then(bgTask => {
                if (has(this.hdf5BgTasks, templateId)) {
                  Vue.set(this.hdf5BgTasks, templateId, bgTask);
                }
              });
          }
        })
        .catch(err => {
          if (has(this.hdf5BgTasks, templateId)) {
            Vue.set(this.hdf5BgTasks, templateId, undefined);
          }
          console.log('Fetch background task error: ', err);
        });
    }
  }

  progress(templateId: string) {
    return this.bgTasks[templateId]?.progress?.ratio * 100;
  }

  exportProgress(templateId: string) {
    return this.exportBgTasks[templateId]?.progress?.ratio * 100;
  }

  hdf5Progress(templateId: string) {
    return this.hdf5BgTasks[templateId]?.progress?.ratio * 100;
  }

  prepareDataSource(
    templateId: string,
    dataSource: DataSource,
    outputType: DataSourceOutputType = DataSourceOutputType.TIME_SERIES,
    refresh = false,
  ) {
    if (outputType === DataSourceOutputType.CSV_DOWNLOAD) {
      Vue.set(this.exportDataSources, templateId, undefined);
      Vue.set(this.exportBgTasks, templateId, undefined);
    } else if (outputType === DataSourceOutputType.HDF5_DOWNLOAD) {
      Vue.set(this.hdf5DataSources, templateId, undefined);
      Vue.set(this.hdf5BgTasks, templateId, undefined);
    } else {
      Vue.set(this.bgTasks, templateId, undefined);
      if (!refresh) {
        // Set timeseries to empty while waiting. For refresh we can leave it
        this.$refs.timeseriesExplorer.clearTimeseries(templateId);
      }
    }

    return this.$api
      .customCreate('data/data-source/' + dataSource.id + '/prepare', {
        refresh: refresh,
      })
      .then(response => {
        return this.$api.get('background-task', response.data.bg_task);
      })
      .then((bgTask: BackgroundTask) => {
        // Data source is prepared in background task. Wait for it to finish.
        if (
          bgTask === undefined ||
          this.$bgTaskHandler.isTaskRegistered(bgTask)
        ) {
          console.log('task already registered');
          return;
        }
        if (outputType === DataSourceOutputType.CSV_DOWNLOAD) {
          Vue.set(this.exportBgTasks, templateId, bgTask);
        } else if (outputType === DataSourceOutputType.HDF5_DOWNLOAD) {
          Vue.set(this.hdf5BgTasks, templateId, bgTask);
        } else {
          Vue.set(this.bgTasks, templateId, bgTask);
        }

        const handle = this.$bgTaskHandler.watchTask(
          bgTask,
          () => {
            // Only update current data if the parameters are still active
            if (!has(this.dataSourceParams, dataSource.template)) {
              return;
            }
            for (const key in this.dataSourceParams[dataSource.template]
              .parameters) {
              if (!has(dataSource.parameters, key)) {
                return;
              }
              if (
                dataSource.parameters[key] !==
                this.dataSourceParams[dataSource.template].parameters[key]
              ) {
                return;
              }
            }

            // Data Source is ready, fetch it by creating again
            this.createDataSource(dataSource).then(data => {
              if (!data.is_prepared) {
                this.handleError('Data Source Content is empty.');
              } else {
                if (outputType === DataSourceOutputType.CSV_DOWNLOAD) {
                  Vue.set(this.exportDataSources, templateId, data);
                } else if (outputType === DataSourceOutputType.HDF5_DOWNLOAD) {
                  Vue.set(this.hdf5DataSources, templateId, data);
                } else {
                  Vue.set(this.dataSources, templateId, data);
                  console.debug('prepareDataSource onDataSourcePrepared');
                  this.onDataSourcePrepared(templateId, data);
                }
              }
            });
          },
          error => {
            this.handleError(error);
            this.$buefy.toast.open({
              message: error,
              type: 'is-danger',
            });
          },
          _bgTask => {
            // this.bgTask = bgTask
          },
          5000,
          48,
        );

        if (outputType === DataSourceOutputType.CSV_DOWNLOAD) {
          Vue.set(this.exportTaskWatcherHandles, templateId, handle);
        } else if (outputType === DataSourceOutputType.HDF5_DOWNLOAD) {
          Vue.set(this.hdf5TaskWatcherHandles, templateId, handle);
        } else {
          Vue.set(this.taskWatcherHandles, templateId, handle);
        }
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  onDataSourcePrepared(templateId: string, dataSourcePrepared: DataSource) {
    if (dataSourcePrepared.content === null) {
      this.handleError('Error getting DataSource content from server.');
      return;
    }

    if (dataSourcePrepared.output_type === DataSourceOutputType.TIME_SERIES) {
      const timeSeriesId: string = dataSourcePrepared.content.time_series;
      if (timeSeriesId === '0') {
        return;
      }
      // fix "this.$refs.timeseriesExplorer is undefined"
      this.$nextTick(() => {
        this.$refs.timeseriesExplorer.addTimeseries(templateId, timeSeriesId);
        this.loading = false;
      });
    }
  }

  refreshExportDataSource(templateId: string) {
    this.refreshDataSource(templateId, DataSourceOutputType.CSV_DOWNLOAD);
  }

  refreshHdf5DataSource(templateId: string) {
    this.refreshDataSource(templateId, DataSourceOutputType.HDF5_DOWNLOAD);
  }

  refreshDataSource(
    templateId: string,
    outputType: DataSourceOutputType = DataSourceOutputType.TIME_SERIES,
  ) {
    this.errorMessages = [];
    if (outputType === DataSourceOutputType.TIME_SERIES) {
      return this.prepareDataSource(
        templateId,
        this.dataSources[templateId],
        outputType,
        true,
      );
    } else if (outputType === DataSourceOutputType.HDF5_DOWNLOAD) {
      return this.prepareDataSource(
        templateId,
        this.hdf5DataSources[templateId],
        outputType,
        true,
      );
    } else {
      return this.prepareDataSource(
        templateId,
        this.exportDataSources[templateId],
        outputType,
        true,
      );
    }
  }

  handleError(error) {
    this.loading = false;
    this.errorMessages = this.$errorHandler.errorToStrings(error);
    this.$errorHandler.handleError(error, false);
  }

  timeSeriesSelectionChanged(selection: TimeSeriesSelection) {
    this.timeSeriesSelection = selection;
  }
}
