import { insertPropsInObject, deepCopy } from '@/util/util';

export enum FormFieldType {
  INPUT = 'input',
  CHECKBOX = 'checkbox-field',
  CHECKBOX_WITH_BLOCK = 'checkbox-with-block',
  SELECTION = 'selection',
  IP_FIELD = 'ip-field',
  NUMBER_FIELD = 'number-field',
  ORDERING_FIELD = 'ordering-field',
  DATE_FIELD = 'date-field',
  DATETIME_FIELD = 'datetime-field',
  LABEL = 'label-field',
  IMAGE_UPLOAD = 'image-upload-field',
  MARKDOWN_FIELD = 'markdown-field',
  JSON_FIELD = 'json-field',
  COLOR_FIELD = 'color-field',
  ROTATION_FIELD = 'rotation-field',
  PROFILES_SELECT = 'profiles-select-field',
  RELATED_MODEL_SELECT = 'related-model-select-field',
}

interface CustomFieldType {
  // This is the type that the user can define
  type: string;
  // This is the actual type that the custom type needs to be replaced with
  realType: string;
  validators?: any;
}

const IpCustomFieldType: CustomFieldType = {
  type: FormFieldType.IP_FIELD,
  realType: 'input-with-field',
  validators: {
    length: {
      expression(field, model, next) {
        next(
          /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/.test(
            model[field.key],
          ),
        );
      },
      message: 'Invalid IP',
    },
  },
};

// TODO: Do we actually need this realType ?
const ProfilesSelectFieldType: CustomFieldType = {
  type: FormFieldType.PROFILES_SELECT,
  realType: 'profiles-select-field',
};

const DateFieldType: CustomFieldType = {
  type: FormFieldType.DATE_FIELD,
  realType: 'date-field',
};

const DateTimeFieldType: CustomFieldType = {
  type: FormFieldType.DATETIME_FIELD,
  realType: 'datetime-field',
};

const NumberFieldType: CustomFieldType = {
  type: FormFieldType.NUMBER_FIELD,
  realType: 'number-field',
};

const OrderingFieldType: CustomFieldType = {
  type: FormFieldType.ORDERING_FIELD,
  realType: 'ordering-field',
};

const RelatedModelSelectFieldType: CustomFieldType = {
  type: FormFieldType.RELATED_MODEL_SELECT,
  realType: 'related-model-select-field',
};

const MarkdownFieldType: CustomFieldType = {
  type: FormFieldType.MARKDOWN_FIELD,
  realType: 'markdown-field',
};

const JsonFieldType: CustomFieldType = {
  type: FormFieldType.JSON_FIELD,
  realType: 'json-field',
};

const ColorFieldType: CustomFieldType = {
  type: FormFieldType.COLOR_FIELD,
  realType: 'color-field',
};

const RotationFieldType: CustomFieldType = {
  type: FormFieldType.ROTATION_FIELD,
  realType: 'rotation-field',
};

const CheckboxFieldType: CustomFieldType = {
  type: FormFieldType.CHECKBOX,
  realType: 'checkbox-field',
};

const FormFieldMap = {
  [FormFieldType.INPUT]: 'input-with-field',
  [FormFieldType.CHECKBOX]: CheckboxFieldType,
  [FormFieldType.CHECKBOX_WITH_BLOCK]: 'checkbox-with-block',
  [FormFieldType.SELECTION]: 'select-with-field',
  [FormFieldType.IP_FIELD]: IpCustomFieldType,
  [FormFieldType.LABEL]: 'label-field',
  [FormFieldType.NUMBER_FIELD]: NumberFieldType,
  [FormFieldType.ORDERING_FIELD]: OrderingFieldType,
  [FormFieldType.DATE_FIELD]: DateFieldType,
  [FormFieldType.DATETIME_FIELD]: DateTimeFieldType,
  [FormFieldType.MARKDOWN_FIELD]: MarkdownFieldType,
  [FormFieldType.JSON_FIELD]: JsonFieldType,
  [FormFieldType.COLOR_FIELD]: ColorFieldType,
  [FormFieldType.ROTATION_FIELD]: RotationFieldType,
  [FormFieldType.PROFILES_SELECT]: ProfilesSelectFieldType,
  [FormFieldType.RELATED_MODEL_SELECT]: RelatedModelSelectFieldType,
};

export const FormFieldQueryBuilderMap = {
  [FormFieldType.PROFILES_SELECT]: (context: { [key: string]: string }) => {
    const query = {};
    if (context.organisation) {
      query['profile~organisation'] = context.organisation;
    }
    if (context.group) {
      query['member~group'] = context.group;
    }
    return query;
  },
};

export interface FormField {
  key: string;
  type: FormFieldType;
  required?: boolean;
  editable?: boolean;
  properties?: {
    label?: string;
    placeholder?: string;
    class?: string;
    icon?: string;
    message?: string;
    text?: string;
    inputType?: string;
    step?: number;
    maxlength?: number;
    extraData?: {
      dataID: number;
      externalName: string;
      internalName: string;
      dependencyKey: string;
    };
    options?: string[] | { text: string; value: string }[];
    [key: string]: any;
  };
  validators?: any;
  templateOptions?: any;
  display?: string;
}

interface Tab {
  name: string;
  icon?: string;
  fields?: FormField[];
}

export interface FormBuilderConfig {
  title?: string;
  tabs?: Tab[];
  fields?: FormField[];
  required?: boolean;
  disabled?: boolean;
  model: {
    [key: string]: any;
  };
}

export class FormBuilderHelper {
  private _config: FormBuilderConfig;
  private _model: any = {}; // The model

  public get config() {
    return this._config;
  }

  public get model() {
    return this._model;
  }

  public static getCommonFields(fieldLists: FormField[][]) {
    const commonFields: FormField[] = fieldLists[0];
    const unmatchedFields: FormField[] = [];
    for (let i = 1; i < fieldLists.length; i++) {
      for (const field of fieldLists[i]) {
        checkFields(fieldLists[i], commonFields, field);
      }
      for (const commonField of commonFields) {
        checkFields(commonFields, fieldLists[i], commonField);
      }
    }

    function checkFields(
      source: FormField[],
      target: FormField[],
      commonField: FormField,
    ) {
      if (
        source.findIndex(
          field =>
            field.key === commonField.key && field.type === commonField.type,
        ) > -1
      ) {
        if (
          target.findIndex(
            field =>
              field.key === commonField.key && field.type === commonField.type,
          ) > -1
        ) {
          // all good
        } else {
          const ind = commonFields.findIndex(
            field =>
              field.key === commonField.key && field.type === commonField.type,
          );
          commonFields.splice(ind, 1);
          if (
            unmatchedFields.findIndex(
              field =>
                field.key === commonField.key &&
                field.type === commonField.type,
            ) === -1
          ) {
            unmatchedFields.push(commonField);
          }
        }
      }
    }

    return {
      commonFields: commonFields,
      unmatchedFields: unmatchedFields,
    };
  }

  public static getFieldDictFromConfig(config: FormBuilderConfig): {
    [key: string]: FormField;
  } {
    const fields = this.getFieldsFromConfig(config);
    const out = {};
    for (const field of fields) {
      out[field.key] = field;
    }
    return out;
  }

  public static getFieldsFromConfig(config: FormBuilderConfig): FormField[] {
    const fields: FormField[] = [];
    if (config.tabs) {
      for (const tab of config.tabs) {
        if (tab.fields) {
          for (const field of tab.fields) {
            fields.push(field);
          }
        }
      }
    }
    if (config.fields) {
      for (const field of config.fields) {
        fields.push(field);
      }
    }
    return fields;
  }

  public static buildErrorForm(exception) {
    // TODO: Create a nicer error-field
    return new FormBuilderHelper({
      title: 'Error',
      fields: [
        {
          key: 'error',
          type: FormFieldType.LABEL,
          properties: {
            text: exception.toString(),
            label: 'Error Building Form',
          },
        },
      ],
      model: {},
    });
  }

  constructor(config: FormBuilderConfig) {
    if (config) {
      this.loadConfig(config);
    } else {
      this.loadConfig({
        model: {},
      });
    }
    return this;
  }

  public loadConfig(config: FormBuilderConfig) {
    try {
      const newConfig: FormBuilderConfig = deepCopy(config);

      // Check for custom pre-defined fields
      if (newConfig.tabs) {
        newConfig.tabs.forEach(tab => {
          this.parseFields(tab.fields, config.model);
        });
      } else if (newConfig.fields) {
        this.parseFields(newConfig.fields, config.model);
      }
      this._model = config.model;
      this._config = newConfig;
    } catch (err) {
      console.log(err);
      const errorForm = FormBuilderHelper.buildErrorForm(err);
      this._model = errorForm.model;
      this._config = errorForm.config;
    }
  }

  /* eslint-disable */
  private parseFields(fields: FormField[], model: any) {
    fields.forEach(field => {
      // If Custom Field Type, do appriopriate transformation
      if (
        FormFieldMap[field.type] &&
        (FormFieldMap[field.type] as CustomFieldType).realType !== undefined
      ) {
        const customField: CustomFieldType = FormFieldMap[
          field.type
        ] as CustomFieldType;
        insertPropsInObject(field, {
          type: customField.realType,
          validators: customField.validators,
        });
      } else if (FormFieldMap[field.type]) {
        // It is a standard field type
        insertPropsInObject(field, {
          type: FormFieldMap[field.type],
        });
      } else {
        throw new Error(`Unknown field type: ${field.type}`);
      }

      if (field.type === FormFieldType.SELECTION) {
        insertPropsInObject(field, {
          templateOptions: {
            options: field.properties.options,
          },
        });
      }
      if (field.type === FormFieldType.LABEL) {
        // No need to add anything to the model
        delete model[field.key];
      }
      if (field.type === FormFieldMap[FormFieldType.INPUT]) {
        if (field.properties && field.properties.inputType) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                type: field.properties.inputType,
              },
            },
          });
        }
        if (field.properties && field.properties.step) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                step: field.properties.step,
              },
            },
          });
        }
        if (field.properties && field.properties.maxlength) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                maxlength: field.properties.maxlength,
              },
            },
          });
        }
      }

      if (field.display) {
        insertPropsInObject(field, {
          ...{
            display: field.display,
          },
        });
      }

      // For certain properties, transform field
      if (field.properties) {
        insertPropsInObject(field, {
          ...field.properties,
        });

        if (field.properties.label) {
          insertPropsInObject(field, {
            templateOptions: {
              wrapper: {
                properties: {
                  addons: false,
                  label: `${field.properties.label}${
                    field.required ? ' *' : ''
                  }`,
                },
              },
            },
          });
        }

        if (field.properties.message) {
          insertPropsInObject(field, {
            templateOptions: {
              wrapper: {
                properties: {
                  message: field.properties.message,
                },
              },
            },
          });
        }

        if (field.properties.class) {
          insertPropsInObject(field, {
            templateOptions: {
              wrapper: {
                properties: {
                  class: field.properties.class,
                },
              },
            },
          });
        }

        if (field.properties.placeholder) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                placeholder: field.properties.placeholder,
              },
            },
          });
        }

        if (field.properties.icon) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                icon: `mdi ${field.properties.icon}`,
              },
            },
          });
        }

        if (field.properties.options !== undefined) {
          insertPropsInObject(field, {
            templateOptions: {
              options: field.properties.options,
            },
          });
        }

        if (model.id && model.id !== '0') {
          if (field.editable !== undefined && field.editable === false) {
            insertPropsInObject(field, {
              templateOptions: {
                properties: {
                  disabled: true,
                },
              },
            });
          }
        }

        if (field.properties.disabled) {
          insertPropsInObject(field, {
            templateOptions: {
              properties: {
                disabled: true,
              },
            },
          });
        }
      }
    });
  }
}
