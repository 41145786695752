










































import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { DeviceSettingKind } from '@/models/device/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import ParentSelectors from '@/components/common/ParentSelectors.vue';
import ObjectBreadcrumbs from '@/components/common/ObjectBreadcrumbs.vue';

import { deviceRouteName } from '../app';

@Component({
  name: 'device-setting-kind-form',
  components: {
    BaseForm,
    ParentSelectors,
    ObjectBreadcrumbs,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      deviceSettingKind: DeviceSettingKind,
    };
  },
  mixins: [BeforeLeaveGuard],
  methods: {
    deviceRouteName,
  },
})
export default class DeviceSettingKindForm extends Vue {
  deviceSettingKind: DeviceSettingKind;
  loading = false;

  mounted() {
    this.loading = true;
    this.$api.get('device-setting-kind', this.$props.id).then(response => {
      this.deviceSettingKind = response;
      this.loading = false;
    });
  }

  updated() {
    this.deviceSettingKind.model =
      this.$store.getters['global/model'] &&
      this.$store.getters['global/model'].id;
  }

  get isNew() {
    return this.$props.id === '0';
  }
}
