import { ClientAppEntry, clientAppRouteName } from '../clientAppRegistry';

export const VIEW_ID = 'web-firmware';

export const CLIENT_APP: ClientAppEntry = {
  name: 'Firmware',
  path: 'fw',
  icon: 'mdi-memory',
  defaultPathName: 'channel-root',
};

export function firmwareRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName);
}
