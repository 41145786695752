import { ToastProgrammatic as Toast } from 'buefy';
import * as Sentry from '@sentry/vue';
import { has } from '@/util/util';

function isObject(o) {
  return o instanceof Object && o.constructor === Object;
}

/**
 * Turn error into strings
 * @param error
 * @param prettyString only show response data and not things like "Error: Request failed with status code 400"
 */
export function errorToStrings(error: any, prettyString = false): string[] {
  let errorMessages = [];
  if (error.response === undefined) {
    if (error.message) {
      errorMessages = [error.message];
      return errorMessages;
    } else {
      try {
        const errMsg = error.toString();
        return [errMsg];
      } catch (_) {
        return ['Unknown error.'];
      }
    }
  }
  if (error.response.data && isObject(error.response.data)) {
    if (!prettyString) {
      errorMessages = [error.message];
    }
    for (const key of Object.keys(error.response.data).sort()) {
      if (has(error.response.data, key)) {
        if (prettyString) {
          errorMessages.push(error.response.data[key]);
        } else {
          errorMessages.push(key + ': ' + error.response.data[key]);
        }
      }
    }
  } else if (
    error.response.data.constructor === Array &&
    error.response.data.length > 0
  ) {
    // Include all returned errors:
    errorMessages = error.response.data;
  } else if (error.message) {
    errorMessages = [error.message];
  } else {
    errorMessages = [error];
  }
  return errorMessages;
}

export function errorToString(error: any): string {
  return errorToStrings(error).join('\n');
}

export class ErrorHandlerClass {
  static install(vue, options): void {
    vue.prototype.$errorHandler = ErrorHandler;
  }

  errorToString(error: any): string {
    return errorToString(error);
  }

  errorToStrings(error: any): string[] {
    return errorToStrings(error);
  }

  /**
   * Utility function to handle errors.
   * Always prints to console.error. By default, it also shows a toast to the user and logs in Sentry.
   * @param error
   * @param showToast
   * @param logInSentry
   */
  handleError(error: unknown, showToast = true, logInSentry = true) {
    // always print to console.error
    console.error(error);

    if (showToast) {
      Toast.open({
        message: errorToString(error),
        type: 'is-danger',
        duration: 5000,
      });
    }

    if (logInSentry) {
      Sentry.captureException(error);
    }
  }
}

export const ErrorHandler = new ErrorHandlerClass();
