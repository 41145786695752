




















import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { DeviceAssignment } from '@/models/study/models';
import { ListModelField } from '@/models/core/base';

@Component({})
/**
 * Show participant to which a certain device relation (source device) is assigned.
 */
export default class ParticipantAssignment extends Vue {
  @Prop({ required: true }) deviceRelation: string;

  DeviceAssignment = DeviceAssignment;

  get filter() {
    return {
      device_relation: this.deviceRelation,
    };
  }

  get columns() {
    const listFields: ListModelField[] = [
      {
        key: 'patient_pid',
      },
      {
        key: 'role',
      },
      {
        key: 'effective_time',
        transform: (value: string) => {
          return new Date(value).toLocaleString();
        },
      },
    ];
    return DeviceAssignment.defaultColumns(
      DeviceAssignment.langPath,
      listFields,
    );
  }
}
