import { ClientAppEntry } from '@/apps/clientAppRegistry';

export const VIEW_ID = 'dh-study';

export const CLIENT_APP: ClientAppEntry = {
  name: 'Device Hub Study',
  path: 'dhstudy',
  icon: 'mdi-account-multiple-plus',
  defaultPathName: 'dh-study-root',
};

export function dhStudyRouteName(routeName: string): string {
  return `${VIEW_ID}-${routeName}`;
}
