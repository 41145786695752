export const auth = {
  profile: 'Profile',
  firstName: 'First Name',
  lastName: 'Last Name',
  username: 'Username',
  password: 'Password',
  passwordRepeat: 'Password (Repeat)',
  passwordNoMatch: 'Passwords do not match',
  changePassword: 'Change password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  repeatPassword: 'New password (repeat)',
  forgotPassword: 'Forgot password?',
  activateAccount: 'Activate account',
  accountActivation: 'Account activation',
  emailPinInfo: 'An E-Mail with a PIN will be sent to your address.',
  email: 'E-Mail',
  newEmail: 'New E-Mail',
  newEmailRepeat: 'New E-Mail (repeat)',
  changeEmail: 'Change E-Mail',
  resetPassword: 'Reset password',
  pinFromEmail: 'PIN (from E-Mail)',
};
