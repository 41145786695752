export function getCookie(key: string): string | undefined {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith(key))
    ?.split('=')[1];
}

export function addCookie(key: string, value: string, max_age: number): string {
  const encoded_value = encodeURIComponent(value);
  document.cookie = key + '=' + encoded_value + '; max-age=' + max_age;
  return document.cookie;
}
