export const DELIVERY_PROCEDURE_STAGES = {
  'builtin-download-local': {
    plugin_id: 'builtin-download-local',
    config: {
      version: 1,
      content_key: 'input',
      mime_type: 'application/octet-stream',
      expire_in: 5,
    },
  },
};
