































import { Component, Vue, Prop } from 'vue-property-decorator';
import { globalStore } from '@/store/modules/global';

@Component({})
// Calling it NavFooter because 'footer' is reserved html tag
export default class NavFooter extends Vue {
  @Prop({ default: false }) showSideBar!: boolean;

  get frontendVersion(): string {
    return globalStore.frontendVersion;
  }

  get backendVersion(): string {
    return globalStore.backendVersion;
  }
}
