import { RouteConfig } from 'vue-router';
import { clientAppRegistryGet } from '@/apps/clientAppRegistry';
import { VIEW_ID, monitoringRouteName } from './app';

import RouterPassThrough from '@/components/common/RouterPassThrough.vue';

import MainMenu from '@/apps/monitoring/components/MainMenu.vue';
import { DataApplication } from '@/models/data/models';
import { apiClientV2 } from '@/api/ApiClientV2';
import { globalStore } from '@/store/modules/global';
import { ErrorHandler } from '@/api/ErrorHandler';
import { ClientApp } from '@/models/client/models';

export async function beforeEnterMonitoringApp(): Promise<void> {
  // set context selection to data application from client app settings
  try {
    // fetch client app settings and keep in store
    await globalStore.fetchClientAppSettings();
    const settingData = globalStore.clientAppSetting('data_application');
    const appId = settingData?.value?.data_application_id;
    if (!appId) {
      throw new Error('No data application ID in client app settings.');
    }
    if (settingData?.value?.data_client_app_id) {
      const dataClientApp = await apiClientV2.get<ClientApp>(
        ClientApp,
        settingData.value.data_client_app_id,
      );
      globalStore.setSelectedObject({
        objectType: 'dataClientApp',
        object: dataClientApp,
      });
    }
    const app = await apiClientV2.get<DataApplication>(DataApplication, appId);
    if (!app) {
      throw new Error('No data application found.');
    }
    globalStore.setSelectedObject({ objectType: 'application', object: app });
  } catch (error) {
    globalStore.setSelectedObject({ objectType: 'application', object: null });
    ErrorHandler.handleError(error);
  }
}

export const globalMonitoringRoutes: RouteConfig[] = [
  {
    path: '/monitoring',
    name: 'standalone-monitoring',
    props: {
      standalone: true,
    },
    meta: {
      hideSidebar: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "dashboard-list" */ './components/MonitoringDashboard.vue'
      ),
  },
];

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: monitoringRouteName('dashboard-root') },
  components: {
    'default': RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterMonitoringApp,
  },
  children: [
    {
      path: 'dashboard',
      name: monitoringRouteName('dashboard-root'),
      redirect: { name: monitoringRouteName('dashboard-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: monitoringRouteName('dashboard-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "dashboard-list" */ './components/MonitoringDashboard.vue'
            ),
        },
        {
          path: ':id',
          name: monitoringRouteName('dashboard-detail'),
          props: route => ({
            id: route.params.id,
            mode: 'deviceRelation',
          }),
          component: () =>
            import(
              /* webpackChunkName: "dashboard-detail" */ '../data/components/DeviceDetail.vue'
            ),
        },
      ],
    },
    {
      path: 'settings',
      name: monitoringRouteName('settings-root'),
      redirect: { name: monitoringRouteName('settings-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: monitoringRouteName('settings-list'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "settings-list" */ './components/MonitoringSettings.vue'
            ),
        },
      ],
    },
  ],
};

export default route;
