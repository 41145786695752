

























import { Channel } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'channel-detail',
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      channel: Channel,
      loading: true,
    };
  },
  components: {},
})
export default class ChannelDetail extends Vue {
  channel: Channel;
  loading: boolean;

  mounted() {
    this.loadChannel(this.$props.id);
  }

  loadChannel(id: string) {
    this.loading = true;
    this.$api
      .get('channel', id)
      .then(response => {
        this.channel = response;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      });
  }
}
