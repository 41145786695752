import { render, staticRenderFns } from "./AttachmentList.vue?vue&type=template&id=23080f78&scoped=true&"
import script from "./AttachmentList.vue?vue&type=script&lang=ts&"
export * from "./AttachmentList.vue?vue&type=script&lang=ts&"
import style0 from "./AttachmentList.vue?vue&type=style&index=0&id=23080f78&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23080f78",
  null
  
)

export default component.exports