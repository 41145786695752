
















import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({
  name: 'formly_date-time-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class DateField extends Vue {
  parsedDate: Date = new Date();

  mounted() {
    if (this.$props.model[this.$props.field.key]) {
      this.parsedDate = moment(
        this.$props.model[this.$props.field.key],
      ).toDate();
    } else {
      this.setDate(new Date().toISOString());
    }
  }

  setDate(newValue) {
    this.$props.model[this.$props.field.key] = newValue;
  }
}
