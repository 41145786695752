













































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {
  DeviceRelation,
  DeviceModel,
  DeviceDetailComponent,
} from '@/models/data/models';
import { Device } from '@/models/device/models';
import { DEVICE_DEFAULT } from '@/models/device/defaults';
import { DEVICE_RELATION_DEFAULT } from '@/models/data/defaults';
import { ClientApp } from '@/models/client/models';
import { capitalizeAfterSeparator } from '@/util/util';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { deepCopy } from '@/util/util';
import { deviceDetailComponents } from '@/apps/data/components/device/deviceDetail';

@Component({
  components: {
    ...deviceDetailComponents,
  },
  mixins: [BeforeLeaveGuard],
})
export default class DeviceDetail extends Vue {
  /**
   * ID of device or device-relation, depending on mode
   */
  @Prop({ required: true }) id!: string;

  /**
   * This component can be used in two modes:
   * - device: as detail view of device in device app
   * - deviceRelation: as detail view of device-relation in data app
   */
  @Prop({ default: 'device' }) mode!: 'device' | 'deviceRelation';

  @Watch('id')
  async onIdChanged(): Promise<void> {
    await this.init();
  }

  activeTab = 0;
  deviceRelation: DeviceRelation = deepCopy(DEVICE_RELATION_DEFAULT);
  device: Device = deepCopy(DEVICE_DEFAULT);
  deviceDetail: DeviceDetailComponent[] = [];
  originalName = '';
  loading = true;

  $refs!: { [key: string]: any };

  shouldWarnOnLeaveFlag = false;

  runShouldWarnOnLeaveCheck(): void {
    // check if any child component has shouldWarnOnLeave === true
    this.shouldWarnOnLeaveFlag = this.deviceDetail
      .map(detail => this.$refs[`${this.id}${detail.component}`])
      .some(component => {
        // any child component has shouldWarnOnLeave === true
        return (
          (component && component.shouldWarnOnLeave === true) ||
          component?.[0]?.shouldWarnOnLeave
        );
      });
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    this.loading = true;
    const loadingComponent = this.$buefy.loading.open({});
    try {
      if (this.mode === 'deviceRelation') {
        this.deviceRelation = await this.$apiv2.get<DeviceRelation>(
          DeviceRelation,
          this.id,
        );
        this.device = await this.$apiv2.get<Device>(
          Device,
          this.deviceRelation.device,
        );
      } else if (this.mode === 'device') {
        this.device = await this.$apiv2.get<Device>(Device, this.id);
      } else {
        throw new Error(`Unknown mode ${this.mode}.`);
      }
      this.originalName = this.device.name ?? '';
      this.deviceDetail = await this.getDeviceDetail();
      this.$nextTick(() => {
        // need to wait for b-tabs to load
        let tab = parseInt(this.$routerHandler.query('')['tab']);
        if (
          tab >= this.deviceDetail.length ||
          tab < 0 ||
          tab === undefined ||
          tab === null ||
          isNaN(tab)
        ) {
          tab = 0;
        }
        this.activeTab = tab;
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
    this.loading = false;
  }

  setTab(): void {
    this.$routerHandler.updateQuery('', { tab: this.activeTab.toString() });
  }

  get disableSaveName(): boolean {
    return this.originalName === this.device.name;
  }

  async saveName(): Promise<void> {
    const loadingComponent = this.$buefy.loading.open({});
    try {
      await this.$apiv2.update<Device, Device>(Device, this.device);
      this.originalName = this.device.name ?? '';
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loadingComponent.close();
  }

  /**
   * Get device detail from client app settings
   * Implemented components are:
   * - device-settings
   * - device-history-list
   * - update-request-list
   * - gateway-software-log
   * - links-list
   * - everion-settings-view
   * - activity-tracker-settings-view
   * - activity-tracker-readout-progress-view
   * - device-state-view
   * - gateway-view
   * - gateway-everion-assignment-view
   * - device-data-explorer
   */
  async getDeviceDetail(): Promise<DeviceDetailComponent[]> {
    let deviceDetailComponents: DeviceDetailComponent[] = [];
    try {
      const clientAppId: string =
        this.$store.getters['global/selectedClientApp'].id;
      const setting = await ClientApp.getSetting(
        this.$apiv2,
        clientAppId,
        'device_models',
      );
      if (setting?.value) {
        const deviceModels: DeviceModel[] = setting.value.device_models || [];
        const deviceModel = deviceModels.find(deviceModel => {
          return deviceModel.id === this.device.model;
        });
        if (deviceModel?.device_detail) {
          deviceDetailComponents = deviceModel.device_detail;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // client app setting does not exist, do nothing and use default
        console.log(error);
      } else {
        this.$errorHandler.handleError(error);
      }
    } finally {
      if (!deviceDetailComponents.length) {
        // get default for device app
        if (this.mode === 'device') {
          deviceDetailComponents = [
            { component: 'device-settings' },
            { component: 'device-history-list' },
            { component: 'update-request-list' },
          ];
        }
      }
    }
    return deviceDetailComponents;
  }

  /**
   * Provide name to be shown in tab
   */
  provideName(deviceDetailComponent: DeviceDetailComponent): string {
    // if name is configured, use this
    if (deviceDetailComponent.name) {
      return deviceDetailComponent.name;
    }
    // otherwise get from component name
    return capitalizeAfterSeparator(deviceDetailComponent.component, [
      'list',
      'view',
    ]);
  }
}
