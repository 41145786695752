import Vue from 'vue';
import Component from 'vue-class-component';
import BaseFormV2 from '@/components/common/forms/BaseFormV2.vue';

@Component({
  beforeRouteLeave(to, fromWhere, next) {
    this.runShouldWarnOnLeaveCheck();
    let ans = true;
    try {
      if (this.shouldWarnOnLeave || this.shouldWarnOnLeaveFlag) {
        ans = window.confirm(this.$tc('common.leaveWithoutSaveQuestion'));
      }
      if (ans) {
        next();
      } else {
        next(false);
      }
    } catch (e) {
      console.error(e);
      next();
    }
  },
})
export class BeforeLeaveGuard extends Vue {
  shouldWarnOnLeaveFlag = false;

  /**
   * Can overwrite this getter in component if necessary
   */
  get shouldWarnOnLeave(): boolean {
    const baseForm = this.$refs.baseForm as BaseFormV2;
    if (baseForm === undefined) {
      console.warn('BeforeLeaveGuard not used correctly');
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return baseForm.isDirty() && !baseForm.isNew;
  }

  /**
   * Can be used to implement a custom fuction to set the shouldWarnOnLeaveFlag
   * that cannot be implemented as a getter
   */
  runShouldWarnOnLeaveCheck() {}
}
