








import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component({})
export default class AutoRefresh extends Vue {
  @Prop({ default: 2000 }) time: number;

  @Prop({ default: false }) enabled: boolean;

  autoRefresh = false;
  interval: number;

  mounted() {
    if (this.enabled) {
      this.autoRefresh = true;
      this.toggle();
    }
  }

  destroyed() {
    this.stop();
  }

  toggle() {
    if (this.autoRefresh) {
      this.start();
    } else {
      this.stop();
    }
  }

  start() {
    this.interval = setInterval(() => {
      this.$emit('refresh');
    }, this.time);
  }

  stop() {
    clearInterval(this.interval);
  }
}
