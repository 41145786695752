
















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ModelClass } from '@/models/core/base';
import { clientAppRouteName } from '@/apps/clientAppRegistry';
import { RawLocation } from 'vue-router';

@Component({})
export default class ObjectBreadcrumbsButton extends Vue {
  @Prop({ required: true }) modelClass: ModelClass;
  @Prop({ required: true }) id: string;
  to: RawLocation = null;

  displayName = '';

  @Watch('id')
  async onIdChanged() {
    await this.getBreadcrumb();
  }

  async mounted() {
    await this.getBreadcrumb();
  }

  get label(): string {
    return this.modelClass.prettyName();
  }

  async getBreadcrumb() {
    if (this.id) {
      const result = await this.$apiv2.get<any>(this.modelClass, this.id);
      if (
        this.modelClass.displayProperty &&
        result[this.modelClass.displayProperty]
      ) {
        this.displayName = result[this.modelClass.displayProperty];
      } else {
        this.displayName = result.name || result.handle || '';
      }
      const clientApps = this.$store.getters['global/collection']('client-app');
      const destinationApp =
        clientApps &&
        clientApps.objects.find(
          app => app.view_id === this.modelClass.defaultViewId,
        );
      this.to = {
        name: clientAppRouteName(
          this.modelClass.defaultViewId,
          `${this.modelClass.objectType}-detail`,
        ),
        params: {
          id: result.id,
          app_handle: destinationApp && destinationApp.handle,
        },
      };
    }
  }
}
