










import Vue from 'vue';
import Component from 'vue-class-component';
import { Device } from '@/models/device/models';

@Component({})
export default class DeviceList extends Vue {
  Device = Device;
}
