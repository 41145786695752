// see \\nas.leitwert.local\Leitwert\02_Produkte\14_Devicemanagement\11_Development\Information logged by the DMS.xlsx
export enum DeviceEventType {
  FIRMWARE_VERSION = 'firmware-version',
  UPDATE_REQUEST = 'update-request',
  MANUFACTURING_TEST = 'manufacturing-test',
  DEVICE_DATA = 'device-data',
  DEVICE_SESSION = 'device-session-event',
}

export enum UpdateRequestState {
  READY = 'ready',
  PENDING = 'pending',
  QUEUED = 'queued',
  PROCESSING = 'processing',
  CREATED = 'created',
  SUCCESS = 'success',
  FAIL = 'fail',
  UP_TO_DATE = 'up_to_date',
  CONFIRMED = 'confirmed',
}

export enum UpdateRequestEventTrigger {
  REQUEST = 'request',
  START = 'start',
  CREATED = 'created',
  CLEANUP = 'cleanup',
  CONFIRM = 'confirm',
  FAIL = 'fail',
}

export interface EventLogResult<T> {
  page: number;
  page_size: number;
  results: T[];
}

export interface DeviceEvent {
  time: string;
  expiration_time?: string;
  event_type: DeviceEventType;
  identity: string;
  organisation: string;
  model: string;
  device: string;
  payload: {
    [key: string]: any;
  };
}

export interface DeviceDataEvent extends DeviceEvent {
  event_type: DeviceEventType.DEVICE_DATA;
  payload: {
    data_index: string;
    time: string;
    stream_id: string;
    readout_time?: string;
    data_json?: string;
    data?: {
      columns: (number | string)[];
      index: (number | string)[];
      data: (number | string)[][];
    };
    gateway_info?: {
      device_id: string;
      model: string;
    };
    [key: string]: any;
  };
}

export interface UpdateRequestEvent extends DeviceEvent {
  event_type: DeviceEventType.UPDATE_REQUEST;
  payload: {
    fields: {
      delivery_method: string;
      parameters: any;
      target_channel: string;
      plan: any;
      target_firmware: string;
      current_channel: string;
      model: string;
      delivery_procedure_id_text: string;
      current_firmware: string;
      state: UpdateRequestState;
      delivery_procedure: string;
      reason: string;
      event_trigger: UpdateRequestEventTrigger;
      firmware_update: string;
    };
    version: number;
  };
}

export interface FirmwareVersionEvent extends DeviceEvent {
  event_type: DeviceEventType.FIRMWARE_VERSION;
  payload: {
    fields: {
      firmware: string;
      firmware_hash: string;
      channel_id_text: string;
      firmware_version: string;
      channel: string;
      model: string;
    };
    version: number;
  };
}

export interface EventLabel {
  label: string;
  kind: 'patient' | string;
  device: string;
  time: string;
  event_type: string;
  identity: string;
}

export interface DeviceEventLogEventQuery {
  order_by?: string;
  page: number;
  page_size: number;
  device?: string;
  model?: string;
  start_time?: string;
  end_time?: string;
  event_type?: string;
  identity?: string;
}

export interface DeviceEventLogLabelQuery {
  order_by?: string;
  page: number;
  page_size: number;
  device: string;
  start_time?: string;
  end_time?: string;
  label_kind: string;
  event_type?: string;
  identity?: string;
}
