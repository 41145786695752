













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class TaskProgress extends Vue {
  // progress between 0 and 100
  @Prop({ required: true }) progress: number;
}
