import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { ClientApp, ClientAppSetting } from '@/models/client/models';
import { apiClientV2 } from '@/api/ApiClientV2';

export interface StudyState {
  dataClientApp: ClientApp | null;
  dataAppSettings: ClientAppSetting[];
}

/**
 * dynamic: true -> register module into store with name
 */
@Module({ dynamic: true, store: store, name: 'study', namespaced: true })
class Study extends VuexModule implements StudyState {
  dataClientApp: ClientApp | null = null;
  dataAppSettings: ClientAppSetting[] = [];

  get dataAppSetting() {
    // return function getter to allow for parameter
    return (key: string) => {
      return this.dataAppSettings.find(setting => setting.key === key);
    };
  }

  @Mutation
  setDataAppSettings(settings: ClientAppSetting[]) {
    this.dataAppSettings = settings;
  }

  @Mutation
  setDataClientApp(clientApp: ClientApp) {
    this.dataClientApp = clientApp;
  }

  @Action
  async fetchDataAppSettings(clientAppId: string) {
    const clientApp = await apiClientV2.get<ClientApp>(ClientApp, clientAppId);
    this.setDataClientApp(clientApp);
    const settings = await ClientApp.getSettings(apiClientV2, clientAppId);
    this.setDataAppSettings(settings);
  }
}

export const studyStore = getModule(Study);
