














































































import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-encrypt-lwefwv2',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class EncryptLWEFWV2 extends Vue {
  plugin: DeliveryProcedurePlugin;

  mounted() {
    this.plugin = this.$props.plugin;
    this.plugin.config['regions'].forEach(region => {
      this.plugin.config['regions'].push(region);
    });
  }

  addRegion() {
    this.plugin.config['regions'].push({
      start: '',
      end: '',
    });
  }

  removeRegion(index) {
    this.plugin.config['regions'].splice(index, 1);
  }

  getLabel(index, type) {
    if (index === 0) {
      if (type === 0) {
        return 'Start';
      } else if (type === 1) {
        return 'End';
      } else if (type === 2) {
        return 'Remove';
      }
    }
  }
}
