











































































import Vue from 'vue';
import Component from 'vue-class-component';
import OrganisationSelector from './OrganisationSelector.vue';
import ClientAppSelector from './ClientAppSelector.vue';
import { mapState } from 'vuex';
import { authStore } from '@/store/modules/auth/auth';

@Component({
  name: 'push-sidebar',
  components: {
    OrganisationSelector,
    ClientAppSelector,
  },
  computed: {
    ...mapState('global', ['context']),
  },
})
export default class PushSideBar extends Vue {
  get isCollapsed() {
    return (
      this.$store.getters['global/navigationIsCollapsed'] ||
      this.$route.params.app_handle === undefined ||
      !this.isLoggedIn
    );
  }

  get isLoggedIn() {
    return authStore.isLoggedIn;
  }

  get selectionIsCollapsed() {
    return this.$store.getters['global/selectionIsCollapsed'];
  }

  get navigationTitle() {
    if (
      this.$store.getters['global/selectedClientApp'] !== null &&
      this.$store.getters['global/selectedClientApp'].name
    ) {
      return this.$store.getters['global/selectedClientApp'].name;
    } else {
      return 'Navigation';
    }
  }

  get hasOrganisationSelection() {
    const organisations = this.$store.getters['global/contextOrganisations'];
    return organisations && organisations.objects.length > 1;
  }

  collapse() {
    this.$store.commit('global/setNavigationCollapsed', { value: true });
    // hide client app menu also on mobile
    this.$store.commit('global/setReaderMode', { value: true });
  }

  toggleSelectionOpen() {
    this.$store.commit('global/setSelectionCollapsed', {
      value: !this.selectionIsCollapsed,
    });
  }
}
