
















import { Vue, Component } from 'vue-property-decorator';

import { routePageSize, routeHasPagination } from '@/apps/routingUtils';

@Component({})
export default class ListPagination extends Vue {
  get showPageSizeSelect(): boolean {
    // TODO: Should be defined in model, not route
    return routeHasPagination(this.$route);
  }

  get currentPageSize(): number {
    return routePageSize(this.$route);
  }

  get pageSizes(): number[] {
    const routePagination = this.$route.meta.hasPagination;
    if (!routePagination) {
      return [];
    }
    return routePagination.pageSizes;
  }

  paginationChange(pageSize: string): void {
    const redirect = {
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page_size: pageSize,
        page: undefined,
      },
    };
    this.$routerHandler.push(redirect);
  }
}
