













































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  determineReadoutProgress,
  ActivityTrackerReadoutProgress,
} from '@/models/device/activity_tracker/models';
import { DeviceState } from '@/models/device/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// const mock: ActivityTrackerReadoutProgress = {
//   valid: true,
//   reason: null,
//   inProgress: true,
//   value: 0.75,
//   sensorIds: [],
// }

@Component({
  props: {
    device: {
      required: true,
    },
    appId: {
      required: true,
    },
  },
})
export default class ActivityTrackerReadoutProgressView extends Vue {
  progress: ActivityTrackerReadoutProgress = {
    valid: false,
    reason: null,
    inProgress: false,
    value: null,
    sensorIds: [],
  };
  destroySubject = new Subject<void>();

  async mounted() {
    await this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async load() {
    // this.progress = mock
    try {
      const result = await this.$apiv2.find<DeviceState>(DeviceState, {
        device: this.$props.device,
        application: this.$props.appId,
      });
      this.progress = await determineReadoutProgress(result.data);
    } catch (error) {
      // Device state does not exist
    }
  }
}
