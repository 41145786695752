import {
  BASE_OBJECT_DEFAULT,
  BaseObject,
  ListModelField,
  ModelField,
  CellType,
} from '@/models/core/base';
import { ApiClientV2, Annotation, RelatedAnnotation } from '@/api/ApiClientV2';
import { getAvatarAnnotation } from '@/models/core/util';
import { deepCopy } from '@/util/util';

export class Organisation extends BaseObject {
  name: string;
  password_policy?: {
    version: number;
    min_length: number;
    alpha_upper: boolean;
    alpha_lower: boolean;
    numeric: boolean;
    special_char: boolean;
  };
  lifecycle_policy?: {
    enable_reviews: boolean;
    require_reviewer: boolean;
  };
  meta?: {
    [key: string]: any;
  };
  avatar?: string;
  slug?: string;
  slug_unique?: string;
  email_template_namespace?: string;

  static showLifeCycle = false;
  static apiUrl = 'organisation';
  static objectType = 'organisation';
  static langPath = 'admin.organisation';
  static fields: ModelField[] = [{ key: 'name', icon: 'mdi-account' }];

  static listFields: ListModelField[] = [
    { key: 'name' },
    { key: 'avatar_img', cellType: CellType.IMAGE },
  ];
  static get annotations(): Annotation<Organisation>[] {
    return [
      {
        key: 'avatar_img',
        callback: getAvatarAnnotation,
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...ORGANISATION_DEFAULT },
    };
  }

  static get defaultModel() {
    return deepCopy(ORGANISATION_DEFAULT);
  }

  static async deactivate(apiClient: ApiClientV2, organisation: Organisation) {
    await apiClient.customPost(`organisation/${organisation.id}/deactivate`);
  }

  static async activate(apiClient: ApiClientV2, organisation: Organisation) {
    await apiClient.customPost(`organisation/${organisation.id}/activate`);
  }

  static async updatePasswordPolicy(
    apiClient: ApiClientV2,
    organisation: string,
    passwordPolicy: Organisation['password_policy'],
  ) {
    await apiClient.customPost(
      `organisation/${organisation}/update-password-policy`,
      passwordPolicy,
    );
  }
}

export const ORGANISATION_DEFAULT: Organisation = {
  ...BASE_OBJECT_DEFAULT,
  name: '',
  password_policy: {
    version: 1,
    min_length: 5,
    alpha_upper: true,
    alpha_lower: true,
    numeric: false,
    special_char: false,
  },
  lifecycle_policy: {
    enable_reviews: false,
    require_reviewer: false,
  },
  meta: {},
  avatar: '',
  slug: '',
  slug_unique: '',
};
