export const monitoring = {
  menu: {
    dashboard: 'Dashboard',
    settings: 'Settings',
  },
  tile: {
    deviceName: 'Device',
    pid: 'PID',
    time: 'Time',
    value: 'Value',
    quality: 'Quality',
    wearing: 'Wearing',
    details: 'Details',
    hasCustomThresholds: 'has custom thresholds',
    confirmDisableDevice: 'Are you sure that you want to disable this device?',
  },
  battery: {
    battery: 'Battery',
    onChargingStation: 'On Charging Station',
    isCharging: 'Charging',
  },
  settings: {
    selectDevices: 'Select Devices',
    orderDevices: 'Order Devices',
  },
  thresholds: {
    customThresholds: 'Custom Thresholds',
    defaultValuesUsed: 'The default values are already used.',
    upper: 'Upper Threshold',
    lower: 'Lower Threshold',
  },
  createTileLink: {
    tileLink: 'Tile Link',
    name: 'Link Name (optional)',
    created: 'Created',
    confirmRevoke: 'Are you sure that you want to revoke this link?',
    createLinkInfo:
      'You can create a unique link that will display only this tile.',
    activeLinkInfo:
      'There is currently a link active for this tile. You can revoke it here.',
    newLinkInfo:
      'This is your new link. Please copy it now. It will not be shown again. ',
  },
};
