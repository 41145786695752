































import Vue from 'vue';
import Component from 'vue-class-component';
import { PRETTY_NAMES } from '@/models/core/defaults';
import { getAllAncestors, getDependencies } from '@/models/objectRegistry';

const LIST_FILTER = 'list-filter';
const OBJECT_CREATION = 'object-creation';

@Component({
  name: 'parent-selectors',
  props: {
    objectType: {
      default: '',
    },
    type: {
      type: String,
      default: LIST_FILTER,
      validator: value => [LIST_FILTER, OBJECT_CREATION].indexOf(value) !== -1,
    },
    showLabels: {
      default: true,
    },
  },
})
export default class ParentSelectors extends Vue {
  get routeFilters() {
    const queryParameters =
      this.$route.meta.hasQuery && Object.keys(this.$route.meta.hasQuery);
    if (!queryParameters) {
      return [];
    }

    const result = queryParameters.map(parameter => {
      const value =
        this.$store.getters['global/object'](parameter) &&
        this.$store.getters[`global/object`](parameter).id;
      const isRequired = this.filterIsRequired(parameter);
      const message =
        isRequired && !value
          ? `${PRETTY_NAMES[parameter]} is required.`
          : undefined;

      let placeholder, deselectedText, isDanger;
      if (this.$props.type === LIST_FILTER) {
        placeholder = `Filter by ${PRETTY_NAMES[parameter]}`;
        deselectedText = `- No filter -`;
        isDanger = isRequired && !value;
      } else {
        placeholder = `Select ${PRETTY_NAMES[parameter]}`;
        deselectedText = `- Deselect -`;
        isDanger = false;
      }

      if (
        this.$store.getters['global/collection'](parameter).objects.length === 0
      ) {
        placeholder = `No ${PRETTY_NAMES[parameter]}s found`;
      }

      let options = [];

      if (
        this.$store.getters['global/collection'](parameter).objects.length > 0
      ) {
        if (!isRequired) {
          options.push({
            name: deselectedText,
          });
        }

        options = options.concat([
          ...this.$store.getters['global/collection'](parameter).objects,
        ]);
      }

      return {
        key: parameter,
        label: this.$props.showLabels ? PRETTY_NAMES[parameter] : '',
        placeholder,
        value,
        input: this.filterInputFn(parameter),
        isRequired,
        isDanger,
        message,
        options,
      };
    });

    return result;
  }

  mounted() {
    if (this.$props.type === OBJECT_CREATION && this.$props.objectType === '') {
      throw new Error(
        `Prop objectType must be set when type is ${OBJECT_CREATION}`,
      );
    }
  }

  filterIsRequired(filteringBy: string) {
    if (this.$props.objectType === '') {
      return false;
    }
    const ancestors = getAllAncestors(this.$props.objectType);
    return ancestors.some(ancestor => {
      return (
        ancestor.objectType === filteringBy &&
        (ancestor['requiredForFilter'] || ancestor['mainParent'])
      );
    });
  }

  filterInputFn(parameter: string) {
    return id => {
      // disable filter input function when navigation is in progress
      // otherwise the change of state during navigation will trigger
      // the input function a second time
      if (!this.$store.getters['global/navigationIsActive']) {
        const redirect = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            [parameter]: id,
          },
        };

        // clear selected page when changing a filter
        delete redirect.query.page;

        for (const item of getDependencies(parameter)) {
          // delete depending object query parameters
          // e.g. when product changes, model must be cleared
          delete redirect.query[item];
        }

        this.$router.push(redirect);
      }
    };
  }
}
