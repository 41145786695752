








import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class CurrentTime extends Vue {
  time = new Date().toLocaleTimeString();
  interval = 0;

  mounted(): void {
    this.interval = setInterval(() => {
      this.time = new Date().toLocaleTimeString();
    }, 1000);
  }

  destroyed(): void {
    clearInterval(this.interval);
  }
}
