import {
  UpdateRequestEvent,
  UpdateRequestState,
  UpdateRequestEventTrigger,
} from './interfaces';

export enum UpdateSequenceState {
  CONFIRMED = 'confirmed',
  UNCONFIRMED = 'unconfirmed',
  FAILED = 'failed',
}

export function getUpdateState(results: UpdateRequestEvent[]): string {
  // check if up_to_date
  if (results[0].payload.fields.state === UpdateRequestState.UP_TO_DATE) {
    return UpdateSequenceState.CONFIRMED;
  }
  // ignore cleanup
  results = results.filter(result => {
    return (
      result.payload.fields.event_trigger !== UpdateRequestEventTrigger.CLEANUP
    );
  });
  // find first event with trigger 'request'
  const firstRequest = results.findIndex(result => {
    return (
      result.payload.fields.event_trigger === UpdateRequestEventTrigger.REQUEST
    );
  });
  if (firstRequest < 0) {
    return UpdateSequenceState.UNCONFIRMED;
  }
  const firmware_update = results[firstRequest].payload.fields.firmware_update;
  // check if all events belong to same update sequence
  for (let i = 0; i < firstRequest; i++) {
    if (results[i].payload.fields.firmware_update !== firmware_update) {
      return UpdateSequenceState.FAILED;
    }
  }
  // check if confirmed update:
  // 'success' preceeded by 'created'
  if (
    results[0].payload.fields.state === UpdateRequestState.SUCCESS &&
    results[1].payload.fields.state === UpdateRequestState.CREATED
  ) {
    return UpdateSequenceState.CONFIRMED;
  }
  // check if unconfirmed
  else if (
    results[0].payload.fields.state === UpdateRequestState.SUCCESS ||
    results[0].payload.fields.state === UpdateRequestState.CREATED
  ) {
    return UpdateSequenceState.UNCONFIRMED;
  }
  // else failed update
  else {
    return UpdateSequenceState.FAILED;
  }
}
