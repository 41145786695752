








import { Component, Vue, Prop } from 'vue-property-decorator';
import { BackgroundTaskStates } from '@/api/BackgroundTaskHandler';

@Component({})
export default class TaskState extends Vue {
  @Prop({ required: true }) state: BackgroundTaskStates;

  BackgroundTaskStates = BackgroundTaskStates;

  typeMap = {
    [BackgroundTaskStates.DONE_SUCCESS]: 'is-success',
    [BackgroundTaskStates.QUEUED]: 'is-warning',
    [BackgroundTaskStates.RUNNING]: 'is-info',
    [BackgroundTaskStates.FAILED]: 'is-danger',
    [BackgroundTaskStates.DONE_SUCCESS_EXPIRED]: 'is-dark',
  };

  messageMap = {
    [BackgroundTaskStates.DONE_SUCCESS]: 'Success',
    [BackgroundTaskStates.QUEUED]: 'Queued',
    [BackgroundTaskStates.RUNNING]: 'Running',
    [BackgroundTaskStates.FAILED]: 'Failed',
    [BackgroundTaskStates.DONE_SUCCESS_EXPIRED]: 'Expired',
  };
}
