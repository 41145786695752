import { common } from '@/lang/de/common';
import { auth } from '@/lang/de/auth';
import { client } from '@/lang/de/client';
import { admin } from '@/lang/de/admin';
import { device } from '@/lang/de/device';
import { data } from '@/lang/de/data';
import { monitoring } from '@/lang/de/monitoring';
import { study } from '@/lang/de/study';
import { dhStudy } from '@/lang/de/dhStudy';

export const de = {
  common,
  auth,
  client,
  admin,
  device,
  data,
  monitoring,
  study,
  dhStudy,
};
