





















































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { FormBuilderHelper } from './formBuilderHelper';

@Component({
  components: {},
  props: {
    config: {
      required: true,
    },
    form: {
      required: true,
    },
  },
})
export default class FormBuilder extends Vue {
  get formHelper() {
    return new FormBuilderHelper(this.$props.config);
  }

  get fields() {
    return this.formHelper.config.fields;
  }

  get model(): any {
    return this.formHelper.model;
  }

  get formConfig() {
    return this.formHelper.config;
  }
}
