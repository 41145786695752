


















import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';
import { getLocationPreservingState } from '../../routingUtils';

import { dataRouteName } from '../app';

import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'main-menu',
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      // {
      //   iconName: 'mdi-apps',
      //   text: 'Applications',
      //   location: { name: dataRouteName('application-root') },
      // },
      {
        iconName: 'mdi-cellphone-link',
        text: this.$tc('data.deviceRelation.menu'),
        location: getLocationPreservingState(
          dataRouteName('device-relation-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-link',
        text: this.$tc('data.deviceSessionConfig.menu'),
        location: getLocationPreservingState(
          dataRouteName('device-session-config-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-view-stream',
        text: this.$tc('data.stream.prettyName', 2),
        location: getLocationPreservingState(
          dataRouteName('stream-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-logout',
        text: this.$tc('data.output.prettyName', 2),
        location: getLocationPreservingState(
          dataRouteName('output-root'),
          this.$router,
        ),
        isChild: true,
      },
      {
        iconName: 'mdi-table-large',
        text: this.$tc('data.dataSourceTemplate.menu'),
        location: getLocationPreservingState(
          dataRouteName('data-source-template-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-poll',
        text: this.$tc('data.dataExplorer.menu'),
        location: getLocationPreservingState(
          dataRouteName('explorer'),
          this.$router,
        ),
      },
    ];
  }
}
