







































import BaseForm from '@/components/common/BaseForm.vue';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import ChannelDetail from './ChannelDetail.vue';
import { CHANNEL_DEFAULT } from '@/models/firmware/defaults';
import { Channel } from '@/models/firmware/models';
import { LifeCycleState } from '@/models/core/base';
import Vue from 'vue';
import Component from 'vue-class-component';
import { deepCopy } from '@/util/util';

@Component({
  name: 'channel-form',
  components: {
    BaseForm,
    ChannelDetail,
  },
  props: {
    id: {
      required: true,
    },
    templateId: {
      default: '',
    },
  },
  data() {
    return {
      channel: deepCopy(CHANNEL_DEFAULT),
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class ChannelForm extends Vue {
  channel: Channel;
  loading = false;

  get model() {
    return this.$store.getters['global/object']('model');
  }
  get classification() {
    return this.$store.getters['global/object']('classification');
  }

  mounted() {
    this.loading = true;
    let id = this.$props.id;
    if (this.isFromTemplate) {
      id = this.$props.templateId;
    }
    this.$api.get('channel', id).then(response => {
      this.channel = response;
      if (this.isFromTemplate) {
        this.channel.id = '0';
        this.channel.object_state = LifeCycleState.Draft;
      }
      this.loading = false;
    });
  }

  get transformedChannel() {
    this.channel.model = this.model && this.model.id;
    this.channel.classification = this.classification && this.classification.id;

    const transChannel: Channel = deepCopy(this.channel);
    if (transChannel.classification === '0') {
      delete transChannel.classification;
    }
    return transChannel;
  }

  get isNew() {
    return this.channel.id === '0';
  }

  get isFromTemplate() {
    return this.$props.templateId !== '';
  }
}
