
































































































import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';

import BaseForm from '@/components/common/BaseForm.vue';
import UploadForm from '@/components/common/UploadForm.vue';

import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { FIRMWARE_DEFAULT } from '@/models/firmware/defaults';
import { Firmware, FirmwareImageMetaData } from '@/models/firmware/models';
import { LifeCycleState } from '@/models/core/base';
import { deepCopy } from '@/util/util';

@Component({
  name: 'firmware-form',
  components: {
    'base-form': BaseForm,
    UploadForm,
  },
  props: {
    id: {
      required: true,
    },
    templateId: {
      default: '',
    },
  },
  data() {
    return {
      firmware: deepCopy(FIRMWARE_DEFAULT),
      publishDate: new Date(),
      loading: true,
      files: [],
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class FirmwareForm extends Vue {
  $refs: {
    baseForm: BaseForm;
    uploadForm: UploadForm;
  };
  firmware: Firmware;
  loading = true;
  publishDate: Date;
  metadataString = '';
  editorOption: { readOnly: boolean };
  uploadSuccess = false;

  mounted() {
    this.loading = true;
    let id = this.$props.id;
    if (this.isFromTemplate) {
      id = this.$props.templateId;
    }

    this.$api
      .get('firmware', id)
      .then(response => {
        const pubDate = moment(
          new Date(response.publish_configuration.publish_date),
        );
        if (response.publish_configuration.publish_date === null) {
          this.publishDate = null;
        } else {
          this.publishDate = pubDate.toDate();
        }
        this.firmware = response;
        if (this.isFromTemplate) {
          this.firmware.id = '0';
          this.firmware.object_state = LifeCycleState.Draft;
        }
        this.metadataString = JSON.stringify(this.firmware.metadata, null, 2);
        if (this.firmware.id === '0') {
          this.firmware.channel = this.$props.channelId;
        }
        if (!this.isDraft) {
          this.editorOption.readOnly = true;
        }
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        this.loading = false;
      });
  }

  get canSave(): boolean {
    return (
      this.firmware.version.length > 0 && (!this.isNew || this.uploadSuccess)
    );
  }

  get warningMessages(): string[] {
    const messages = [];
    if (this.firmware.version.length === 0) {
      messages.push('Firmware version is required.');
    }
    if (this.isNew && !this.uploadSuccess) {
      messages.push('Firmware image is required.');
    }
    return messages;
  }

  get channel() {
    return this.$store.getters['global/object']('channel');
  }

  get transformedFirmware() {
    const newDate = moment(this.firmware.publish_configuration.publish_date);
    if (this.isNew) {
      this.firmware.publish_date = newDate.toISOString();
    }

    try {
      const metadataJson = JSON.parse(this.metadataString);
      this.firmware.metadata = metadataJson;
      if (this.$refs.baseForm !== undefined) {
        this.$refs.baseForm.formValidation.errorMessages = [];
        this.$refs.baseForm.formValidation.isValid = true;
      }
    } catch (exception) {
      if (this.$refs.baseForm !== undefined) {
        this.$refs.baseForm.formValidation.errorMessages = [exception.message];
        this.$refs.baseForm.formValidation.isValid = false;
      }
    }
    this.firmware.channel = this.channel && this.channel.id;

    if (this.$refs.uploadForm !== undefined) {
      const files = this.$refs.uploadForm.uploadedFiles;
      if (files.length > 0) {
        this.firmware.img_file = files[0].response.id;
      }
    }
    return this.firmware;
  }

  get isFromTemplate() {
    return this.$props.templateId !== '';
  }

  get isNew() {
    return this.firmware.id === '0';
  }

  get isDraft() {
    return this.firmware.object_state === LifeCycleState.Draft;
  }

  get isApproved() {
    return this.firmware.object_state === LifeCycleState.Approved;
  }

  get publishConfigDirty() {
    if (this.firmware.publish_configuration.publish_date === null) {
      if (this.publishDate === null) {
        return false;
      } else {
        return true;
      }
    }
    const pubDate = moment(
      new Date(this.firmware.publish_configuration.publish_date),
    );
    if (this.publishDate === null) {
      if (pubDate === null) {
        return false;
      } else {
        return true;
      }
    }
    return !pubDate.isSame(this.publishDate, 'minute');
  }

  onUploadSuccess() {
    this.uploadSuccess = true;
  }

  savePublishConfig() {
    if (this.publishDate !== null) {
      const newDate = moment(this.publishDate);
      this.firmware.publish_configuration.publish_date = newDate.toDate();
    } else {
      this.firmware.publish_configuration.publish_date = null;
    }

    this.loading = true;
    this.$api
      .customCreate(
        'firmware/' + this.firmware.id + '/update-publish-configuration',
        this.firmware.publish_configuration,
      )
      .then(response => {
        const pubDate = moment(
          new Date(response.data.publish_configuration.publish_date),
        );
        if (response.data.publish_configuration.publish_date === null) {
          this.publishDate = null;
        } else {
          this.publishDate = pubDate.toDate();
        }
        this.loading = false;
      })
      .catch(error => {
        this.$refs.baseForm.handleError(error);
        this.loading = false;
      });
  }

  get dropFileText(): string {
    if (this.firmware.img_file) {
      return 'Firmware image already uploaded. Drop new firmware file to replace. ';
    } else {
      return 'Drop firmware file to upload.';
    }
  }

  get firmwareImageMetaData(): FirmwareImageMetaData | null {
    if (this.firmware.img_file) {
      // TODO: get actual meta data once backend is ready
      return {
        name: '',
        size: '',
        status: 'OK',
      };
    } else {
      return null;
    }
  }
}
