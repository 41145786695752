import { ClientAppEntry, clientAppRouteName } from '../clientAppRegistry';

export const VIEW_ID = 'web-data';

export const CLIENT_APP: ClientAppEntry = {
  name: 'Data',
  path: 'data',
  icon: 'mdi-poll',
  defaultPathName: 'device-relation-root',
};

export function dataRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName);
}
