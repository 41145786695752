import Router, { Route, Location, RawLocation } from 'vue-router';
import { ClientApp } from '@/models/client/models';
import store from '@/store';
import { getAllAncestors } from '@/models/objectRegistry';
import { CollectionFilter } from '@/api/ApiClient';
import { has } from '@/util/util';

export function routeRequiresList(route: Route, list: string): boolean {
  return route.meta.requiresList && route.meta.requiresList[list] === true;
}

export function routeIsDetail(route: Route): boolean {
  return has(route.meta, 'detailOf');
}

export function routeIsDetailOf(route: Route, parameter: string): boolean {
  return route.meta.detailOf === parameter;
}

export function getRouteIsDetailOf(route: Route): string {
  if (routeIsDetail(route)) {
    return route.meta.detailOf;
  } else {
    throw new Error(`Not a detail route: ${route.fullPath}`);
  }
}

/**
 * Query parameters are optional and added to the url via ?product=
 * @param route
 * @param parameter
 */
export function routeHasQuery(route: Route, parameter: string): boolean {
  return route.meta.hasQuery && route.meta.hasQuery[parameter] === true;
}

/**
 * Check whether route has required or optional parameter
 * @param route
 * @param parameter
 */
export function routeHasParam(route: Route, parameter: string): boolean {
  return routeIsDetailOf(route, parameter) || routeHasQuery(route, parameter);
}

export function routeHasPagination(route: Route): boolean {
  return has(route.meta, 'hasPagination');
}

export function routeHasPaginationFor(
  route: Route,
  parameter: string,
): boolean {
  return (
    routeHasPagination(route) && route.meta.hasPagination.objects === parameter
  );
}

export function routePage(route: Route): number {
  return Number.parseInt(route.query.page as string, 10) || 1;
}

export function routePageSize(route: Route): number {
  return (
    Number.parseInt(route.query.page_size as string, 10) ||
    route.meta.hasPagination?.pageSizeDefault
  );
}

export function routeOrderBy(route: Route, parameter: string): string {
  return (
    route.meta.hasOrdering &&
    route.meta.hasOrdering.objects === parameter &&
    (route.query.order_by || route.meta.hasOrdering.orderingDefault)
  );
}

export function routeHasClientApp(route: Route): boolean {
  return Boolean(route.params.app_handle);
}

export function getClientAppOfRoute(route: Route): ClientApp {
  let clientApp;
  try {
    clientApp = store.getters['global/objectByProperty'](
      'client-app',
      'handle',
      route.params.app_handle,
    );
  } catch (error) {
    throw new Error('Could not determine client app of route');
  }
  return clientApp;
}

/**
 * Generate a location object including query parameters.
 * If the store contains an object for any of the route
 * parameters ('meta.hasQuery') it is put into the
 * query string of the generated location object. Like this
 * the current 'context' is propagated via query params
 * to the next location.
 * @param routeName the route name to generate a location for
 * @param router router
 */
export function getLocationPreservingState(
  routeName: string,
  router: Router,
  app_handle?: string,
): RawLocation {
  const query = {};

  // find parameters of target route and add to query if object (e.g. product) is selected in global state
  const { route } = router.resolve({
    name: routeName,
    params: router.currentRoute.params,
  });

  if (route?.meta.hasQuery) {
    for (const parameter in route.meta.hasQuery) {
      const obj = store.getters[`global/object`](parameter);
      if (obj) {
        query[parameter] = obj.id;
      }
    }
  }

  return {
    name: routeName,
    params: {
      org_slug: store.getters['global/object']('organisation').slug,
      app_handle: app_handle
        ? app_handle
        : store.getters['global/object']('client-app').handle,
    },
    query,
  };
}

/* SIMONS CODE START
const contextObjectIdCache = {}
function contextObjectId(objectType: string): Promise<string> {
  if (objectType in contextObjectIdCache) {
    return contextObjectIdCache[objectType]
  }
  if (to.meta.detailForType === objectType) {
    return contextObjectIdCache[objectType] = Promise.resolve(to.params.id)
  } else if (to.query[objectType]) {
    return contextObjectIdCache[objectType] = Promise.resolve(to.query[objectType])
  } else {
    return contextObjectIdCache[objectType] = contextObject(findCurrentChildType(objectType))
      .then(o => o[objectType])
  }
}

function contextObject(objectType: string): Promise<any> {
  return contextObjectId(objectType).then(id => getObjectOrFetch(id))
}

const upstreamIds = {}
upstreamIds[dependencyChain[0]] = getObjectOrFetch(dependencyChain[0], id)
let previousInChain = dependencyChain[0]
for (const objectType of dependencyChain) {
  upstreamIds[objectType] = upstreamIds[previousInChain].then(o => {
    return getObjectOrFetch(o[objectType])
  })
  previousInChain = objectType
}
SIMONS CODE END */

async function lookupUpstreamObjectIds(objectType, ids) {
  const upstreams = getAllAncestors(objectType);
  // console.log(objectType, ':', ...upstreams, ids)
  if (upstreams.length > 0) {
    const currentObj = await store.dispatch('global/getOrFetchObjectById', {
      objectType,
      id: ids[objectType],
    });
    for (const up of upstreams) {
      // find id of upstream in current object
      if (!ids[up.objectType] && currentObj[up.objectType.replace('-', '_')]) {
        ids[up.objectType] = currentObj[up.objectType.replace('-', '_')];
        // console.log(`${up.objectType}: id found via ${objectType}`)
        // recursive call upwards
        await lookupUpstreamObjectIds(up.objectType, ids);
      }
    }
  }
}

/**
 * This function will update the app state by updating the lists of objects
 * and the selection of objects by checking the route meta options and the
 * corresponding parameters, including pagination and ordering. Dependencies
 * of objects are modelled in the CONTEXT_RELATIONS array. if one object
 * depends on another, e.g. the list of models depends on the currently
 * selected product, the update of the current product is 'awaited' before
 * the models list is updated. If there are no dependencies, all objects and
 * lists are updated in parallel.
 * @param to The route we are going to
 * @param objectList The list with object types to process for this route
 */
export async function updateStateForRoute(
  to: Route,
  objectList: string[],
): Promise<void | Location> {
  const organisation = store.getters['global/object']('organisation');

  // generate a list of all given ids of route, be it in params or query
  const ids = {};
  for (const objectType of objectList) {
    if (routeIsDetailOf(to, objectType)) {
      ids[objectType] = to.params.id;
    } else if (routeHasQuery(to, objectType)) {
      ids[objectType] = to.query[objectType];
    }
  }

  // if we are in a detail route, determine ids of upstream objects
  if (routeIsDetail(to)) {
    // console.log(`${to.name} is a detail route. fetching ids upwards.`)
    const startObjectType = getRouteIsDetailOf(to);
    if (
      startObjectType !== 'organisation' &&
      startObjectType !== 'user' &&
      startObjectType !== 'client-app' &&
      objectList.indexOf(startObjectType) > -1
    ) {
      // do not lookup ids for topmost level. e.g. no automatic setting of context for these objectTypes
      await lookupUpstreamObjectIds(startObjectType, ids);
    }
  }

  const upstreamPromises = {};
  let listPromise = null;

  for (const objectType of objectList) {
    upstreamPromises[objectType] = [];

    // update list
    if (routeRequiresList(to, objectType) || routeHasQuery(to, objectType)) {
      const ancestors = getAllAncestors(objectType);

      // ensure all dependent promises are done before continuing
      for (const ancestor of ancestors) {
        // console.time(`${objectType}: awaiting ${ancestor.objectType}`)
        await Promise.all(upstreamPromises[ancestor.objectType]);
        // console.timeEnd(`${objectType}: awaiting ${ancestor.objectType}`)
      }

      let filter: CollectionFilter = {
        organisation: organisation.id,
      };

      // if the id of a up is present, set it as filter
      // (even if it gets ignored by the backend)
      for (const ancestor of ancestors) {
        if (filter && ids[ancestor.objectType]) {
          filter[ancestor.objectType.replace('-', '_')] =
            ids[ancestor.objectType];
        } else if (ancestor.requiredForFilter && !ids[ancestor.objectType]) {
          // the id of a required filter parameter is not given,
          // e.g. model_id not given for classification filter
          // hence set filter to null
          filter = null;
        }
      }

      // determine ordering parameter
      const order_by = routeOrderBy(to, objectType);
      if (filter && order_by) {
        filter.order_by = order_by;
      }

      // determine pagination parameter(s)
      let pagination = {};
      if (routeHasPaginationFor(to, objectType)) {
        pagination = {
          page: routePage(to),
          pageSize: routePageSize(to),
        };
      }

      // update list filter
      listPromise = store.dispatch('global/updateContextFilter', {
        objectType,
        filter,
        pagination,
      });
      upstreamPromises[objectType].push(listPromise);
    }

    // update selection
    if (ids[objectType] || routeHasQuery(to, objectType)) {
      // TODO: implement default selection for filter
      // if (!ids[objectType] && routeHasSelectFirst(to, objectType)) {
      //   await listPromise
      //   const collection = store.getters['global/collection'](objectType)
      //   if (collection.objects.length > 0) {
      //     ids[objectType] = collection.objects[0].id
      //   }
      // }

      // if the object is available in the store (not undefined) then set it
      if (store.getters['global/object'](objectType) !== undefined) {
        // console.time(`${objectType}: select id: ${ids[objectType]}`)
        upstreamPromises[objectType].push(
          store.dispatch('global/selectObjectById', {
            objectType,
            id: ids[objectType],
          }),
        );
        // console.timeEnd(`${objectType}: select id: ${ids[objectType]}`)
      }
    }
  }

  return Promise.resolve();
}
