



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { Token } from '@/models/core/profile';
import { Filter } from '@/api/ApiClientV2';
import { CustomAction } from '@/components/common/interfaces';

@Component({})
export default class TokenList extends Vue {
  @Prop({ required: true }) profile!: string;
  @Prop({ default: 't' }) filterPrefix!: string;

  showRevoked: boolean = this.$route.query.enabled === undefined;
  Token = Token;

  get collectionFilter(): Filter {
    return { profile: this.profile, enabled: this.enabled };
  }

  get enabled(): string | undefined {
    if (this.showRevoked) {
      return undefined;
    } else {
      return 'true';
    }
  }

  get customActions(): CustomAction[] {
    return [
      {
        label: this.$tc('admin.token.revoke'),
        icon: 'mdi-close',
        type: 'is-danger',
        disabled: (token: Token) => {
          return token.enabled === false;
        },
        callback: async (token: Token) => {
          this.confirmRevoke(token);
        },
      },
    ];
  }

  confirmRevoke(token: Token): void {
    this.$buefy.dialog.confirm({
      message: this.$tc('admin.token.confirmRevoke'),
      onConfirm: async () => {
        await this.revoke(token);
      },
    });
  }

  async revoke(token: Token): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      await Token.revoke(token.id);
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    await this.$apiv2.refreshData();
    loading.close();
  }

  async onShowRevoked(value: boolean): Promise<void> {
    await this.$routerHandler.setPage(this.filterPrefix + '~', 1);
    this.showRevoked = value;
  }
}
