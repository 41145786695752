



























































import Vue from 'vue';
import Component from 'vue-class-component';
import { authStore } from '@/store/modules/auth/auth';

@Component({})
export default class Login extends Vue {
  errorMessage = '';
  username = (this.$route.query.username as string) || '';
  password = '';

  mounted() {
    if (authStore.isLoggedIn) {
      this.redirectUser();
    }
  }

  async redirectUser() {
    try {
      let url = '/';
      if (this.$router.currentRoute.query.afterLoginUrl) {
        url = this.$router.currentRoute.query.afterLoginUrl as string;
      }
      this.$router.push({ path: url });
    } catch (error) {
      console.error(error);
    }
  }

  enterKey() {
    this.login();
  }

  async login() {
    try {
      await authStore.logIn({
        username: this.username,
        password: this.password,
      });
      await this.redirectUser();
    } catch (error) {
      this.errorMessage = this.$errorHandler.errorToString(error);
    }
  }
}
