import {
  ClientApp,
  ClientAppSettingKind,
  CLIENT_APP_SETTING_KIND_DEFAULT,
  ClientAppSetting,
  CLIENT_APP_SETTING_DEFAULT,
} from '@/models/client/models';
import { apiClientV2 } from '@/api/ApiClientV2';

async function getSettingKind(clientAppId: string) {
  return await apiClientV2.getOrCreate<ClientAppSettingKind>(
    ClientAppSettingKind,
    {
      ...CLIENT_APP_SETTING_KIND_DEFAULT,
      name: 'default',
      handle: 'default',
      client_app: clientAppId,
    },
    ['client_app', 'handle'],
  );
}

async function postNewSetting(setting: ClientAppSetting) {
  try {
    await ClientApp.getSetting(apiClientV2, setting.client_app, setting.key);
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // only post setting if it does not exist.
      await ClientApp.setSetting(
        apiClientV2,
        setting.client_app,
        setting.key,
        setting.value,
        setting.kind,
      );
    } else {
      throw error;
    }
  }
}

export interface ClientAppConfig {
  default_client_app_settings: { key: string; value: any }[];
}

export async function initClientApp(clientApp: ClientApp): Promise<void> {
  const settingKind = await getSettingKind(clientApp.id);
  let clientAppConfig: ClientAppConfig;
  if (clientApp.view_id === 'web-device') {
    clientAppConfig = await import('@/apps/device/clientAppConfig.json');
  } else if (clientApp.view_id === 'web-data') {
    clientAppConfig = await import('@/apps/data/clientAppConfig.json');
  } else if (clientApp.view_id === 'web-monitoring') {
    clientAppConfig = await import('@/apps/monitoring/clientAppConfig.json');
  } else if (clientApp.view_id === 'web-study') {
    clientAppConfig = await import('@/apps/study/clientAppConfig.json');
  } else if (clientApp.view_id === 'dh-study') {
    clientAppConfig = await import(
      '@/devicehub/apps/dhStudy/clientAppConfig.json'
    );
  }
  if (clientAppConfig?.default_client_app_settings?.length > 0) {
    for (const setting of clientAppConfig?.default_client_app_settings) {
      await postNewSetting({
        ...CLIENT_APP_SETTING_DEFAULT,
        client_app: clientApp.id,
        kind: settingKind.id,
        key: setting.key,
        value: setting.value,
      });
    }
  }
}
