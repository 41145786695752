// until Object.fromEntries() is available ...
export function objectFromMap(map: Map<string, unknown>): {
  [key: string]: unknown;
} {
  const obj = {};
  map.forEach((value, key) => (obj[key] = value));
  return obj;
}

/**
 * Returns a boolean indicating whether the object has the specified property
 * as its own property (as opposed to inheriting it).
 * @param object
 * @param property
 */
export function has(object: any, property: string): boolean {
  return object
    ? Object.prototype.hasOwnProperty.call(object, property)
    : false;
}

/**
 * Inserts an object into another object without breaking reactivity
 * @param target target object
 * @param props object containing properties to insert
 */
export function insertPropsInObject(target: any, props: any): void {
  Object.keys(props).forEach(key => {
    if (target[key]) {
      // Check if its a nested object
      // See here for explanation https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
      if (Object.prototype.toString.call(props[key]) === '[object Object]') {
        insertPropsInObject(target[key], props[key]);
      } else {
        // We can assign it
        target[key] = props[key];
      }
    } else {
      target[key] = props[key];
    }
  });
}

/**
 * Deep copy using JSON parse/stringify
 * @param obj
 */
export function deepCopy<T>(obj: T): T {
  if (obj === undefined || obj === null) {
    return obj;
  }
  return JSON.parse(JSON.stringify(obj));
}

/**
 * Use media query to check if screen size is mobile
 */
export function isMobile(): boolean {
  return window.matchMedia('(max-width: 767px)').matches;
}

/**
 * Set document title (showns in browser tab)
 * @param title
 */
export function setDocumentTitle(title: string): void {
  window.document.title = title;
}

export function capitalizeAfterSeparator(
  str: string,
  wordsToDrop?: string[],
): string {
  const separators = ['-', '_', '/'];
  let newName = '';
  for (let i = 0; i < str.length; i++) {
    if (separators.includes(str[i])) {
      newName += ' ';
    } else {
      if (newName[i - 1] === ' ') {
        newName += str[i].toUpperCase();
      } else {
        newName += str[i];
      }
    }
  }

  const filteredName = (wordsToDrop: string[]) =>
    newName
      .split(' ')
      .filter((word: string) => !wordsToDrop.includes(word.toLocaleLowerCase()))
      .join(' ');

  return filteredName(wordsToDrop || []).replace(
    filteredName(wordsToDrop).charAt(0),
    filteredName(wordsToDrop).charAt(0).toUpperCase(),
  );
}

export type JsonObject = Record<string, any>;

/**
 * Get type of data stored in JSON.
 * @param object
 */
export function getType(
  object: null | undefined | string | number | JsonObject,
): 'null' | 'undefined' | 'string' | 'number' | 'array' | 'object' | 'unknown' {
  const stringConstructor = ''.constructor;
  const arrayConstructor = [].constructor;
  const objectConstructor = {}.constructor;
  if (object === null) {
    return 'null';
  }
  if (object === undefined) {
    return 'undefined';
  }
  if (object.constructor === stringConstructor) {
    return 'string';
  }
  if (object.constructor === arrayConstructor) {
    return 'array';
  }
  if (object.constructor === objectConstructor) {
    return 'object';
  }
  if (typeof object === 'number') {
    return 'number';
  }
  return 'unknown';
}

/**
 * Sort a JSON object alphabetically.
 * @param object
 */
export function sortJsonObject(
  object: null | undefined | string | number | JsonObject,
): null | undefined | string | number | JsonObject {
  if (getType(object) === 'object') {
    const newObject = {};
    for (const key of Object.keys(object).sort()) {
      if (getType(object[key]) === 'array') {
        // array
        newObject[key] = (object[key] as JsonObject[]).map(obj =>
          sortJsonObject(obj),
        );
      } else {
        // nested object or anything else
        newObject[key] = sortJsonObject(object[key]);
      }
    }
    return newObject;
  } else {
    return object;
  }
}

/**
 * Converts an object like {'a.b': 1, d: 3} to {a: {b: 1}, d: 3}
 * @param model
 */
export function parseDotString(model: any): any {
  // TODO: we could also use dotize.backward for this
  const parsedModel = {};
  Object.keys(model).forEach(key => {
    const keys = key.split('.');
    if (keys.length === 1 && key !== '') {
      parsedModel[keys[0]] = model[keys[0]];
    } else {
      if (parsedModel[keys[0]] === undefined) {
        parsedModel[keys[0]] = parseDotString({
          [keys.slice(1, keys.length).join('.')]: model[key],
        });
      } else {
        insertPropsInObject(
          parsedModel[keys[0]],
          parseDotString({
            [keys.slice(1, keys.length).join('.')]: model[key],
          }),
        );
      }
    }
  });
  return parsedModel;
}
