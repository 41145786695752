import { updateStateForRoute } from '../routingUtils';
import { clientAppRegistryGet } from '../clientAppRegistry';
import { VIEW_ID, deviceRouteName } from './app';

import RouterPassThrough from '@/components/common/RouterPassThrough.vue';
import { Location, RouteConfig } from 'vue-router';

import MainMenu from '@/apps/device/components/MainMenu.vue';

import ClassificationForm from '@/apps/device/components/ClassificationForm.vue';
import ClassificationList from '@/apps/device/components/ClassificationList.vue';
import DeviceSettingKindList from '@/apps/device/components/DeviceSettingKindList.vue';
import DeviceSettingKindForm from '@/apps/device/components/DeviceSettingKindForm.vue';
import DeviceDashboard from '@/apps/device/components/DeviceDashboard.vue';
import DeviceDetail from '@/apps/data/components/DeviceDetail.vue';
import DeviceForm from '@/apps/device/components/DeviceForm.vue';
import DeviceList from '@/apps/device/components/DeviceList.vue';
import ModelForm from '@/apps/device/components/ModelForm.vue';
import ModelList from '@/apps/device/components/ModelList.vue';
import ProductForm from '@/apps/device/components/ProductForm.vue';
import ProductList from '@/apps/device/components/ProductList.vue';
import { globalStore } from '@/store/modules/global';
import { ErrorHandler } from '@/api/ErrorHandler';

async function beforeEnterDeviceApp(to): Promise<void | Location> {
  try {
    await globalStore.fetchClientAppSettings();
  } catch (error) {
    ErrorHandler.handleError(error);
  }
  const objectList = [
    'product',
    'model',
    'classification',
    'device',
    'device-setting-kind',
  ];

  return updateStateForRoute(to, objectList);
}

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: deviceRouteName('dashboard') },
  components: {
    'default': RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterDeviceApp,
  },
  children: [
    {
      path: 'dashboard',
      name: deviceRouteName('dashboard'),
      props: true,
      component: DeviceDashboard,
    },
    {
      path: 'product',
      name: deviceRouteName('product-root'),
      redirect: { name: deviceRouteName('product-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: deviceRouteName('product-list'),
          props: true,
          component: ProductList,
          meta: {
            requiresList: {
              product: true,
            },
            hasPagination: {
              objects: 'product',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'product',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: deviceRouteName('product-create'),
          component: ProductForm,
          props: { id: '0' },
        },
        {
          path: ':id',
          name: deviceRouteName('product-detail'),
          props: true,
          component: ProductForm,
          meta: {
            detailOf: 'product',
          },
        },
      ],
    },
    {
      path: 'model',
      name: deviceRouteName('model-root'),
      redirect: { name: deviceRouteName('model-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: deviceRouteName('model-list'),
          props: true,
          component: ModelList,
          meta: {
            requiresList: {
              model: true,
            },
            hasQuery: {
              product: true,
            },
            hasPagination: {
              objects: 'model',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'model',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: deviceRouteName('model-create'),
          component: ModelForm,
          props: { id: '0' },
          meta: {
            requiresList: {
              product: true,
            },
            hasQuery: {
              product: true,
            },
          },
        },
        {
          path: ':id',
          name: deviceRouteName('model-detail'),
          props: true,
          component: ModelForm,
          meta: {
            requiresList: {
              product: true,
            },
            detailOf: 'model',
          },
        },
      ],
    },
    {
      path: 'classification',
      name: deviceRouteName('classification-root'),
      redirect: { name: deviceRouteName('classification-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: deviceRouteName('classification-list'),
          props: true,
          component: ClassificationList,
          meta: {
            requiresList: {
              classification: true,
            },
            hasQuery: {
              product: true,
              model: true,
            },
            hasPagination: {
              objects: 'classification',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'classification',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: deviceRouteName('classification-create'),
          component: ClassificationForm,
          props: { id: '0' },
          meta: {
            hasQuery: {
              product: true,
              model: true,
            },
          },
        },
        {
          path: ':id',
          name: deviceRouteName('classification-detail'),
          props: true,
          component: ClassificationForm,
          meta: {
            detailOf: 'classification',
          },
        },
      ],
    },
    {
      path: 'device-setting-kind',
      name: deviceRouteName('device-setting-kind-root'),
      redirect: { name: deviceRouteName('device-setting-kind-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: deviceRouteName('device-setting-kind-list'),
          props: true,
          component: DeviceSettingKindList,
          meta: {
            requiresList: {
              'device-setting-kind': true,
            },
            hasQuery: {
              product: true,
              model: true,
            },
            hasPagination: {
              objects: 'device-setting-kind',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'device-setting-kind',
              orderingDefault: 'name',
            },
          },
        },
        {
          path: 'create',
          name: deviceRouteName('device-setting-kind-create'),
          component: DeviceSettingKindForm,
          props: { id: '0' },
          meta: {
            hasQuery: {
              product: true,
              model: true,
            },
          },
        },
        {
          path: ':id',
          name: deviceRouteName('device-setting-kind-detail'),
          props: true,
          component: DeviceSettingKindForm,
          meta: {
            detailOf: 'device-setting-kind',
          },
        },
      ],
    },
    {
      path: 'device',
      name: deviceRouteName('device-root'),
      redirect: { name: deviceRouteName('device-list') },
      component: RouterPassThrough,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          name: deviceRouteName('device-list'),
          props: true,
          component: DeviceList,
          meta: {
            requiresList: {
              device: true,
            },
            hasQuery: {
              product: true,
              model: true,
              classification: true,
            },
            hasPagination: {
              objects: 'device',
              pageSizes: [10, 20, 50],
              pageSizeDefault: 10,
            },
            hasOrdering: {
              objects: 'device',
              orderingDefault: 'device_id',
            },
          },
        },
        {
          path: 'create',
          name: deviceRouteName('device-create'),
          component: DeviceForm,
          props: { id: '0' },
          meta: {
            hasQuery: {
              product: true,
              model: true,
              classification: true,
            },
          },
        },
        {
          path: ':id',
          name: deviceRouteName('device-detail'),
          props: true,
          component: DeviceDetail,
          meta: {
            requiresList: {
              'device-setting-kind': true,
            },
            detailOf: 'device',
          },
        },
      ],
    },
  ],
};

export default route;
