



















































import 'codemirror/addon/selection/active-line.js';
import 'codemirror/theme/base16-light.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/yaml/yaml.js';
import yaml from 'js-yaml';
import { StagesMaintainer } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { codemirror, Codemirror } from 'vue-codemirror';
import { deepCopy } from '@/util/util';

@Component({
  name: 'yaml-plugin',
  components: {
    codemirror: codemirror,
  },
  props: {
    stages_json_text: {
      required: true,
    },
    config_buffer: {
      required: true,
    },
  },
  data() {
    return {
      editorTextReceive: this.$props.stages_text,
      editorTextDisplay: this.$props.stages_text,
      stateChanged: false,
      configBuffer: this.$props.config_buffer,
      jsonString: this.$props.stages_json_text,
      errorMessage: null,
      editorOption: {
        tabSize: 2,
        indentWithTabs: false,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        mode: 'text/x-yaml',
        theme: 'base16-light',
        extraKeys: {
          Tab: cm => {
            if (cm.somethingSelected()) {
              cm.indentSelection('add');
            } else {
              cm.replaceSelection(
                cm.getOption('indentWithTabs')
                  ? '\t'
                  : Array(cm.getOption('indentUnit') + 1).join(' '),
                'end',
                '+input',
              );
            }
          },
        },
      },
    };
  },
})
export default class YamlPlugin extends Vue {
  editorTextReceive: string;
  editorTextDisplay: string;
  stateChanged: boolean;
  stagesMaintainer: StagesMaintainer;
  configBuffer: string;
  jsonString: string;
  editorVersion = 1;
  errorMessage: string;

  $refs: {
    myEditor: Codemirror;
  };
  mounted() {
    this.editorTextDisplay = this.editorTextReceive;
    const jsonObject = JSON.parse(this.jsonString);
    this.stagesMaintainer = new StagesMaintainer(
      this.configBuffer,
      jsonObject,
      this.editorVersion,
    );
    this.editorTextDisplay = this.stagesMaintainer.bufferStagesText;
    this.stateChanged = this.stagesMaintainer.hasChanged;
    this.$refs.myEditor.codemirror.setSize(null, 600);
  }
  set codeValue(newValue) {
    this.editorTextDisplay = newValue;
    // Create new stringified JSON buffer and call callback
    const newValueJSON = {
      editor_version: this.editorVersion,
      stages_text: newValue,
    };
    try {
      const newValueJSONStringified = JSON.stringify(newValueJSON);
      const updatedJSONObject = deepCopy(yaml.load(newValue));
      this.errorMessage = null;
      this.$emit('editorTextChanged', newValueJSONStringified);
      this.$emit('updateJSONObject', updatedJSONObject);
    } catch (error) {
      console.log(error);
      this.errorMessage = error;
    }
  }
  get codeValue() {
    return this.editorTextDisplay;
  }

  fetchUpdatedText() {
    this.stagesMaintainer.updateBuffer();
    this.stateChanged = false;
    this.editorTextDisplay = this.stagesMaintainer.bufferStagesText;
  }
}
