import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TransientBaseObject } from '@/models/core/base';
import { deepCopy } from '@/util/util';
import {
  ModelClass,
  Filter,
  Pagination,
  Annotation,
  RelatedAnnotation,
  constructGetUrl,
  Context,
  ListResponse,
  constructListUrl,
  constructUrl,
  HookOptions,
  apiClientV2,
  constructUpdateUrl,
  constructCreateUrl,
  constructDeleteUrl,
} from './ApiClientV2';
import { ApiListSubscription } from './ApiListSubscription';

export class Collection<T, K> {
  modelClass: ModelClass;
  subscriptionsMap = new Map<string, ApiListSubscription<K>>();

  /**
   * Create new Collection.
   * @param {TransientBaseObject} modelClass
   */
  constructor(modelClass: ModelClass) {
    // Check if modelClass has defined all needed properties
    if (modelClass.apiUrl === undefined) {
      throw new Error(
        `ModelClass ${modelClass.objectType} needs to define property "apiUrl".`,
      );
    }
    if (modelClass.defaultModel === undefined) {
      throw new Error(
        `ModelClass ${modelClass.objectType} needs to define "defaultModel".`,
      );
    }
    this.modelClass = modelClass;
  }

  /**
   * Create new ApiListSubscription
   */
  subscribe(
    config: AxiosRequestConfig,
    modelClass: ModelClass,
    filter: Filter | null,
    pagination: Pagination,
    annotations?: Annotation<TransientBaseObject>[],
    joins?: RelatedAnnotation<TransientBaseObject>[],
    refreshPeriod?: number,
  ): ApiListSubscription<K> {
    const apiListSubscription = new ApiListSubscription(
      config,
      modelClass,
      filter,
      pagination,
      annotations,
      joins,
      refreshPeriod,
    );
    this.subscriptionsMap.set(apiListSubscription.key, apiListSubscription);
    return apiListSubscription;
  }

  public get<T>(id: string, config: AxiosRequestConfig): Promise<T> {
    const url = constructGetUrl(this.modelClass, id);
    return axios
      .get(url, config)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  public getList<T>(
    context: Context,
    config: AxiosRequestConfig,
  ): Promise<ListResponse<T>> {
    if (context.filter === null) {
      return Promise.resolve({
        size: 0,
        page: 1,
        page_size: 1,
        results: [],
      });
    }
    const url = constructListUrl(constructUrl(this.modelClass), context);
    return axios
      .get(url, config)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  /**
   * Create new object and refresh subscribers
   * @param data
   * @param config for axios request
   * @returns {Promise<never | any[]>}
   */
  public async create(
    data: ModelClass,
    config: AxiosRequestConfig,
    isUpdate = false,
    hookOptions: HookOptions = {
      beforeSaveOptions: undefined,
      afterSaveOptions: undefined,
    },
  ): Promise<AxiosResponse<K>> {
    const axiosConfig: AxiosRequestConfig = deepCopy(config);
    let response: AxiosResponse<K>;
    if (this.modelClass.beforeSaveHook) {
      await this.modelClass.beforeSaveHook(
        apiClientV2,
        data,
        hookOptions.beforeSaveOptions,
      );
    }
    if (isUpdate) {
      response = await axios.patch(
        constructUpdateUrl(this.modelClass, data.id, data),
        data,
        config,
      );
    } else {
      delete data.id;
      response = await axios.post(
        constructCreateUrl(this.modelClass, data),
        data,
        axiosConfig,
      );
      // store id of created object in data, such that it is available in the form component
      if ((response.data as any).id) {
        data.id = (response.data as any).id;
      }
    }
    if (this.modelClass.afterSaveHook) {
      await this.modelClass.afterSaveHook(
        apiClientV2,
        data,
        hookOptions.afterSaveOptions,
        response.data,
      );
    }
    await this.refresh();
    return response;
  }

  /**
   * Update existing object and refresh subscribers
   */
  public async update(
    data: ModelClass,
    config: AxiosRequestConfig,
    hookOptions?: HookOptions,
  ): Promise<AxiosResponse<K>> {
    return this.create(data, config, true, hookOptions);
  }

  public delete(
    id: string,
    purge = false,
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse<any>> {
    const url = constructDeleteUrl(this.modelClass, id, purge);
    return axios
      .delete(url, config)
      .then(response => {
        this.refresh();
        return response;
      })
      .catch(error => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  async refresh(): Promise<void> {
    this.subscriptionsMap.forEach(apiListSubscription => {
      apiListSubscription.refresh();
    });
  }
}
