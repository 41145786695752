










import { Component, Vue } from 'vue-property-decorator';
import { Firmware } from '@/models/firmware/models';

@Component({})
export default class FirmwareList extends Vue {
  Firmware = Firmware;
  showDeleted: boolean = this.$route.query.object_state !== undefined;
  firmwareFilter: {
    object_state?: string;
    order_by_dsc: string;
  } = { order_by_dsc: 'version' };

  switchShowDeleted() {
    if (this.showDeleted) {
      this.firmwareFilter = {
        object_state: '',
        order_by_dsc: 'version',
      };
    } else {
      this.$routerHandler.removeFromQuery('', 'object_state');
      this.firmwareFilter = { order_by_dsc: 'version' };
    }
  }
}
