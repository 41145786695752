



































































































































































import store from '@/store/index';
import Vue from 'vue';
import { Watch, Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import SearchField from '@/components/common/lists/SearchField.vue';
import { copyTextToClipboard } from '@/util/clipboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import AutoRefresh from '@/apps/device/components/AutoRefresh.vue';
import { deviceEventTypes } from '@/apps/device/components/device/deviceEventTypes';
import { DeviceEventLog } from '@/models/device/models';
import { DeviceEventType, DeviceEvent } from '@/models/device/interfaces';
import { deepCopy } from '@/util/util';

@Component({
  components: {
    ...deviceEventTypes,
    SearchField,
    AutoRefresh,
  },
})
export default class DeviceHistoryList extends Vue {
  @Prop({ required: true }) device: string;

  sortField = 'version';
  sortOrder = 'desc';
  defaultSortOrder = 'desc';
  pagination = { page: 1, pageSize: 10 };
  startDate = null;
  endDate = null;
  results = [];
  identity = '';
  loading = true;
  eventType = '';
  eventTypeList: string[] = Object.values(DeviceEventType);
  openedDetailed = [];
  destroySubject = new Subject<void>();

  @Watch('$route.query')
  onRouteChanged() {
    this.getEventResults();
  }

  get hasPagination() {
    return this.total > this.pagination.pageSize ? true : false;
  }

  get total() {
    if (this.results.length === this.pagination.pageSize) {
      return (this.pagination.page + 1) * this.results.length;
    } else {
      return (
        (this.pagination.page - 1) * this.pagination.pageSize +
        this.results.length
      );
    }
  }

  mounted() {
    this.getEventResults();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.getEventResults();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  onPageSizeChange(pageSize) {
    this.closeAll();
    this.pagination.pageSize = pageSize;
    this.getEventResults();
  }

  onPageChange(page) {
    this.closeAll();
    this.pagination.page = page;
    this.getEventResults();
  }

  onStart(date: Date) {
    this.closeAll();
    this.startDate = date;
    this.pagination.page = 1;
    this.getEventResults();
  }

  onEnd(date: Date) {
    this.closeAll();
    this.endDate = date;
    this.pagination.page = 1;
    this.getEventResults();
  }

  onEventTypeChange(eventType) {
    this.$routerHandler.updateQuery('history~', { 'event-type': eventType });
  }

  async getEventResults(showLoading = true) {
    if (showLoading) this.loading = true;
    this.eventType = (this.$route.query['history~event-type'] as string) || '';
    const query = {
      device: this.$props.device,
      page: this.pagination.page,
      page_size: this.pagination.pageSize,
      event_type: this.eventType,
      identity: (this.$route.query['h~identity'] as string) || '',
      start_time: this.startDate ? this.startDate.toISOString() : '',
      end_time: this.endDate ? this.endDate.toISOString() : '',
    };
    try {
      const events = await DeviceEventLog.queryEvents<
        DeviceEvent & { index: number }
      >(query);
      events.results.forEach((r, index) => {
        r.index = index;
      });
      this.results = events.results;
    } catch (error) {
      this.$errorHandler.handleError(error);
      this.results = [];
    }
    this.loading = false;
  }

  openAll() {
    this.openedDetailed = [];
    for (let i = 0; i < this.results.length; i++) {
      this.openedDetailed.push(i);
    }
  }

  closeAll() {
    this.openedDetailed = [];
  }

  copyEventData(row) {
    const copy = deepCopy(row);
    delete copy.index;
    copyTextToClipboard(JSON.stringify(copy, null, 2));
    this.$buefy.toast.open({
      message: this.$tc('common.copiedEvent'),
      type: 'is-success',
    });
  }
}
