









import Vue from 'vue';
import Component from 'vue-class-component';
import { Classification } from '@/models/device/models';

@Component({})
export default class ClassificationList extends Vue {
  Classification = Classification;
}
