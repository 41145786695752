import { ClientAppEntry, clientAppRouteName } from '../clientAppRegistry';

export const VIEW_ID = 'web-study';

export const CLIENT_APP: ClientAppEntry = {
  name: 'Study',
  path: 'sty',
  icon: 'mdi-account-multiple-plus',
  defaultPathName: 'participant-root',
};

export function studyRouteName(routeName: string): string {
  return clientAppRouteName(VIEW_ID, routeName);
}
