



















































































import { Token } from '@/models/core/profile';
import { Device } from '@/models/device/models';
import { Participant } from '@/models/study/models';
import { globalStore } from '@/store/modules/global';
import { copyTextToClipboard } from '@/util/clipboard';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TileLinkMetaData } from '../interfaces';
import QrcodeVue from 'qrcode.vue';

@Component({ components: { QrcodeVue } })
export default class CreateTileLink extends Vue {
  @Prop({ default: '' }) device!: string;
  @Prop({ default: '' }) deviceRelation!: string;
  @Prop({ default: null }) participant!: Participant | null;
  @Prop({ required: true }) options!: {
    profile?: string;
    monitoringClientApp?: string;
  };

  name = '';
  currentLink = '';
  currentLinkMetaData: TileLinkMetaData | null = null;

  get showLinkModal(): boolean {
    return !!this.currentLink;
  }

  get hasCurrentLinkWithToken(): boolean {
    return !!this.currentLinkMetaData?.tokenId;
  }

  get formattedCreatedDate(): string {
    if (this.currentLinkMetaData?.created) {
      return new Date(this.currentLinkMetaData?.created).toLocaleString();
    } else {
      return 'N/A';
    }
  }

  async mounted(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    if (this.participant) {
      this.currentLinkMetaData =
        this.participant.meta?.currentLinkMetaData ?? null;
    } else {
      await this.getCurrentLinkMetaFromSetting();
    }
  }

  async getCurrentLinkMetaFromSetting(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const setting = await Device.getSetting(
        this.$apiv2,
        this.device,
        'monitoring_tile_link',
      );
      this.currentLinkMetaData = setting.value;
    } catch (error) {
      this.currentLinkMetaData = null;
      if (error.response && error.response.status === 404) {
        // ignore that device setting does not exist
      } else {
        this.$errorHandler.handleError(error);
      }
    }
    loading.close();
  }

  async createLink(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      const clientAppId =
        this.options?.monitoringClientApp ??
        globalStore.selection?.clientApp?.id;
      if (clientAppId === undefined) {
        throw new Error('Client app not configured');
      }
      const token = await this.generateToken();
      if (this.participant) {
        this.currentLink = `${window.location.protocol}//${window.location.host}/#/monitoring?client_app=${clientAppId}&participant=${this.participant?.id}&token=${token}`;
      } else {
        this.currentLink = `${window.location.protocol}//${window.location.host}/#/monitoring?client_app=${clientAppId}&device_relations=${this.deviceRelation}&token=${token}`;
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async generateToken(): Promise<string> {
    if (this.options?.profile === undefined) {
      throw new Error('Profile not configured');
    }
    const token = await this.$apiv2.create<Partial<Token>, Token>(Token, {
      label: `${this.name}__${this.device ?? this.participant?.pid}`,
      profile: this.options.profile,
    });

    if (token.key === undefined) {
      throw new Error('There was an error while creating a token.');
    }

    const currentLinkMetaData: TileLinkMetaData = {
      tokenId: token.id,
      name: this.name,
      created: new Date().toISOString(),
    };

    await this.saveCurrentLinkMeta(currentLinkMetaData);

    return token.key;
  }

  async saveCurrentLinkMeta(
    currentLinkMetaData: TileLinkMetaData,
  ): Promise<void> {
    if (this.participant) {
      if (!this.participant.meta) {
        this.participant.meta = {};
      }
      this.participant.meta.currentLinkMetaData = currentLinkMetaData;
      await this.$apiv2.update(Participant, this.participant);
    } else {
      await Device.setSetting(
        this.$apiv2,
        this.device,
        'monitoring_tile_link',
        currentLinkMetaData,
      );
    }
  }

  confirmRevoke(): void {
    this.$buefy.dialog.confirm({
      message: this.$tc('monitoring.createTileLink.confirmRevoke'),
      onConfirm: async () => {
        await this.revokeToken();
      },
    });
  }

  async revokeToken(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      if (!this.currentLinkMetaData?.tokenId) {
        throw new Error('No token registered for current tile');
      }
      await Token.revoke(this.currentLinkMetaData.tokenId);
      await this.removeCurrentLinkMeta();
      await this.init();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async removeCurrentLinkMeta(): Promise<void> {
    if (this.participant) {
      if (!this.participant.meta) {
        this.participant.meta = {};
      }
      this.participant.meta.currentLinkMetaData = {};
      await this.$apiv2.update(Participant, this.participant);
    } else {
      await Device.deleteSetting(
        this.$apiv2,
        this.device,
        'monitoring_tile_link',
      );
    }
  }

  async onCloseModal(): Promise<void> {
    this.currentLink = '';
    await this.init();
  }

  async copyToClipboard(): Promise<void> {
    const result = await copyTextToClipboard(this.currentLink);
    if (result) {
      this.$buefy.toast.open({
        message: this.$tc('common.copySuccess'),
        type: 'is-success',
      });
    } else {
      this.$buefy.toast.open({
        message: this.$tc('common.copyError'),
        type: 'is-danger',
      });
    }
  }
}
