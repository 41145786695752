









































































import { DeviceState } from '@/models/device/models';
import Vue from 'vue';
import Component from 'vue-class-component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  name: 'device-state',
  props: {
    device: {
      required: true,
    },
    appId: {
      required: true,
    },
  },
})
export default class DeviceStateView extends Vue {
  state: DeviceState = null;
  destroySubject = new Subject<void>();

  async mounted() {
    await this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async load() {
    try {
      const response = await this.$apiv2.find<DeviceState>(DeviceState, {
        device: this.$props.device,
        application: this.$props.appId,
      });
      if (response.data) {
        this.state = response;
      }
    } catch (error) {
      // Device state does not exist
    }
  }

  dateFormat(date: string): string {
    if (date) {
      return new Date(date).toLocaleString();
    } else {
      return 'n/a';
    }
  }
}
