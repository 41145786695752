<template>
  <router-view name="nav-bar" />
</template>

<script>
export default {
  name: 'RouterPassThroughNavBar',
};
</script>

<style scoped></style>
