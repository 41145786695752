






































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class SearchField extends Vue {
  @Prop({ required: true }) label: string;
  @Prop({ required: true }) searchQuery: string;
  @Prop({ default: '' }) placeholder: string;
  @Prop({ default: 'magnify' }) icon: string;
  @Prop({ default: '' }) prefix: string;
  @Prop({ default: 'string' }) type:
    | 'string'
    | 'number'
    | 'date'
    | 'select'
    | 'custom';
  @Prop({ default: () => [] }) options: { value: string; name: string }[];
  @Prop({ default: 'iso-8601' }) dateFormat: 'iso-8601';
  @Prop({ default: 1 }) numberStep: number;

  searchTerm: string | Date = null;

  @Watch('$route.query')
  onRouteChanged() {
    let value: string | Date = this.$routerHandler.query(this.prefix)[
      this.searchQuery
    ];
    if (this.type === 'date') {
      if (value) {
        switch (this.dateFormat) {
          case 'iso-8601':
            value = new Date(value);
        }
      } else {
        value = null;
      }
    } else if (this.type === 'number') {
      if (typeof value === 'string' && value) {
        value = parseFloat(value).toString();
      } else {
        value = null;
      }
    }
    this.searchTerm = value;
  }

  mounted() {
    this.onRouteChanged();
  }

  get placeholderField() {
    if (this.placeholder) {
      return this.placeholder;
    } else {
      if (this.type === 'select') {
        return `${this.$tc('common.filterBy')} ${this.label}`;
      } else {
        return `${this.$tc('common.searchBy')} ${this.label}`;
      }
    }
  }

  clear() {
    this.searchTerm = null;
    this.performSearch();
  }

  setSearchTermAndPerformSearch(value) {
    this.searchTerm = value;
    this.performSearch();
  }

  performSearch() {
    let value: string = null;
    if (this.searchTerm) {
      switch (this.type) {
        case 'date':
          switch (this.dateFormat) {
            case 'iso-8601':
              value = moment(this.searchTerm).format('YYYY-MM-DD');
              break;
          }
          break;
        case 'number':
          value =
            typeof this.searchTerm === 'string' &&
            parseFloat(this.searchTerm).toString();
          break;
        default:
          value = this.searchTerm.toString();
      }
    }
    if (value) {
      this.$routerHandler.updateQuery(this.prefix, {
        [this.searchQuery]: value,
        page: '1',
      });
    } else {
      this.$routerHandler.removeFromQuery(this.prefix, this.searchQuery);
    }
  }
}
