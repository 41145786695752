export const study = {
  participant: {
    prettyName: 'Participant | Participants',
    fields: {
      pid: 'PID',
      state: 'State',
      meta: 'Meta',
      last_data: 'Last Data',
    },
  },
  deviceassignment: {
    prettyName: 'Device Assignment | Device Assignments',
    fields: {
      patient: 'Participant',
      patient_pid: 'PID',
      device_relation: 'Device relation',
      device_id: 'Device ID',
      device_name: 'Device name',
      role: 'Role',
      effective_time: 'Start time',
    },
    deleteExisting: 'Delete existing device assignment',
    confirmDelete: 'Are you sure that you want to unassign this device?',
  },
  dataassignment: {
    prettyName: 'Data Assignment | Data Assignments',
    fields: {
      assignment_unassignment: 'Assignment/Unassignment',
      add_data: 'Add data',
      remove_data: 'Remove data',
      role: 'Role',
      start_date: 'Start date',
      end_date: 'End date',
      device: 'Device',
    },
    confirmUnassign:
      'Are you sure that you want to unassign data from this participant?',
    noDataAssigned:
      'There is no data assigned from this device to this participant within the selected time range.',
    alreadyDataAssigned:
      'There is already some data assigned from this device to participant {pid} within this time range. This data will be unassigned before assigning new data. Would you like to continue?',
    alreadyDataRoleAssigned:
      'There is already some data assigned from device "{device_name}" ({device_id}) with role "{role}" to this participant within this time range. Cannot assign data twice.',
    previousRemoved: 'Previous data assignment has been removed',
    updateSuccessful: 'Data assignment update successful',
  },
  datalabel: {
    prettyName: 'Data Label | Data Labels',
    fields: {
      application: 'Application',
      type: 'Type',
      patient: 'Participant',
      device: 'Device',
      state: 'State',
      start_time: 'Start Time',
      end_time: 'End Time',
      meta: 'Meta',
    },
  },
  state: {
    current: 'Current state',
    next: 'Next state',
  },
};
