import { dhStudy as dhStudyEn } from '@/lang/en/dhStudy';

export const dhStudy: typeof dhStudyEn = {
  study: 'Studie | Studien',
  participant: {
    this: 'Participant | Participants',
    participant_id: 'PID',
    state: 'Status | Status',
    active: 'Aktiv',
    inactive: 'Inaktiv',
    lastData: 'Letzte Daten',
    copyPID: 'PID kopieren',
  },
  // TODO: translate
  deviceAssignment: {
    this: 'Device Assignment | Device Assignments',
    device_name: 'Device Name',
    device_id: 'Device ID',
    start_time: 'Start',
    end_time: 'Ende',
    swapDevice: 'Device wechseln',
    confirmDelete:
      'Sind Sie sicher, dass Sie dieses Device Assignment löschen wollen?',
    confirmEndNow:
      'Sind Sie sicher, dass Sie dieses Device Assignment jetzt beenden wollen?',
  },
  device: {
    this: 'Device | Devices',
    device_name: 'Device Name',
    device_id: 'Device ID',
    model: 'Modell',
    assigned_pid: 'Assigned PID',
    lastData: 'Letzte Daten',
    battery: 'Batterie',
    copyUUID: 'Device UUID kopieren',
  },
  dataExport: {
    this: 'Data Export',
    deviceUuid: 'Device UUID',
    startTime: 'Start Time',
    endTime: 'End Time',
    streamId: 'Stream ID',
    startExport: 'Start Export',
    download: 'Download',
    exportInProgress: 'Export in progress',
    exportReady: 'Export ready',
    page: 'Page',
  },
  sourceDevice: {
    this: 'Source Device | Source Devices',
  },
  availableDevice: {
    this: 'Available Device | Available Devices',
  },
  endNow: 'Jetzt beenden',
  data: 'Data',
};
