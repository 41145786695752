




























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { has } from '@/util/util';

@Component({
  name: 'dependency-tree-item',
  props: {
    item: {
      required: true,
    },
    role: {
      required: true,
    },
    propagate: {
      default: false,
    },
  },
  filters: {
    formatPermission(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      value = value.split('_').join(' ');
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
})
export default class DependencyTreeItem extends Vue {
  propagate: boolean;

  onSwitch(contentType, permissionName, value, autoTrigger) {
    if (this.propagate) {
      const dependencies = this.$props.item.dependencies;
      if (dependencies) {
        dependencies.forEach(cType => {
          if (has(this.$props.role.permissions[cType], permissionName)) {
            this.$props.role.permissions[cType][permissionName] =
              this.$props.role.permissions[contentType][permissionName];
          }
        });
      }
    }
  }
}
