import axios, { AxiosRequestConfig } from 'axios';
import stringify from 'json-stable-stringify';
import {
  Observable,
  Subscription,
  timer,
  BehaviorSubject,
  combineLatest,
  from,
  Subject,
  merge,
} from 'rxjs';
import { map, mergeMap, share, switchMap } from 'rxjs/operators';
import { DataStateDisplay } from '@/util/DataStateDisplay';
import { insertPropsInObject } from '@/util/util';
import { TransientBaseObject } from '@/models/core/base';
import { getModelClass } from '@/models/objectRegistry';
import { Dictionary, isEmpty, isEqual } from 'lodash';
import {
  ModelClass,
  Status,
  Info,
  Annotation,
  RelatedAnnotation,
  Filter,
  Pagination,
  Context,
  ListResponse,
  constructUrl,
  AnnotationCallback,
  apiClientV2,
  AnnotationResponse,
  constructListUrl,
} from './ApiClientV2';

export class ApiListSubscription<T> {
  axiosConfig: AxiosRequestConfig;
  modelClass: ModelClass;
  status: Status;
  periodicRefreshObservable: Observable<any>;
  subscription: Subscription;
  key: string;
  info: Info;
  annotations?: Annotation<TransientBaseObject>[] | null;
  joins?: RelatedAnnotation<TransientBaseObject>[] | null;
  filter: Filter | null;
  pagination: Pagination | null;
  url: string;
  objects: (TransientBaseObject & any)[] = [];

  contextSubject: BehaviorSubject<Context>;
  contextObservable: Observable<Context>;
  refreshObservable: Observable<ListResponse>;

  constructor(
    axiosConfig: AxiosRequestConfig,
    modelClass: ModelClass,
    filter: Filter | null = null,
    pagination: Pagination | null = null,
    annotations: Annotation<TransientBaseObject>[] | null = null,
    joins: RelatedAnnotation<TransientBaseObject>[] | null = null,
    refreshPeriod?: number,
  ) {
    this.axiosConfig = axiosConfig;
    this.modelClass = modelClass;

    this.status = {
      fetching: false,
      ready: false,
      readyPromise: Promise.resolve(true),
    };
    this.filter = filter;
    this.pagination = pagination;
    this.info = {};
    this.url = constructUrl(modelClass);
    this.key = stringify({
      filter,
      pagination,
      joins,
    });
    // passed annotations & joins have higher importance than modelClass things
    this.annotations = annotations || modelClass.annotations;
    this.joins = joins || modelClass.joins;

    // Setup input streams
    if (refreshPeriod) {
      // Periodic refresh stream: Periodic refresh stream
      this.periodicRefreshObservable = timer(0, refreshPeriod).pipe(
        map(() => this),
      );
    } else {
      this.periodicRefreshObservable = from([null]);
    }

    const context: Context = {
      filter: this.filter,
      pagination: this.pagination,
    };
    this.contextSubject = new BehaviorSubject(context);
    this.contextObservable = this.contextSubject.asObservable();
    this.refreshObservable = combineLatest([
      this.contextObservable,
      this.periodicRefreshObservable,
    ]).pipe(
      map(([context, refresh]) => {
        return context;
      }),
      switchMap((context: Context) => {
        return from(
          this.getList(context).catch(err => {
            // catch error here to not break rxjs flow
            console.log(err);
            return {
              size: 0,
              page: 1,
              page_size: 1,
              results: [],
            };
          }),
        );
      }),
      share(),
    );

    // RefreshObservable emits the list of objects from the backend
    // * every time the context (filter or pagination) changes or
    // * when the periodicRefreshObservable (a timer) emits
    // This list is then already passed to the components via this.objects
    // Annotations and joins are filled asynchronously below
    this.subscription = this.refreshObservable.subscribe(
      (listResponse: ListResponse) => {
        this.info.size = listResponse.size;
        // For join data that add new properties to object, we need to add the properties here already so that they are reactive later
        this.joins?.forEach(join => {
          const relatedObjectKey =
            join.relatedModelClass.relatedObjectKey ??
            join.relatedModelClass.objectType.replace('-', '_');
          const relatedObjectType = join.relatedObjectType ?? relatedObjectKey;
          const newProperty = `${relatedObjectType}_${join.relatedObjectProperty}`;
          listResponse.results.forEach(object => {
            object[newProperty] = null;
          });
        });
        this.annotations?.forEach(annotation => {
          listResponse.results.forEach(object => {
            object[annotation.key] = null;
          });
        });
        this.objects.splice(0, this.objects.length, ...listResponse.results);
      },
    );

    // Get annotations by calling the callback function for each object and each annotation
    // Then emitting all annotation responses as a stream
    const annotater = this.refreshObservable.pipe(
      mergeMap(response => {
        const callbacksToInvoke: { cb: AnnotationCallback; obj: any }[] = [];
        response.results.forEach(object => {
          this.annotations?.forEach(annotation => {
            callbacksToInvoke.push({ cb: annotation.callback, obj: object });
          });
        });
        return from(callbacksToInvoke);
      }),
      mergeMap(callback => {
        return from(callback.cb(callback.obj, apiClientV2));
      }, 10),
    );

    // Get joins (related annotations) by getting the related object and extracting the related property
    // for each object and each join
    // Then emitting all annotation responses as a stream
    const joiner = this.refreshObservable.pipe(
      mergeMap(response => {
        const cache: {
          [objectType: string]: { [id: string]: Promise<any> };
        } = {};
        const annotationSubject = new Subject<AnnotationResponse>();
        response.results.forEach(
          (object: TransientBaseObject & Dictionary<string>) => {
            this.joins?.forEach(join => {
              // TODO properly fix underscore vs dash in object type
              const relatedObjectKey =
                join.relatedModelClass.relatedObjectKey ??
                join.relatedModelClass.objectType.replace('-', '_');
              const relatedObjectType: string =
                join.relatedObjectType ?? relatedObjectKey;
              let relatedModelClass: ModelClass;
              if (join.relatedModelClass) {
                relatedModelClass = join.relatedModelClass;
              } else if (join.relatedObjectType) {
                relatedModelClass = getModelClass(join.relatedObjectType);
              }
              const newProperty = `${relatedObjectType}_${join.relatedObjectProperty}`;
              const annotations: AnnotationResponse = {
                id: object.id,
                annotations: {
                  [newProperty]: null,
                },
              };
              if (object[relatedObjectType]) {
                if (
                  cache[relatedModelClass.objectType]?.[
                    object[relatedObjectType]
                  ]
                ) {
                  // use cache
                  cache[relatedModelClass.objectType][
                    object[relatedObjectType]
                  ].then(getResponse => {
                    annotations.annotations[newProperty] =
                      getResponse[join.relatedObjectProperty];
                    annotationSubject.next(annotations);
                  });
                } else {
                  if (!cache[relatedModelClass.objectType]) {
                    cache[relatedModelClass.objectType] = {};
                  }
                  // get from backend and store promise in cache
                  cache[relatedModelClass.objectType][
                    object[relatedObjectType]
                  ] = apiClientV2
                    .get(relatedModelClass, object[relatedObjectType])
                    .then(getResponse => {
                      annotations.annotations[newProperty] =
                        getResponse[join.relatedObjectProperty];
                      annotationSubject.next(annotations);
                      return getResponse;
                    });
                }
              } else if (object[relatedObjectType] === null) {
                annotations.annotations[newProperty] =
                  DataStateDisplay.OkNoData;
                annotationSubject.next(annotations);
              } else {
                throw new Error(
                  `${relatedObjectType} does not exist on object.`,
                );
              }
            });
          },
        );
        return annotationSubject;
      }),
    );

    // Combine joins and annotations and insert into objects
    const annotaterSubscription = merge(joiner, annotater).subscribe(
      annotation => {
        annotateObject(this.objects, annotation);
      },
    );
    this.subscription.add(annotaterSubscription);

    function annotateObject(
      objects: TransientBaseObject[],
      annotation: AnnotationResponse,
    ) {
      if (annotation.id) {
        const index = objects.findIndex(object => {
          return object.id === annotation.id;
        });
        if (index >= 0) {
          insertPropsInObject(objects[index], { ...annotation.annotations });
        }
      }
    }
  }

  /**
   * Disposes the subscription
   */
  dispose(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Set collection filter. Replaces all filter properties.
   * @param {CollectionFilter} filter: When null, the collection is emptied.
   */
  public async setFilter(
    filter: Filter | null,
    noRefresh = false,
  ): Promise<void> {
    if (isEmpty(filter)) {
      filter = null;
    }
    this.filter = filter;
    if (!noRefresh) {
      await this.refresh();
    }
  }

  /**
   * Refreshes collection.
   * @returns {Promise<boolean>}
   */
  public refresh(): Promise<boolean> {
    this.contextSubject.next({
      filter: this.filter,
      pagination: this.pagination,
    });
    return Promise.resolve(true);
  }

  private getList(context: Context): Promise<ListResponse> {
    // TODO: Check if all required filters and pagination is given
    // If filter value is 'null', we leave the collection empty.
    if (context.filter === null) {
      return Promise.resolve({
        size: 0,
        page: 1,
        page_size: 1,
        results: [],
      });
    }

    this.status.fetching = true;
    return axios
      .get(constructListUrl(this.url, context), this.axiosConfig)
      .then(response => {
        this.status.fetching = false;
        return {
          ...response.data,
        };
      })
      .catch(error => {
        this.status.fetching = false;
        return Promise.reject(error);
      });
  }

  /**
   * Set collection pagination.
   * @param {number} page
   * @param {number} pageSize
   * @returns {void}
   */
  setPagination(page?: number, pageSize?: number): Promise<boolean> {
    if (this.pagination === null) {
      this.pagination = {};
    }
    if (page) {
      this.pagination.page = page;
    }
    if (pageSize) {
      this.pagination.pageSize = pageSize;
    }
    return this.refresh();
  }

  /**
   * Set collection order.
   * @param {string} field
   * @param {string} order
   * @returns {Promise<boolean>}
   */
  setOrder(field: string, order?: string): Promise<boolean> {
    if (this.filter === null) {
      this.filter = {};
    }
    if (order) {
      if (order === 'desc' && !field.endsWith('_dsc')) {
        if (!field.endsWith('_asc')) {
          // Add _dsc
          this.filter.order_by = field + '_dsc';
        } else {
          // Slice off _asc and add _dsc:
          this.filter.order_by = field.slice(0, -4) + '_dsc';
        }
      } else {
        this.filter.order_by = field;
      }
    } else {
      this.filter.order_by = field;
    }
    return this.refresh();
  }

  public async setFilterAndPagination(
    filter: Filter | null,
    pagination: Pagination,
  ): Promise<void> {
    if (this.pagination === null) {
      this.pagination = {};
    }
    // Only need to refresh if something changed
    if (
      !isEqual(stringify(filter), stringify(this.filter)) ||
      !isEqual(stringify(pagination), stringify(this.pagination))
    ) {
      this.filter = filter;
      if (pagination.page) {
        this.pagination.page = pagination.page;
      }
      if (pagination.pageSize) {
        this.pagination.pageSize = pagination.pageSize;
      }
      await this.refresh();
    }
  }
}
