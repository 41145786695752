


















import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';
import { getLocationPreservingState } from '../../routingUtils';

import { studyRouteName } from '../app';

import Vue from 'vue';
import Component from 'vue-class-component';
import { RawLocation } from 'vue-router';

@Component({
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries(): {
    iconName: string;
    text: string;
    location: RawLocation;
  }[] {
    return [
      {
        iconName: 'mdi-account-details',
        text: this.$tc('study.participant.prettyName', 2),
        location: getLocationPreservingState(
          studyRouteName('participant-root'),
          this.$router,
        ),
      },
    ];
  }
}
