










import Vue from 'vue';
import Component from 'vue-class-component';
import { Product } from '@/models/device/models';

@Component({})
export default class ProductList extends Vue {
  Product = Product;
  get filter() {
    return {
      organisation: this.$store.getters['global/organisation'].id,
    };
  }
}
