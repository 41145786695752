import { PluginObject } from 'vue';
import { apiClient } from '@/api/ApiClient';

export class ApiClientPlugin implements PluginObject<any> {
  install(vue, options): void {
    vue.prototype.$api = apiClient;
    if (options) {
      if (options.defaultModelsMap) {
        vue.prototype.$api.defaultModelsMap = {
          ...vue.prototype.$api.defaultModelsMap,
          ...options.defaultModelsMap,
        };
      }
    }
  }
}
