export function toLocaleString(value?: string | number | Date | null): string {
  if (!value) {
    return '';
  }
  return new Date(value).toLocaleString();
}

export function toLocaleDateString(
  value?: string | number | Date | null,
): string {
  if (!value) {
    return '';
  }
  return new Date(value).toLocaleDateString();
}
