import { device as deviceEn } from '@/lang/en/device';

export const device: typeof deviceEn = {
  product: {
    prettyName: 'Produkt | Produkte',
    fields: {
      name: 'Name',
      create_time: 'Erstellungsdatum',
    },
  },
  model: {
    prettyName: 'Modell | Modelle',
    fields: {
      name: 'Name',
      brief: 'Beschreibung',
      handle: 'Handle',
      product_name: 'Produkt',
    },
  },
  classification: {
    prettyName: 'Klassifikation | Klassifikationen',
    fields: {
      name: 'Name',
      handle: 'Handle',
    },
  },
  deviceSettingKind: {
    prettyName: 'Setting Kind | Setting Kinds',
    fields: {
      name: 'Name',
      handle: 'Handle',
      description: 'Beschreibung',
    },
  },
  device: {
    prettyName: 'Gerät | Geräte',
    fields: {
      name: 'Name',
      device_id: 'Geräte ID',
      description: 'Beschreibung',
      create_time: 'Erstellt',
      classification_name: 'Klassifikation',
      model_name: 'Modell',
      firmware_version: 'Firmware',
      last_seen: 'Zuletzt gesehen',
    },
    selectDevices: 'Geräte auswählen',
  },
  setting: {
    prettyName: 'Geräte Setting | Geräteset Settings',
    applyToOtherDevices: 'Auf andere Geräte anwenden',
    deviceSelectionListDescription:
      'Alle Geräte vom selben Modell werden angezeigt.',
    deviceRelationSelectionListDescription:
      'Alle Source Geräte in dieser Data Applikation mit derselben Rolle (Modell) werden angezeigt.',
    confirmApply:
      'Sind Sie sicher, dass Sie dieses Setting auf die ausgewählten Geräte anwenden möchten? Bestehende Settings mit diesem Key werden überschrieben.',
  },
};
