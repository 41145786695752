const diffTypeId = '~~valueDiffType';
enum ValueState {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  UNCHANGED = 'unchanged',
}
/**
 * TODO: Implement deep diff for arrays as well
 */

/**
 * Compares the original object with the updated object and returns all values that have
 * changed or have been created
 * @param updateObject updated Object
 * @param originalObject Original Object
 */
export function prepareUpdateObject(
  updateObject: Record<string, any>,
  originalObject: Record<string, any>,
) {
  const diff = deepDiffMap(updateObject, originalObject);
  const updateDiff = {};
  Object.keys(diff.data).forEach(key => {
    if (diff.data[key][diffTypeId]) {
      const state: ValueState = diff.data[key][diffTypeId];
      switch (state) {
        case ValueState.CREATED:
          updateDiff[key] = updateObject[key];
          break;
        case ValueState.UPDATED:
          updateDiff[key] = updateObject[key];
          break;
        case ValueState.UNCHANGED:
          // Never remove id
          if (key === 'id') {
            updateDiff['id'] = updateObject[key];
          }
          break;
        default:
          break;
      }
    } else {
      // Both objects have it and its nested
      // insertPropsInObject(updateDiff, {
      //   [key]: prepareUpdateObject(updateObject[key], originalObject[key]),
      // })
    }
  });
  return updateDiff;
}

/**
 * Originally taken from https://stackoverflow.com/a/8596559 and adjusted
 * Calculates difference between two objects and specifiecs which values
 * have been created, updated, deleted or are unchanged.
 */
function deepDiffMap(
  updateObj: Record<string, any>,
  origObj: Record<string, any>,
): any {
  if (isFunction(updateObj) || isFunction(origObj)) {
    throw 'Invalid argument. Function given, object expected.';
  }
  if (isValue(updateObj) || isValue(origObj)) {
    return {
      [diffTypeId]: compareValues(updateObj, origObj),
      data: updateObj === undefined ? origObj : updateObj,
    };
  }

  if (isArray(updateObj)) {
    if (updateObj !== origObj) {
      return {
        [diffTypeId]: ValueState.UPDATED,
        data: updateObj,
      };
    } else {
      return {
        [diffTypeId]: ValueState.UNCHANGED,
        data: origObj,
      };
    }
  }

  const diff = {};
  let anyChanges = false;
  for (const key in updateObj) {
    if (isFunction(updateObj[key])) {
      continue;
    }

    let value2 = undefined;
    if ('undefined' != typeof origObj[key]) {
      value2 = origObj[key];
    }

    diff[key] = deepDiffMap(updateObj[key], value2);
    if (
      diff[key][diffTypeId] === ValueState.CREATED ||
      diff[key][diffTypeId] === ValueState.UPDATED ||
      diff[key][diffTypeId] === ValueState.DELETED
    ) {
      anyChanges = true;
    }
  }
  for (const key in origObj) {
    if (isFunction(origObj[key]) || 'undefined' != typeof diff[key]) {
      continue;
    }

    diff[key] = deepDiffMap(undefined, origObj[key]);
    if (
      diff[key][diffTypeId] === ValueState.CREATED ||
      diff[key][diffTypeId] === ValueState.UPDATED ||
      diff[key][diffTypeId] === ValueState.DELETED
    ) {
      anyChanges = true;
    }
  }

  if (anyChanges) {
    return {
      [diffTypeId]: ValueState.UPDATED,
      data: diff,
    };
  } else {
    return {
      [diffTypeId]: ValueState.UNCHANGED,
      data: diff,
    };
  }
}

function compareValues(updateValue, origValue) {
  if (updateValue === origValue) {
    return ValueState.UNCHANGED;
  }
  if (
    isDate(updateValue) &&
    isDate(origValue) &&
    updateValue.getTime() === origValue.getTime()
  ) {
    return ValueState.UNCHANGED;
  }
  if ('undefined' === typeof updateValue) {
    return ValueState.DELETED;
  }
  if ('undefined' === typeof origValue) {
    return ValueState.CREATED;
  }

  return ValueState.UPDATED;
}

function isFunction(obj) {
  return {}.toString.apply(obj) === '[object Function]';
}
function isArray(obj) {
  return {}.toString.apply(obj) === '[object Array]';
}
function isDate(obj) {
  return {}.toString.apply(obj) === '[object Date]';
}
function isObject(obj) {
  return {}.toString.apply(obj) === '[object Object]';
}
function isValue(obj) {
  return !isObject(obj) && !isArray(obj);
}
