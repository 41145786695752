



























































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import BaseForm from '@/components/common/BaseForm.vue';
import EmbeddedBaseForm from '@/components/common/EmbeddedBaseForm.vue';
import AvatarUploadForm from '@/components/common/AvatarUploadForm.vue';

import { Attachment } from '@/models/core/models';
import { Organisation } from '@/models/core/organisation';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';

@Component({
  name: 'organisation-form',
  components: {
    'base-form': BaseForm,
    'embedded-base-form': EmbeddedBaseForm,
    AvatarUploadForm,
  },
  props: {
    id: {
      required: true,
    },
  },
  data() {
    return {
      organisation: Organisation,
      loading: true,
      originalAvatar: null,
    };
  },
  mixins: [BeforeLeaveGuard],
})
export default class OrganisationForm extends Vue {
  $refs: {
    uploadForm: AvatarUploadForm;
  };
  organisation: Organisation;
  avatar: Attachment;
  loading = false;
  files = [];
  originalName: string;
  originalAvatar: string;
  shouldRemoveAvatar = false;

  mounted() {
    this.loading = true;
    this.$api.get('organisation', this.$props.id).then(response => {
      this.originalName = response.name;
      this.originalAvatar = response.avatar;
      this.organisation = response;
      this.loading = false;
    });
  }

  removeAvatar() {
    this.shouldRemoveAvatar = true;
    this.originalAvatar = null;
  }

  get transformedOrganisation() {
    if (this.shouldRemoveAvatar) {
      this.organisation.avatar = null;
    } else if (this.$refs.uploadForm !== undefined) {
      if (this.$refs.uploadForm.avatar) {
        this.organisation.avatar = this.$refs.uploadForm.avatar;
      }
    }
    return this.organisation;
  }

  get completedLocation() {
    if (
      this.organisation.name !== this.originalName &&
      this.$props.id !== '0'
    ) {
      // TODO: This is not optimal. Best case would be to fetch new organisation slug here
      return {
        name: 'overview',
        replace: true,
      };
    } else {
      return null;
    }
  }
}
