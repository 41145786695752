


























import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'device-history-list',
  props: {
    device: {
      required: true,
    },
    options: {
      required: true,
    },
    parameters: {
      required: true,
    },
    model: {
      default: '',
    },
  },
})
export default class LinkdsList extends Vue {
  processQuery(query) {
    const result = {};
    for (const key in query) {
      if (query[key].startsWith('$')) {
        result[key] = this.$props.parameters[query[key].replace('$', '')];
      } else {
        result[key] = query[key];
      }
    }
    return result;
  }
}
