






























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class InstructionsCard extends Vue {
  instructionsOpen = false;
}
