import { ClientAppEntry, clientAppRouteName } from '../clientAppRegistry';

export const VIEW_ID = 'web-device';

export const CLIENT_APP: ClientAppEntry = {
  name: 'Device',
  path: 'dev',
  icon: 'mdi-cellphone-link',
  defaultPathName: 'dashboard',
};

export function deviceRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName);
}
