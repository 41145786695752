import { monitoring as monitoringEn } from '@/lang/en/monitoring';

export const monitoring: typeof monitoringEn = {
  menu: {
    dashboard: 'Dashboard',
    settings: 'Einstellungen',
  },
  tile: {
    deviceName: 'Gerät',
    pid: 'PID',
    time: 'Zeit',
    value: 'Wert',
    quality: 'Qualität',
    wearing: 'Getragen',
    details: 'Details',
    hasCustomThresholds: 'individuelle Grenzwerte gesetzt',
    confirmDisableDevice:
      'Sind Sie sicher, dass Sie dieses Gerät deaktivieren möchten?',
  },
  battery: {
    battery: 'Batterie',
    onChargingStation: 'Auf Ladestation',
    isCharging: 'Am Laden',
  },
  settings: {
    selectDevices: 'Geräte auswählen',
    orderDevices: 'Geräte ordnen',
  },
  thresholds: {
    customThresholds: 'Individuelle Grenzwerte',
    defaultValuesUsed: 'Es werden bereits die Standardwerte verwendet.',
    upper: 'Obere Grenze',
    lower: 'Untere Grenze',
  },
  createTileLink: {
    tileLink: 'Tile Link',
    name: 'Link Name (optional)',
    created: 'Erstellt',
    confirmRevoke: 'Sind Sie sicher, dass Sie diesen Link widerrufen wollen?',
    createLinkInfo:
      'Sie können einen Link erstellen, welcher nur diese Kachel anzeigt.',
    activeLinkInfo:
      'Momentan ist ein Link für diese Kachel aktiv. Sie können ihn hier widerrufen.',
    newLinkInfo:
      'Dies ist Ihr neuer Link. Bitte kopieren Sie ihn jetzt. Er wird nicht wieder angezeigt. ',
  },
};
