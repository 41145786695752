import { TRANSIENT_BASE_OBJECT_DEFAULT } from '@/models/core/base';
import { ClientApp } from '@/models/client/models';

export const CLIENT_APP_DEFAULT: ClientApp = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  handle: '',
  name: '',
  view_id: '',
  description: '',
};
