import { TRANSIENT_BASE_OBJECT_DEFAULT } from '@/models/core/base';
import {
  Participant,
  DeviceAssignment,
  UpdateParticipantLabel,
  DataLabel,
} from '@/models/study/models';

export const PARTICIPANT_DEFAULT: Participant = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  pid: '',
  application: '',
  state: '',
  meta: {},
};

export const DEVICE_ASSIGNMENT_DEFAULT: DeviceAssignment = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  patient: '',
  device_relation: '',
  role: '',
  effective_time: '',
};

export const DATA_LABEL_DEFAULT: DataLabel = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  application: '',
  type: '',
};

export const UPDATE_PARTICIPANT_LABEL_DEFAULT: UpdateParticipantLabel = {
  device: '',
  role: '',
  start_time: '',
  end_time: '',
  assign: true,
};
