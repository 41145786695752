import Vue from 'vue';
import VueI18n, { Path, Locale } from 'vue-i18n';
import { en } from './en';
import { de } from './de';
import { nl } from './nl';
import { de as dateFnsDe } from 'date-fns/locale';
import { nl as dateFnsNl } from 'date-fns/locale';
import { Locale as DateFsLoacale } from 'date-fns';
import axios from 'axios';
import { LocaleOption, DMS_DEFAULT_LOCALE, NODE_ENV } from '@/env';
import { ConfigProgrammatic } from 'buefy';
import { Dictionary } from '@/util/interfaces';

Vue.use(VueI18n);

let silentTranslationWarn = true;
if (NODE_ENV === 'development') {
  // enable warning during development for any missing translations
  silentTranslationWarn = false;
}

export const i18n = new VueI18n({
  locale: DMS_DEFAULT_LOCALE,
  fallbackLocale: LocaleOption.EN,
  silentTranslationWarn,
  messages: {
    de,
    en,
    nl,
  },
});

i18n.missing = (locale: Locale, key: Path): string | undefined => {
  // Default for missing translations is key. Developers see warnings in console for missing translations.
  // return last part of key
  return key.split('.').pop();
};

// our default language that is preloaded
const loadedLanguages: LocaleOption[] = [LocaleOption.EN];

function setI18nLanguage(lang: string): string {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang);
  ConfigProgrammatic.setOptions({
    defaultDialogCancelText: translate('common.cancel'),
  });
  return lang;
}

export function loadLanguageAsync(lang: LocaleOption): Promise<string> {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(
        /* webpackChunkName: "lang-[request]" */ `@/lang/${lang}`
      ).then(msgs => {
        i18n.setLocaleMessage(lang, msgs[lang]);
        loadedLanguages.push(lang);
        return setI18nLanguage(lang);
      });
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}

export function getLocale(): LocaleOption {
  return i18n.locale as LocaleOption;
}

export function translate(key: string, plural = false): string {
  if (plural) {
    return i18n.tc(key, 2);
  } else {
    return i18n.tc(key);
  }
}

const dateFnsLocaleMap: Dictionary<DateFsLoacale | undefined> = {
  en: undefined,
  de: dateFnsDe,
  nl: dateFnsNl,
};

export function getDateFnsLocale(): DateFsLoacale | undefined {
  return dateFnsLocaleMap[i18n.locale];
}
