import { ClientAppEntry, clientAppRouteName } from '@/apps/clientAppRegistry';

export const VIEW_ID = 'web-admin';

// used directly in navbar
export const VIEW_ID_ADMIN = VIEW_ID;

export const CLIENT_APP: ClientAppEntry = {
  name: 'Admin',
  path: 'adm',
  icon: 'mdi-domain',
  defaultPathName: 'dashboard',
};

export function adminRouteName(routeName: string) {
  return clientAppRouteName(VIEW_ID, routeName);
}
