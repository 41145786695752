import {
  TransientBaseObject,
  ListModelField,
  ModelField,
} from '@/models/core/base';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';
import {
  apiClientV2,
  ApiClientV2,
  Annotation,
  RelatedAnnotation,
} from '@/api/ApiClientV2';
import { deepCopy } from '@/util/util';
import {
  PARTICIPANT_DEFAULT,
  DEVICE_ASSIGNMENT_DEFAULT,
  DATA_LABEL_DEFAULT,
} from './defaults';
import { DeviceRelation } from '../data/models';
import { Device } from '../device/models';
import { EventLogResult, DeviceDataEvent } from '../device/interfaces';
import { Dictionary } from '@/util/interfaces';

export interface ParticipantEventQuery {
  order_by?: string;
  page: number;
  page_size: number;
  start_time?: string;
  end_time?: string;
  patient: string;
  role?: string;
  event_type?: string;
  identity?: string;
}

export class Participant<
  T = {
    [key: string]: any;
  },
> extends TransientBaseObject {
  pid: string;
  application: string;
  state?: string;
  meta?: T;
  static defaultViewId = 'web-study';
  static apiUrl = 'patient';
  static useApiClientV2 = true;
  static langPath = 'study.participant';
  static objectType = 'clinic.patient';
  static relatedObjectKey = 'patient';
  static listFields: ListModelField[] = [
    {
      key: 'pid',
      sortable: true,
      searchQuery: 'pid_search',
    },
    {
      key: 'state',
    },
    {
      key: 'last_data',
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'pid',
      formProperties: {
        editable: false,
        class: 'width-400',
      },
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get annotations(): Annotation<Participant>[] {
    return [
      {
        key: 'last_data',
        callback: async (participant: Participant, api: ApiClientV2) => {
          let last_data = '';
          try {
            const result = await Participant.queryEvents({
              page: 1,
              page_size: 1,
              patient: participant.id,
            });
            if (result.results.length > 0) {
              last_data = new Date(result.results[0].time).toLocaleString();
            }
          } catch (error) {
            console.error(error);
          }
          return {
            id: participant.id,
            annotations: {
              last_data,
            },
          };
        },
      },
    ];
  }

  static get defaultModel() {
    return deepCopy(PARTICIPANT_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: deepCopy(PARTICIPANT_DEFAULT),
    };
  }

  static async updateState(id: string, state: string) {
    await apiClientV2.update<Partial<Participant>, Participant>(Participant, {
      id,
      state,
    });
  }

  static async queryEvents(query: ParticipantEventQuery) {
    const result: EventLogResult<DeviceDataEvent> = await apiClientV2.customGet(
      'patient/query-events',
      { ...query },
    );
    return result;
  }
}

export class DeviceAssignment extends TransientBaseObject {
  patient: string;
  device_relation: string;
  role: string;
  effective_time: string;
  static defaultViewId = 'web-study';
  static apiUrl = 'patient-device-assignment';
  static useApiClientV2 = true;
  static langPath = 'study.deviceassignment';
  static objectType = 'clinic.deviceassignment';
  static listFields: ListModelField[] = [
    {
      key: 'device_name',
    },
    {
      key: 'device_id',
    },
    {
      key: 'role',
      sortable: true,
    },
    {
      key: 'effective_time',
      transform: (value: string) => {
        return new Date(value).toLocaleString();
      },
    },
  ];
  static get joins(): RelatedAnnotation<DeviceRelation>[] {
    return [
      {
        relatedModelClass: Participant,
        relatedObjectProperty: 'pid',
      },
    ];
  }
  static get annotations(): Annotation<DeviceAssignment>[] {
    return [
      {
        key: 'device_name',
        callback: async (
          deviceAssignment: DeviceAssignment,
          api: ApiClientV2,
        ) => {
          try {
            const deviceRelation = await api.get<DeviceRelation>(
              DeviceRelation,
              deviceAssignment.device_relation,
            );
            const device = await api.get<Device>(Device, deviceRelation.device);
            return {
              id: deviceAssignment.id,
              annotations: {
                device_name: device.name,
                device_id: device.device_id,
              },
            };
          } catch (err) {
            return {
              id: deviceAssignment.id,
              annotations: {
                device_name: '',
                device_id: '',
              },
            };
          }
        },
      },
    ];
  }
  static fields: ModelField[] = [
    {
      key: 'role',
      formProperties: {
        class: 'width-400',
      },
    },
    {
      key: 'effective_time',
      formFieldType: FormFieldType.DATETIME_FIELD,
      formProperties: {
        class: 'width-400',
      },
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(DEVICE_ASSIGNMENT_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: deepCopy(DEVICE_ASSIGNMENT_DEFAULT),
    };
  }
}

export class UpdateParticipantLabel {
  device: string;
  role: string;
  start_time: string;
  end_time: string;
  assign: boolean;
}

export class DataLabel extends TransientBaseObject {
  application: string;
  type: string;
  patient?: string;
  device?: string;
  state?: string;
  start_time?: string;
  end_time?: string;
  meta?: any;

  static langPath = 'study.datalabel';
  static objectType = 'clinic.datalabel';
  static useApiClientV2 = true;
  static apiUrl = 'data-label';
  static listFields: ListModelField[] = [
    {
      key: 'type',
    },
    {
      key: 'patient',
    },
    {
      key: 'device',
    },
  ];
  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }
  static get defaultModel() {
    return deepCopy(DATA_LABEL_DEFAULT);
  }
}
