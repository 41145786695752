import {
  GROUP_DEFAULT,
  GROUP_MEMBERSHIP_DEFAULT,
  OBJECT_AUTHORIZATION_DEFAULT,
  ROLE_DEFAULT,
  ATTACHMENT_DEFAULT,
  contentTypes,
} from './defaults';
import {
  FormFieldType,
  FormFieldQueryBuilderMap,
} from '@/components/common/forms/formBuilderHelper';
import { ApiClientV2, RelatedAnnotation, Annotation } from '@/api/ApiClientV2';
import {
  TransientBaseObject,
  ListModelField,
  ModelField,
  CellType,
} from '@/models/core/base';
import { Organisation } from '@/models/core/organisation';
import { getModelClass } from '../objectRegistry';
import { deepCopy } from '@/util/util';
import { Profile } from './profile';

export class Attachment extends TransientBaseObject {
  kind: string;
  mime_type: string;
  encoding: string;
  file: string;

  static apiUrl = 'attachment';
  static langPath = 'admin.attachment';
  static objectType = 'attachment';

  static get defaultModel() {
    return deepCopy(ATTACHMENT_DEFAULT);
  }
}

export class Role extends TransientBaseObject {
  name: string;
  description: string;
  organisation: string;
  permissions: any;

  static apiUrl = 'role';
  static langPath = 'admin.role';
  static objectType = 'role';
  static listFields: ListModelField[] = [
    {
      key: 'name',
      sortable: true,
    },
    {
      key: 'description',
    },
  ];
  static fields: ModelField[] = [
    {
      key: 'name',
    },
    {
      key: 'description',
      required: false,
    },
    {
      key: 'permissions',
      undotized: true,
      doNotRender: true,
    },
  ];

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(ROLE_DEFAULT);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...ROLE_DEFAULT },
    };
  }
}

export class ObjectAuthorization extends TransientBaseObject {
  organisation: string;
  object_type: string;
  object_id: string;
  role: string;
  note: string;
  propagation: Propagation;
  group: string;

  static apiUrl = 'object-authorization';
  static langPath = 'admin.objectauthorization';
  static objectType = 'object-authorization';

  static listFields: ListModelField[] = [
    {
      key: 'role_name',
    },
    {
      key: 'group_name',
    },
    {
      key: 'object_type',
      sortable: true,
      transform: (objectType: string) => {
        const type = contentTypes.find(type => type.name === objectType);
        return type ? type.prettyName : objectType;
      },
    },
    {
      key: 'object_name',
      tooltip: row => {
        return {
          label: row.tooltip,
          icon: 'mdi-information-variant',
        };
      },
    },
    {
      key: 'note',
      searchQuery: 'note_search',
      cellType: CellType.EDITABLE_CELL,
    },
  ];

  static get ancestors() {
    return [
      {
        modelClass: Organisation,
      },
      {
        modelClass: Role,
      },
      {
        modelClass: Group,
      },
    ];
  }
  static get joins(): RelatedAnnotation<ObjectAuthorization>[] {
    return [
      {
        relatedModelClass: Role,
        relatedObjectProperty: 'name',
      },
      {
        relatedModelClass: Group,
        relatedObjectProperty: 'name',
      },
    ];
  }
  static get annotations(): Annotation<ObjectAuthorization>[] {
    return [
      {
        key: 'object_name',
        callback: async (
          authorization: ObjectAuthorization,
          api: ApiClientV2,
        ) => {
          let name = '';
          try {
            const apiName = contentTypes.find(
              t => t.name === authorization.object_type,
            ).apiName;
            let modelClass = null;
            try {
              modelClass = getModelClass(apiName);
            } catch (error) {
              modelClass = getModelClass(authorization.object_type);
            }
            if (!modelClass) {
              throw new Error(
                `Could not get modelClass of ${authorization.object_type}`,
              );
            }
            const obj: any = await api.get(modelClass, authorization.object_id);
            if (!obj) {
              name = authorization.object_id;
            } else if (obj && obj.name) {
              name = obj.name;
            } else if (obj && obj.title) {
              name = obj.title;
            } else {
              name = authorization.object_id;
            }
            return {
              id: authorization.id,
              annotations: {
                object_name: name,
                tooltip: authorization.object_id,
              },
            };
          } catch (error) {
            return {
              id: authorization.id,
              annotations: {
                object_name: authorization.object_id,
                tooltip: authorization.object_id,
              },
            };
          }
        },
      },
    ];
  }

  static columns() {
    return this.defaultColumns(this.langPath, this.listFields);
  }

  static get defaultModel() {
    return deepCopy(OBJECT_AUTHORIZATION_DEFAULT);
  }
}

export enum Propagation {
  BOTH = 'both',
  PARENTS = 'parents',
  CHILDREN = 'children',
  NONE = 'none',
}

export class Group extends TransientBaseObject {
  name: string;
  description: string;
  organisation: string;

  static apiUrl = 'group';
  public static objectType = 'group';
  public static langPath = 'admin.group';
  static listFields: ListModelField[] = [
    { key: 'name' },
    { key: 'description' },
  ];
  static fields: ModelField[] = [
    { key: 'name' },
    { key: 'description', required: false },
    {
      key: 'profiles',
      required: false,
      virtual: true,
      formFieldType: FormFieldType.PROFILES_SELECT,
    },
  ];
  static detailLinkQuery(context: { [key: string]: string }) {
    return FormFieldQueryBuilderMap[FormFieldType.PROFILES_SELECT](context);
  }

  static formConfig() {
    return {
      fields: this.defaultFormFields(this.langPath, this.fields),
      model: { ...GROUP_DEFAULT },
    };
  }

  static get defaultModel() {
    return deepCopy(GROUP_DEFAULT);
  }
}

export class GroupMembership extends TransientBaseObject {
  group: string;
  profile: string;

  static apiUrl = 'group-membership';
  public static objectType = 'group-membership';
  static langPath = 'admin.groupmembership';
  static listFields: ListModelField[] = [
    { key: 'profile_username' },
    { key: 'profile_email' },
  ];
  static get joins(): RelatedAnnotation<GroupMembership>[] {
    return [
      {
        relatedModelClass: Profile,
        relatedObjectProperty: 'username',
      },
      {
        relatedModelClass: Profile,
        relatedObjectProperty: 'email',
      },
      {
        relatedModelClass: Group,
        relatedObjectProperty: 'name',
      },
    ];
  }

  static get defaultModel() {
    return deepCopy(GROUP_MEMBERSHIP_DEFAULT);
  }
}

export class PasswordResetRequest {
  username: string;
  email: string;
  password1: string;
  password2: string;
}

export class PasswordReset {
  id: string;
  pin: string;
  nonce: string;
}

export class EmailUpdate {
  pin: string;
  nonce: string;
}

export class RequestEmailUpdate {
  email1: string;
  email2: string;
}

export class ProfileActivationRequest {
  username: string;
  email: string;
  password1: string;
  password2: string;
}

export class ProfileActivation {
  id: string;
  nonce: string;
  pin: string;
}

export class BackgroundTask extends TransientBaseObject {
  state: string;
  description: string;
  result?: any;
  progress?: {
    ratio: number;
  };
}
