import { RouteConfig } from 'vue-router';
import { clientAppRegistryGet } from '@/apps/clientAppRegistry';
import { VIEW_ID, dhStudyRouteName } from './app';

import RouterPassThrough from '@/components/common/RouterPassThrough.vue';

import MainMenu from '@/devicehub/apps/dhStudy/components/MainMenu.vue';
import { handleError } from '@/devicehub/utils/error';
import { globalStore } from '@/store/modules/global';
import { apiClientV2 } from '@/api/ApiClientV2';
import { useParticipantStore } from './stores/participantStore';
import { useStudyApi } from './stores/studyApi';
import { Subscription } from 'rxjs';
import { useDeviceAssignmentStore } from './stores/deviceAssignmentStore';
import { useDeviceStore } from './stores/deviceStore';
import { useStudyDataExportApi } from './stores/studyDataExportApi';
import { getCookie } from '@/util/cookie';

async function beforeEnterDhStudyApp(): Promise<void> {
  try {
    await globalStore.fetchClientAppSettings();

    setApiHeaders();
    setRefreshListeners();
    setDashboardUrls();
    setDataClientAppHandle();
    setStudyApiBasePath();
    setStudyDataExportApiBasePath();
    setAccessToken();
  } catch (error) {
    handleError(error);
  }
}

function setStudyApiBasePath(): void {
  const studyApi = useStudyApi();
  const basePath =
    globalStore.clientAppSetting('config')?.value
      ?.devicehub_study_app_base_path;
  if (!basePath) {
    throw new Error(
      '"devicehub_study_app_base_path" missing in client app seting "config"',
    );
  }
  studyApi.setBasePath(basePath);
}

function setAccessToken(): void {
  const studyApi = useStudyApi();
  const studyDataExport = useStudyDataExportApi();
  const accessToken = getCookie('keycloakAccessToken');
  if (!accessToken) {
    studyApi.setAccessToken('');
    studyDataExport.setAccessToken('');
    throw new Error(
      'no access token available for study app, please logout and login again',
    );
  }
  studyApi.setAccessToken(accessToken);
  studyDataExport.setAccessToken(accessToken);
}

function setStudyDataExportApiBasePath(): void {
  const studyDataExportApi = useStudyDataExportApi();
  const basePath =
    globalStore.clientAppSetting('config')?.value
      ?.devicehub_study_data_export_base_path;
  if (!basePath) {
    throw new Error(
      '"devicehub_study_data_export_base_path" missing in client app setting "config"',
    );
  }
  studyDataExportApi.setBasePath(basePath);
}

function setApiHeaders(): void {
  const headers =
    globalStore.clientAppSetting('config')?.value?.devicehub_api_headers;
  if (headers) {
    const studyApi = useStudyApi();
    studyApi.setHeaders(headers);

    const studyDataExportApi = useStudyDataExportApi();
    studyDataExportApi.setHeaders(headers);
  }
}

let refreshSubscription: Subscription | undefined;

/**
 * listen to refresh button from navigation bar
 */
function setRefreshListeners(): void {
  const deviceStore = useDeviceStore();
  const participantStore = useParticipantStore();
  const deviceAssignmentStore = useDeviceAssignmentStore();
  if (!refreshSubscription) {
    refreshSubscription = apiClientV2.getRefreshStream().subscribe(async () => {
      try {
        await Promise.all([
          participantStore.getParticipants(),
          deviceAssignmentStore.getDeviceAssignmentsOfParticipant(),
          deviceStore.getSourceDevices(),
        ]);
      } catch (error) {
        handleError(error);
      }
    });
  }
}

function setDashboardUrls(): void {
  const participantStore = useParticipantStore();
  const participantDashboardUrl =
    globalStore.clientAppSetting('config')?.value
      ?.devicehub_participant_dashboard_url;
  if (!participantDashboardUrl) {
    throw new Error(
      '"devicehub_participant_dashboard_url" missing in client app seting "config"',
    );
  }
  participantStore.participantDashboardUrl = participantDashboardUrl;

  const deviceStore = useDeviceStore();
  const deviceDashboardUrl =
    globalStore.clientAppSetting('config')?.value
      ?.devicehub_device_dashboard_url;
  if (!deviceDashboardUrl) {
    throw new Error(
      '"devicehub_participant_dashboard_url" missing in client app seting "config"',
    );
  }
  deviceStore.deviceDashboardUrl = deviceDashboardUrl;
}

function setDataClientAppHandle(): void {
  const deviceStore = useDeviceStore();
  const dataClientAppHandle =
    globalStore.clientAppSetting('config')?.value?.data_client_app_handle;
  if (dataClientAppHandle) {
    deviceStore.dataClientAppHandle = dataClientAppHandle;
  }
}

const route: RouteConfig = {
  path: clientAppRegistryGet(VIEW_ID, 'path'),
  name: VIEW_ID,
  redirect: { name: dhStudyRouteName('dh-study-root') },
  components: {
    'default': RouterPassThrough,
    'main-menu': MainMenu,
  },
  meta: {
    beforeEnter: beforeEnterDhStudyApp,
    requiresAuth: true,
  },
  children: [
    {
      path: 'study',
      name: dhStudyRouteName('dh-study-root'),
      redirect: { name: dhStudyRouteName('dh-study') },
      component: RouterPassThrough,
      children: [
        {
          path: '',
          name: dhStudyRouteName('dh-study'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "study" */ './components/StudyOverview.vue'
            ),
        },
        {
          path: 'create',
          name: dhStudyRouteName('participant-create'),
          component: () =>
            import(
              /* webpackChunkName: "participant-create" */ './components/ParticipantCreate.vue'
            ),
        },
        {
          path: ':id',
          name: dhStudyRouteName('participant-detail'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "participant-detail" */ './components/ParticipantDetail.vue'
            ),
        },
      ],
    },
    {
      path: 'device',
      name: dhStudyRouteName('dh-study-device-root'),
      redirect: { name: dhStudyRouteName('dh-study-device') },
      component: RouterPassThrough,
      children: [
        {
          path: '',
          name: dhStudyRouteName('dh-study-device'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "dhStudyDeviceList" */ './components/DeviceList.vue'
            ),
        },
      ],
    },
    {
      path: 'export',
      name: dhStudyRouteName('dh-study-data-export-root'),
      redirect: { name: dhStudyRouteName('dh-study-data-export') },
      component: RouterPassThrough,
      children: [
        {
          path: '',
          name: dhStudyRouteName('dh-study-data-export'),
          props: true,
          component: () =>
            import(
              /* webpackChunkName: "dhStudyDataExport" */ './components/DataExport.vue'
            ),
        },
      ],
    },
  ],
};

export default route;
