import { TRANSIENT_BASE_OBJECT_DEFAULT } from '@/models/core/base';
import {
  DataApplication,
  Output,
  Stream,
  DeviceRelation,
  DeviceSessionConfig,
  DataSourceTemplate,
  DataSource,
  DataSourceOutputType,
  DataSourceParams,
  TimeSeries,
} from '@/models/data/models';

export const DATA_APPLICATION_DEFAULT: DataApplication = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  organisation: '',
  handle: '',
  name: '',
  description: '',
};

export const OUTPUT_DEFAULT: Output = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  stream: '',
  handle: '',
  plugin_id: '',
  config: {
    version: 1,
  },
};

export const STREAM_DEFAULT: Stream = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  application: '',
  handle: '',
  stream_id_filter: [],
  role_filter: [],
  model_filter: [],
};

export const DEVICE_RELATION_DEFAULT: DeviceRelation = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  application: '',
  role: '',
  device: '',
};

export const DEVICE_SESSION_CONFIG_DEFAULT: DeviceSessionConfig = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  application: '',
  handle: '',
  name: '',
  description: '',
  expiration_policy: {
    closed: 86400,
    expired: 604800,
  },
};

export const DATA_SOURCE_TEMPLATE_DEFAULT: DataSourceTemplate = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  plugin_id: '',
  config: {},
  application: '',
  handle: '',
};

export const DATA_SOURCE_DEFAULT: DataSource = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  template: '',
  parameters: {},
  hash: '',
  content: null,
  bg_task: null,
  is_prepared: false,
  output_type: 'time-series' as DataSourceOutputType, // using enum leads to webpack error
};

export const DATA_SOURCE_PARAMS_DEFAULT: DataSourceParams = {
  template: '',
  parameters: {},
  output_type: 'time-series' as DataSourceOutputType, // using enum leads to webpack error
};

export const TIME_SERIES_DEFAULT: TimeSeries = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  file: '',
  size: 0,
  metadata: {},
  last_access: new Date(),
  application: '',
};
