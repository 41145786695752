
















import Vue from 'vue';
import Component from 'vue-class-component';
import OrganisationSelector from './OrganisationSelector.vue';
import { mapState } from 'vuex';

@Component({
  name: 'sidebar',
  components: {
    OrganisationSelector,
  },
  computed: {
    ...mapState('global', ['context']),
  },
})
export default class SideBar extends Vue {}
