































import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

import SideBar from '@/components/navigation/sidebar/SideBar.vue';
import PushSideBar from '@/components/navigation/sidebar/PushSideBar.vue';
import Breadcrumb from '@/components/navigation/Breadcrumb.vue';
import NavFooter from '@/components/navigation/NavFooter.vue';
import { setDocumentTitle } from './util/util';
import { authStore } from './store/modules/auth/auth';
import { DMS_FRONTEND_THEME, THEME } from './env';

@Component({
  name: 'app',
  components: {
    Breadcrumb,
    SideBar,
    PushSideBar,
    NavFooter,
  },
  computed: {
    ...mapGetters('global', ['navbarHidden', 'navigationIsCollapsed']),
  },
})
export default class App extends Vue {
  created(): void {
    const document: Document = window.document;
    const favicon: HTMLElement & { href?: string } =
      document.getElementById('favicon');

    // set custom title and favicon here if applicable
    setDocumentTitle('Device Hub');
    favicon.href = '/img/favicon.ico';

    if (DMS_FRONTEND_THEME === THEME.MARNE) {
      favicon.href = '/img/marne/favicon.png';
    }
  }

  get isPushed(): boolean {
    return (
      !this.$store.getters['global/navigationIsCollapsed'] &&
      this.$route.params.app_handle !== undefined &&
      this.isLoggedIn
    );
  }

  get isLoggedIn(): boolean {
    return authStore.isLoggedIn;
  }

  get showSideBar(): boolean {
    return this.isLoggedIn && !this.$route.meta?.hideSidebar;
  }
}
