import { toLocaleDateString, toLocaleString } from '@/devicehub/utils/filters';

/**
 * Function to transform the value. Options:
 * - dateToLocaleString: transform an ISO 8601 date string to the locale format of the browser, e.g. "2021-08-25T08:53:21.069520+00:00" is transformed to "25/08/2021, 10:53:21"
 * - dateToLocaleDateString: transform an ISO 8601 date string to the locale date format (without time) of the browser, e.g. "2021-08-25T08:53:21.069520+00:00" is transformed to "25/08/2021"
 */
export enum Transform {
  DATE_TO_LOCALE_STRING = 'dateToLocaleString',
  DATE_TO_LOCALE_DATE_STRING = 'dateToLocaleDateString',
}

export const transformMap: Record<Transform, (v: any) => any> = {
  [Transform.DATE_TO_LOCALE_STRING]: v => toLocaleString(v),
  [Transform.DATE_TO_LOCALE_DATE_STRING]: v => toLocaleDateString(v),
};

export interface StateViewConfig {
  /**
   * array of fields
   */
  fields: {
    /**
     * Key of the JSON property to use, use a dot (.) for nested properties (e.g. "wifi_information.ip")
     */
    key: string;
    /**
     * Text to show above the field
     */
    label: string;
    /**
     * Function to transform the value (optional). See `Transform`.
     */
    transform?: Transform;
    /**
     * Text to show below the field (optional)
     */
    message?: string;
  }[];
  /**
   * Text to show below all the fields (optional)
   */
  message?: string;
}

export interface StateField {
  key: string;
  label: string;
  value: string | number | boolean | null | undefined;
  message?: string;
}
