export const PLOT_COLORS = [
  '#d53e4f',
  '#74c144',
  '#3e76ca',
  '#f46d43',
  '#66c2a5',
  '#940CA7',
  '#DAF80D',
  '#9e0142',
  '#fdae61',
  '#e6f598',
  '#3288bd',
  '#72C39B',
  '#80CEAA',
  '#80CCB3',
  '#7FC9BD',
  '#7FC7C6',
  '#7EC4CF',
  '#7FBBCF',
  '#8897CE',
  '#8F90CD',
  '#9788CD',
  '#9E81CC',
  '#AA81C5',
  '#B681BE',
  '#C280B7',
  '#DC647E',
  '#E05A6D',
  '#E16167',
  '#E26962',
  '#E2705C',
  '#E37756',
  '#E38457',
  '#E0B15B',
  '#DFB95C',
  '#DDC05E',
  '#DBC75F',
  '#E3CF6D',
  '#EAD67C',
  '#F2DE8A',
];
