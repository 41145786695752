











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Device, DeviceSetting } from '@/models/device/models';
import { deepCopy } from '@/util/util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, merge } from 'lodash';
import { ModelConfig, ParameterConfig } from '../interfaces';
import { globalStore } from '@/store/modules/global';

@Component({})
export default class CustomThresholds extends Vue {
  @Prop({ required: true }) device!: string;

  /**
   * Custom parameters for this device
   */
  customModelConfig: ModelConfig | null = null;
  /**
   * Original parameters to check shouldWarnOnLeave
   */
  originalCustomModelConfig: ModelConfig | null = null;
  /**
   * Default parameters from client app settings
   */
  defaultCustomModelConfig: ModelConfig | null = null;
  /**
   * Device setting for last modified time
   */
  deviceCustomModelConfig: DeviceSetting | null = null;

  loading = true;

  destroySubject = new Subject<void>();

  deviceObj: Device | null = null;

  get parametersConfig(): ParameterConfig[] {
    return (
      this.customModelConfig?.monitoringConfig?.streams?.[0]
        ?.parametersConfig || []
    );
  }

  get shouldWarnOnLeave(): boolean {
    return !isEqual(this.customModelConfig, this.originalCustomModelConfig);
  }

  get hasCustomThresholds(): boolean {
    return !isEqual(this.customModelConfig, this.defaultCustomModelConfig);
  }

  get lastModifiedFormatted(): string {
    if (this.deviceCustomModelConfig?.last_modified) {
      return new Date(
        this.deviceCustomModelConfig.last_modified,
      ).toLocaleString();
    }
    return 'N/A';
  }

  async mounted(): Promise<void> {
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.init();
      });
    await this.init();
  }

  destroyed(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  hasThreshold(param: ParameterConfig): boolean {
    if (param?.thresholds?.value?.danger) {
      return true;
    }
    return false;
  }

  async init(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      this.deviceObj = await this.$apiv2.get<Device>(Device, this.device);
      const setting = globalStore.clientAppSetting('device_models');
      if (setting?.value) {
        const deviceModels: ModelConfig[] = setting.value.device_models || [];
        this.defaultCustomModelConfig =
          deviceModels.find(m => m.id === this.deviceObj?.model) ?? null;
        let newModelConfig: ModelConfig | null = deepCopy(
          this.defaultCustomModelConfig,
        );
        try {
          this.deviceCustomModelConfig = await Device.getSetting(
            this.$apiv2,
            this.device,
            'custom_model_config',
          );
          newModelConfig = merge(
            newModelConfig,
            this.deviceCustomModelConfig?.value?.custom_model_config,
          );
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // does not exist yet, continue
          } else {
            throw error;
          }
        }
        // only use here to avoid flashing
        this.customModelConfig = newModelConfig;
        this.originalCustomModelConfig = deepCopy(this.customModelConfig);
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    this.loading = false;
    loading.close();
  }

  async reset(): Promise<void> {
    this.customModelConfig = deepCopy(this.defaultCustomModelConfig);
  }

  async save(): Promise<void> {
    const loading = this.$buefy.loading.open({});
    try {
      await Device.setSetting(this.$apiv2, this.device, 'custom_model_config', {
        custom_model_config: this.customModelConfig,
      });
      await this.$apiv2.refreshData();
      this.$buefy.toast.open({
        message: this.$tc('common.saveSuccess'),
        type: 'is-success',
      });
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
