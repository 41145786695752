



















































































































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { isEqual } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ACT_TRACKER_ACC_SETTINGS_DEFAULT,
  ActivityTrackerAccelerometerSettings,
  HalfBwOptions,
  LowNoiseOptions,
  OdrOptions,
  RangeOptions,
} from '@/models/device/activity_tracker/models';
import { Device } from '@/models/device/models';
import { deepCopy } from '@/util/util';

@Component({})
export default class DeviceDetail extends Vue {
  @Prop({ required: true }) device: string;

  actTrackerAccSettings: ActivityTrackerAccelerometerSettings = deepCopy(
    ACT_TRACKER_ACC_SETTINGS_DEFAULT,
  );
  originalActTrackerSettings: ActivityTrackerAccelerometerSettings = deepCopy(
    ACT_TRACKER_ACC_SETTINGS_DEFAULT,
  );
  odrOptions = OdrOptions;
  rangeOptions = RangeOptions;
  halfBwOptions = HalfBwOptions;
  lowNoiseOptions = LowNoiseOptions;
  lastModified = '';
  loading = false;
  hasError = false;
  errorMessages = [];
  destroySubject = new Subject<void>();

  mounted() {
    this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get disableSave() {
    return isEqual(this.actTrackerAccSettings, this.originalActTrackerSettings);
  }

  async load() {
    try {
      const response = await Device.getSetting(
        this.$apiv2,
        this.device,
        'accelerometer-settings',
      );
      if (response && response.last_modified) {
        this.lastModified = response.last_modified;
      }
      if (response && response.value) {
        this.actTrackerAccSettings = response.value;
        this.originalActTrackerSettings = { ...this.actTrackerAccSettings };
      }
      this.hasError = false;
    } catch (error) {
      this.errorMessages = this.$errorHandler.errorToStrings(error);
      this.hasError = true;
    }
    this.loading = false;
  }

  async save() {
    this.loading = true;
    try {
      await Device.setSetting(
        this.$apiv2,
        this.device,
        'accelerometer-settings',
        this.actTrackerAccSettings,
      );
      await this.$apiv2.refreshData();
      this.hasError = false;
    } catch (error) {
      this.errorMessages = this.$errorHandler.errorToStrings(error);
      this.hasError = true;
    }
    this.loading = false;
  }

  get lastModifiedFormatted() {
    return new Date(this.lastModified).toLocaleString();
  }
}
