import { DeviceDetail } from '@/generated/api/study';
import { defineStore } from 'pinia';
import { handleError } from '@/devicehub/utils/error';
import { useStudyApi } from './studyApi';

export interface DeviceState {
  loading: boolean;
  sourceDevices: DeviceDetail[];
  searchTerm: string;
  deviceDashboardUrl: string;
  dataClientAppHandle: string;
}

export const useDeviceStore = defineStore({
  id: 'device',
  state: (): DeviceState => ({
    loading: false,
    sourceDevices: [],
    searchTerm: '',
    deviceDashboardUrl: '',
    dataClientAppHandle: 'data',
  }),
  getters: {
    filteredSourceDevices(state): DeviceDetail[] {
      return state.sourceDevices.filter(d => {
        return JSON.stringify(d)
          .toLowerCase()
          .includes(state.searchTerm.trim().toLowerCase());
      });
    },
  },
  actions: {
    async getSourceDevices(): Promise<void> {
      this.loading = true;
      try {
        const studyApi = useStudyApi();
        const response =
          await studyApi.deviceApi.getDeviceDetailListDevicesDetailGet();
        this.sourceDevices = response.data;
      } catch (error) {
        this.sourceDevices = [];
        handleError(error);
      }
      this.loading = false;
    },
  },
});
