










































































































import Vue from 'vue';
import Component from 'vue-class-component';
import DependencyTree from './DependencyTree.vue';
// import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard'
import {
  PERMISSIONS_DEFAULT,
  ROLE_DEPENDENCY_TREE,
} from '@/models/core/defaults';
import { Role } from '@/models/core/models';
import { copyTextToClipboard } from '@/util/clipboard';
import { adminRouteName } from '@/apps/admin/app';
import { deepCopy, has } from '@/util/util';

@Component({
  components: {
    DependencyTree,
  },
  props: {
    id: {
      required: true,
    },
  },
  // TODO: properly use before leave guard
  // mixins: [BeforeLeaveGuard],
})
export default class RoleForm extends Vue {
  navigateOnSave = true;
  // local copy of role for dependency tree
  role: Role = null;
  // role modelclass
  Role = Role;
  additionalPermissions = {};
  importText = '';
  errorMessage = '';
  propagate = true;
  depTreeItems = deepCopy(ROLE_DEPENDENCY_TREE);
  showUnhandled = false;

  getObject(role: Role) {
    this.role = this.orderAndFilterPermissions(role);
  }

  beforeSaveHook(role: Role) {
    // if new role
    if (this.$props.id === '0') {
      // if a new role is created, don't navigate away in order to be able to add permissions
      this.navigateOnSave = false;
      role.organisation = this.$store.getters['global/organisation'].id;
    } else {
      // when editing, write back permissions from dependency tree
      role.permissions = this.role.permissions;
      // apply the additional permissions we dont know about back
      for (const objType in this.additionalPermissions) {
        for (const permissionName in this.additionalPermissions[objType]) {
          if (role.permissions[objType] === undefined) {
            role.permissions[objType] = {};
          }
          role.permissions[objType][permissionName] =
            this.additionalPermissions[objType][permissionName];
        }
      }
    }
  }

  onCreated(role: Role) {
    this.navigateOnSave = true;
    this.$routerHandler.push({
      name: adminRouteName('role-detail'),
      params: {
        id: role.id,
      },
    });
  }

  orderAndFilterPermissions(role) {
    const formattedPermissions = {};
    let out: Role;
    for (const objType in role.permissions) {
      if (has(role.permissions, objType)) {
        formattedPermissions[objType] = {};
        for (const permissionName in PERMISSIONS_DEFAULT[objType]) {
          if (has(role.permissions[objType], permissionName)) {
            formattedPermissions[objType][permissionName] =
              role.permissions[objType][permissionName];
          }
        }

        // For permissions that are not registered with the frontend yet, we just add them to the end
        for (const permissionName in role.permissions[objType]) {
          if (this.additionalPermissions[objType] === undefined) {
            Vue.set(this.additionalPermissions, objType, {});
          }
          if (PERMISSIONS_DEFAULT[objType] === undefined) {
            Vue.set(
              this.additionalPermissions[objType],
              permissionName,
              role.permissions[objType][permissionName],
            );
          } else if (!has(PERMISSIONS_DEFAULT[objType], permissionName)) {
            Vue.set(
              this.additionalPermissions[objType],
              permissionName,
              role.permissions[objType][permissionName],
            );
          }
        }
      }
    }
    out = role;
    out.permissions = formattedPermissions;
    return out;
  }

  get unhandledPermissions() {
    const out = {};
    Object.keys(this.additionalPermissions).forEach(objectType => {
      Object.keys(this.additionalPermissions[objectType]).forEach(perm => {
        if (perm !== 'owner') {
          if (out[objectType] === undefined) {
            out[objectType] = {};
          }
          out[objectType][perm] = this.additionalPermissions[objectType][perm];
        }
      });
    });
    return out;
  }

  exportRole(onlyTrue = false) {
    const roleCopy: Role = deepCopy(this.role);
    // Copy additional permissions too:
    Object.keys(this.additionalPermissions).forEach(objectType => {
      Object.keys(this.additionalPermissions[objectType]).forEach(perm => {
        if (roleCopy.permissions[objectType] === undefined) {
          roleCopy.permissions[objectType] = {};
        }
        roleCopy.permissions[objectType][perm] =
          this.additionalPermissions[objectType][perm];
      });
    });
    if (onlyTrue) {
      for (const obj in roleCopy.permissions) {
        for (const permission in roleCopy.permissions[obj]) {
          if (!roleCopy.permissions[obj][permission]) {
            // only keep true permissions
            delete roleCopy.permissions[obj][permission];
          }
        }
      }
      for (const obj in roleCopy.permissions) {
        if (Object.keys(roleCopy.permissions[obj]).length === 0) {
          // only keep objects that have any true permission
          delete roleCopy.permissions[obj];
        }
      }
    }
    // empty unnecessary fields
    roleCopy.id = '';
    roleCopy.organisation = '';
    roleCopy.create_time = '';
    roleCopy.creator = '';
    roleCopy._permissions = {};
    // sort
    const orderedPermissions = {};
    Object.keys(roleCopy.permissions)
      .sort()
      .forEach(key => {
        orderedPermissions[key] = roleCopy.permissions[key];
      });
    roleCopy.permissions = orderedPermissions;
    for (const obj in roleCopy.permissions) {
      const ordered = {};
      Object.keys(roleCopy.permissions[obj])
        .sort()
        .forEach(key => {
          ordered[key] = roleCopy.permissions[obj][key];
        });
      roleCopy.permissions[obj] = ordered;
    }
    // copy to clipboard
    copyTextToClipboard(JSON.stringify(roleCopy, null, 2));
    this.$buefy.toast.open({
      message: 'Copied role to clipboard',
      type: 'is-success',
    });
  }

  validateJSON() {
    try {
      const role: Role = JSON.parse(this.importText);
      this.errorMessage = '';
    } catch (err) {
      this.errorMessage = this.$errorHandler.errorToString(err);
    }
  }

  importRole() {
    // do not propagate
    this.propagate = false;
    try {
      const role: Role = JSON.parse(this.importText);
      for (const obj in role.permissions) {
        for (const permission in role.permissions[obj]) {
          if (this.role.permissions[obj] === undefined) {
            this.role.permissions[obj] = {};
          }
          this.role.permissions[obj][permission] =
            role.permissions[obj][permission];
        }
      }
      this.role = this.orderAndFilterPermissions(this.role);
      this.$buefy.toast.open({
        message: 'Role imported',
        type: 'is-success',
      });
    } catch (err) {
      this.errorMessage = this.$errorHandler.errorToString(err);
    }
  }
}
