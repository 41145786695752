import { RawLocation, Route, Location } from 'vue-router';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { CollectionPagination } from '@/api/ApiClient';
import { Store } from 'vuex';
import { deepCopy } from '@/util/util';
import { Dictionary } from 'vue-router/types/router';

const prefixSeparator = '~';

export class RouterHandler {
  private _vm: Vue;
  private _router: VueRouter;
  private _store: Store<any>;
  public DEFAULT_PAGE_SIZE = 10;

  static install(vue: any): void {
    vue.prototype.$routerHandler = new RouterHandler();
  }

  public init(vm: Vue): void {
    this._vm = vm;
    this._router = vm.$router;
    this._store = vm.$store;
  }

  static prefixObject(
    prefix: string,
    obj: Dictionary<string | number | undefined>,
  ): Dictionary<string> {
    const newObj = {};
    Object.keys(obj).forEach((key: string) => {
      const prefixKey = key.startsWith(prefix) ? key : `${prefix}${key}`;
      newObj[prefixKey] = obj[key];
    });
    return newObj;
  }

  public query(prefix = ''): Dictionary<string> {
    const query = {};
    Object.keys(this._vm.$route.query).forEach((key: string) => {
      if (key.startsWith(prefix)) {
        const substring = key.substring(prefix.length);
        if (substring !== 'pageSize' && substring !== 'page') {
          query[substring] = this._vm.$route.query[key];
        }
      } else if (key.indexOf(prefixSeparator) < 0) {
        // Filter out pagination parametres and if this key is alredy defined by
        // prefix-specific filter
        if (key !== 'pageSize' && key !== 'page' && query[key] === undefined) {
          query[key] = this._vm.$route.query[key];
        }
      }
    });
    return query;
  }

  public pagination(prefix = ''): CollectionPagination {
    let pageSize;
    let page;
    Object.keys(this._vm.$route.query).forEach((key: string) => {
      if (key.startsWith(prefix)) {
        const substring = key.substring(prefix.length);
        if (substring === 'pageSize' || substring === 'page_size') {
          pageSize = parseInt(this._vm.$route.query[key] as string);
        } else if (substring === 'page') {
          page = parseInt(this._vm.$route.query[key] as string);
        }
      } else if (key.indexOf(prefixSeparator) < 0) {
        if (
          (key === 'pageSize' || key === 'page_size') &&
          pageSize === undefined
        ) {
          pageSize = parseInt(this._vm.$route.query[key] as string);
        } else if (key === 'page' && page === undefined) {
          page = parseInt(this._vm.$route.query[key] as string);
        }
      }
    });
    if (!page) {
      page = 1;
    }
    if (!pageSize) {
      pageSize = this.DEFAULT_PAGE_SIZE;
    }
    return {
      page,
      pageSize,
    };
  }

  public async push(location: RawLocation): Promise<Route> {
    if (!this._store.getters['global/navigationIsActive']) {
      return this._router.push(location);
    }
  }

  public resolve(
    to: RawLocation,
    current?: Route,
    append?: boolean,
  ):
    | {
        location: Location;
        route: Route;
        href: string;
        // backwards compat
        normalizedTo: Location;
        resolved: Route;
      }
    | undefined {
    if (!this._store.getters['global/navigationIsActive']) {
      return this._router.resolve(to, current, append);
    }
  }

  public async replace(location: RawLocation): Promise<void> {
    if (!this._store.getters['global/navigationIsActive']) {
      await this._router.replace(location).catch((error: Error) => {
        if (
          error?.message?.startsWith(
            'Avoided redundant navigation to current location',
          )
        ) {
          // ignore this error
          console.log(error);
        } else {
          throw error;
        }
      });
    }
  }

  private removeUndefinedProps(object: any) {
    Object.keys(object).forEach((key: string) => {
      if (object[key] === undefined) {
        delete object[key];
      }
    });
    return object;
  }

  private removePrefixedProps(prefix: string, object: any) {
    Object.keys(object).forEach((key: string) => {
      if (prefix === '') {
        if (key.indexOf(prefixSeparator) < 0) {
          if (key !== 'pageSize' && key !== 'page') {
            delete object[key];
          }
        }
      } else {
        if (key.startsWith(prefix)) {
          const substring = key.substring(prefix.length);
          if (substring !== 'pageSize' && substring !== 'page') {
            delete object[key];
          }
        }
      }
    });
    return object;
  }

  public removeFromQuery(prefix: string, key: string): void {
    const prefixedString = `${prefix}${key}`;

    const redirect = {
      path: this._vm.$route.path,
      query: deepCopy(this._vm.$route.query),
    };
    delete redirect.query[prefixedString];
    this.replace(redirect);
  }

  /**
   * Replaces the query parameters for the given prefix.
   * @param prefix The prefix to replace the query parameters for.
   * @param query The new query parameters.
   */
  public async setQuery(
    prefix: string,
    query: Dictionary<string>,
  ): Promise<void> {
    const prefixedQuery = RouterHandler.prefixObject(prefix, query);

    const redirect = {
      path: this._vm.$route.path,
      query: {
        ...this.removePrefixedProps(
          prefix,
          // Important to do parse-stringify, because vue-router would not detect changes in the query object directly
          deepCopy(this._vm.$route.query),
        ),
        ...prefixedQuery,
      },
    };
    await this.replace(redirect);
  }

  /**
   * Updates the query parameters for the given prefix.
   * To remove a parameter, pass undefined for it's value or use removeFromQuery.
   * @param prefix The prefix to update the query parameters for.
   * @param query The new query parameter values.
   */
  public async updateQuery(
    prefix: string,
    query: Dictionary<string | number | undefined | null>,
  ): Promise<void> {
    const prefixedQuery = RouterHandler.prefixObject(prefix, query);

    const redirect = {
      path: this._vm.$route.path,
      query: this.removeUndefinedProps({
        ...deepCopy(this._vm.$route.query),
        ...prefixedQuery,
      }),
    };
    await this.replace(redirect);
  }

  public async setPage(prefix: string, page: number): Promise<void> {
    const oldPagination = this.pagination(prefix);
    const newPagination = RouterHandler.prefixObject(prefix, {
      page: page ? page : 1,
      pageSize: oldPagination.pageSize
        ? oldPagination.pageSize
        : this.DEFAULT_PAGE_SIZE,
    });

    const redirect = {
      path: this._vm.$route.path,
      query: {
        ...this._vm.$route.query,
        ...newPagination,
      },
    };
    await this.replace(redirect);
  }

  get idString(): string {
    return 'id';
  }

  public getDetailLinkTo(
    name: string,
    id: string,
    copy = false,
    extraParams: any,
    extraQuery: any,
  ): RawLocation {
    let params = {
      [this.idString]: id,
      templateId: '',
    };
    if (copy) {
      params = {
        [this.idString]: '0',
        templateId: id,
      };
    }

    return {
      name,
      params: {
        ...params,
        ...extraParams,
      },
      query: {
        ...extraQuery,
      },
    };
  }
}
