import { ErrorHandler } from '@/api/ErrorHandler';

export async function setItem<T = any>(key: string, value: T): Promise<void> {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    ErrorHandler.handleError(error);
  }
}

export async function getItem<T = any>(key: string): Promise<T | null> {
  try {
    const val = localStorage.getItem(key);
    return JSON.parse(val) as T | null;
  } catch (error) {
    ErrorHandler.handleError(error);
    return null;
  }
}
