

















import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';
import { getLocationPreservingState } from '../../routingUtils';

import { firmwareRouteName } from '../app';

import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'main-menu',
  components: {
    'main-menu-entry': MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries() {
    return [
      {
        iconName: 'mdi-video-input-component',
        text: this.$tc('firmware.channel.prettyName', 2),
        location: getLocationPreservingState(
          firmwareRouteName('channel-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-memory',
        text: this.$tc('firmware.firmware.prettyName', 2),
        location: getLocationPreservingState(
          firmwareRouteName('firmware-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-directions',
        text: this.$tc('firmware.deliveryMethod.prettyName', 2),
        location: getLocationPreservingState(
          firmwareRouteName('delivery-method-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-stairs',
        text: this.$tc('firmware.deliveryProcedure.prettyName', 2),
        location: getLocationPreservingState(
          firmwareRouteName('delivery-procedure-root'),
          this.$router,
        ),
      },
    ];
  }
}
