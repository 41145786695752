import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { Organisation } from '@/models/core/organisation';
import { DataApplication } from '@/models/data/models';
import { ClientApp, ClientAppSetting } from '@/models/client/models';
import { apiClientV2 } from '@/api/ApiClientV2';
import axios from 'axios';
import { NavLink, VersionInfo } from './interfaces';

import { config } from 'vuex-module-decorators';
// Set rawError to true by default on all @Action decorators
config.rawError = true;

export interface GlobalState {
  backendVersion: string;
  frontendVersion: string;
  selection: {
    organisation: Organisation | null;
    application: DataApplication | null;
    clientApp: ClientApp | null;
    dataClientApp: ClientApp | null;
  };
  clientAppSettings: ClientAppSetting[];
  navLinks: NavLink[];
}

/**
 * dynamic: true -> register module into store with name
 */
@Module({ dynamic: true, store: store, name: 'global2', namespaced: true })
class Global extends VuexModule implements GlobalState {
  backendVersion = '';
  frontendVersion = '';
  selection: GlobalState['selection'] = {
    organisation: null,
    application: null,
    clientApp: null,
    dataClientApp: null,
  };
  clientAppSettings: ClientAppSetting[] = [];
  navLinks: NavLink[] = [];

  get clientAppSetting() {
    // return function getter to allow for parameter
    return (key: string) => {
      return this.clientAppSettings.find(setting => setting.key === key);
    };
  }

  @Mutation
  setBackendVersion(version: string) {
    this.backendVersion = version;
  }

  @Mutation
  setSelectedObject(args: {
    objectType: keyof GlobalState['selection'];
    object: any;
  }) {
    this.selection[args.objectType] = args.object;
  }

  @Mutation
  setClientAppSettings(settings: ClientAppSetting[]) {
    this.clientAppSettings = settings;
  }

  @Mutation
  setFrontendVersion(version: string) {
    this.frontendVersion = version.trim();
  }

  @Mutation
  findNavLinks(): void {
    const setting = this.clientAppSettings.find(s => s.key === 'nav_links');
    if (setting) {
      if (setting.value?.nav_links?.length) {
        const navLinks = setting.value?.nav_links;
        this.navLinks = navLinks.filter((l: Partial<NavLink>) => {
          return l.label && l.url && l.icon;
        });
        return;
      }
    }
    this.navLinks = [];
  }

  @Action
  async initialize() {
    await this.loadFrontendVersion();
    await this.loadBackendVersion();
  }

  @Action
  async loadFrontendVersion() {
    try {
      const version = await import('@/generated/version.json');
      this.setFrontendVersion(
        version?.distance === 0
          ? // if this is a release, use the tag
            version?.tag?.replace('release-', '')
          : // else use the raw version, which is e.g. 'release-3.7.1-11-g2d4e1b97'
            version?.raw?.replace('release-', ''),
      );
      console.debug('loadFrontendVersion', this.frontendVersion);
    } catch (error) {
      console.error('loadFrontendVersion error', error);
    }
  }

  @Action
  async loadBackendVersion() {
    const response = await axios.get<VersionInfo>('/api/v1/version/info');
    this.setBackendVersion(response.data.version);
  }

  @Action
  async fetchClientAppSettings(
    clientAppId: string | undefined = this.selection.clientApp?.id,
  ) {
    if (clientAppId === undefined) {
      throw new Error('No client app selected');
    }
    const settings = await ClientApp.getSettings(apiClientV2, clientAppId);
    this.setClientAppSettings(settings);
    this.findNavLinks();
  }
}

export const globalStore = getModule(Global);
