





















import Vue from 'vue';
import Component from 'vue-class-component';
import { Model } from '@/models/device/models';
import { Channel } from '@/models/firmware/models';

@Component({
  name: 'firmware-version',
  props: {
    payload: {
      required: true,
    },
  },
  data() {
    return {
      modelName: '',
      channelName: '',
    };
  },
})
export default class FirmwareVersion extends Vue {
  modelName: string;
  channelName: string;

  async mounted() {
    if (
      this.$props.payload &&
      this.$props.payload.fields &&
      this.$props.payload.fields.model &&
      this.$props.payload.fields.channel
    ) {
      const result = await Promise.all(
        [
          this.$apiv2.get<Model>(Model, this.$props.payload.fields.model),
          this.$apiv2.get<Channel>(Channel, this.$props.payload.fields.channel),
        ].map(p => {
          return (p as Promise<Model | Channel>).catch(err => {
            console.log(this.$errorHandler.errorToString(err));
          });
        }),
      );
      this.modelName = result[0] ? result[0].name : 'n/a';
      this.channelName = result[1] ? result[1].name : 'n/a';
    } else {
      this.modelName = 'n/a';
      this.channelName = 'n/a';
    }
  }
}
