























































import { Component, Vue, Watch } from 'vue-property-decorator';

import { CollectionSubscriber } from '@/api/ApiClient';
import DashboardCollectionTile from './DashboardCollectionTile.vue';

@Component({
  name: 'admin-dashboard',
  components: {
    DashboardCollectionTile,
  },
  data() {
    return {
      organisations: this.$api.subscribeListInComponent(
        this,
        'organisation',
        {},
      ),
      profiles: this.$api.subscribeListInComponent(this, 'profile', {
        organisation: this.$store.getters['global/organisation'].id,
      }),
      roles: this.$api.subscribeListInComponent(this, 'role', {
        organisation: this.$store.getters['global/organisation'].id,
      }),
      objectAuthorizations: this.$api.subscribeListInComponent(
        this,
        'object-authorization',
        { organisation: this.$store.getters['global/organisation'].id },
      ),
      clientApps: this.$api.subscribeListInComponent(this, 'client-app', {
        organisation: this.$store.getters['global/organisation'].id,
      }),
    };
  },
})
export default class AdminDashboard extends Vue {
  organisations: CollectionSubscriber;
  profiles: CollectionSubscriber;
  roles: CollectionSubscriber;
  objectAuthorizations: CollectionSubscriber;
  clientApps: CollectionSubscriber;

  @Watch('$route.query')
  organisationChanged() {
    // TODO: This is a temporary solution before ApiClient supports watching for a variable
    this.organisations.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    });
    this.profiles.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    });
    this.roles.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    });
    this.objectAuthorizations.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    });
    this.clientApps.setFilter({
      organisation: this.$store.getters['global/organisation'].id,
    });
  }
}
