



















































































































import Vue from 'vue';
import Component from 'vue-class-component';

import EditableCell from './EditableCell.vue';
import MultilineTooltip from '@/components/common/MultilineTooltip.vue';
import { DataStateDisplay } from '@/util/DataStateDisplay';
import { CellType } from '@/models/core/base';

@Component({
  name: 'base-list-cell',
  components: {
    EditableCell,
    MultilineTooltip,
  },
  props: {
    row: {
      required: true,
    },
    column: {
      required: true,
    },
    cell: {
      required: true,
    },
    value: {
      required: true,
    },
    index: Number,
  },
  data() {
    return {
      CellType,
    };
  },
})
export default class BaseListCell extends Vue {
  value;

  isAsync(column) {
    return column.async === true;
  }

  isAsyncLoading() {
    return this.value === DataStateDisplay.Loading;
  }

  isType(cell, type: CellType) {
    if (cell.type !== undefined) {
      return cell.type === type;
    }
  }

  hasTooltip(cell) {
    return cell.tooltip !== undefined;
  }

  transformField(cell) {
    if (cell.transform !== undefined) {
      return cell.transform(this.value);
    } else {
      return this.value;
    }
  }

  getClass(row, cell): string {
    if (cell.class !== undefined) {
      return cell.class(row);
    }
    return '';
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }
}
