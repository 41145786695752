























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Device } from '@/models/device/models';
import { DataApplication } from '@/models/data/models';
import {
  DATA_APPLICATION_DEFAULT,
  DEVICE_RELATION_DEFAULT,
} from '@/models/data/defaults';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { getDeviceSessionConfig } from '@/apps/data/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { deepCopy } from '@/util/util';

@Component({
  components: {},
  mixins: [BeforeLeaveGuard],
})
export default class GatewayView extends Vue {
  @Prop({ required: true }) device: string;
  @Prop({ required: true }) appId: string;

  bleDongleEnabled = false;
  destroySubject = new Subject<void>();

  async mounted() {
    await this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async load() {
    const loading = this.$buefy.loading.open({});
    try {
      await this.getServiceOptions();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async save() {
    const loading = this.$buefy.loading.open({});
    try {
      await this.updateGatewayConfig();
      await this.$apiv2.refreshData();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async getServiceOptions() {
    const setting = await Device.getSetting(this.$apiv2, this.device, 'config');
    const serviceOptions = setting?.value?.['service_options'];
    if (
      this.userInputIsTrue(
        serviceOptions?.['leitwert-firmware-bled112-dongle']?.enabled,
      )
    ) {
      this.bleDongleEnabled = true;
    } else {
      this.bleDongleEnabled = false;
    }
  }

  userInputIsTrue(value: string | number | boolean): boolean {
    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    } else if (typeof value === 'number') {
      return value > 0;
    } else {
      return !!value;
    }
  }

  async updateGatewayConfig() {
    const setting = await Device.getSetting(this.$apiv2, this.device, 'config');
    const newValue = setting.value;
    if (!newValue.service_options) {
      newValue.service_options = {};
    }
    newValue.service_options['leitwert-firmware-bled112-dongle'] = {
      enabled: this.bleDongleEnabled,
    };
    newValue.service_options['leitwert-system-ble'] = {
      enabled: !this.bleDongleEnabled,
    };
    await Device.setSetting(this.$apiv2, this.device, 'config', newValue);
  }
}
