import { admin as adminEn } from '@/lang/en/admin';

export const admin: typeof adminEn = {
  role: {
    prettyName: 'Rolle | Rollen',
    fields: {
      name: 'Name',
      description: 'Beschreibung',
    },
  },
  user: {
    prettyName: 'Benutzer | Benutzer',
  },
  objectauthorization: {
    prettyName: 'Objekt Berechtigung | Objekt Berechtigungen',
    fields: {
      role_name: 'Rolle',
      group_name: 'Gruppe',
      object_type: 'Objekt Typ',
      object_name: 'Objekt Name',
      note: 'Notiz',
    },
  },
  organisation: {
    prettyName: 'Organisation | Organisationen',
    fields: {
      name: 'Name',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      object_state: 'State',
      password_policy: {
        version: 'Version',
        min_length: 'Min Length',
        alpha_upper: 'Alpha Upper',
        alpha_lower: 'Alpha Lower',
        numeric: 'Numeric',
        special_char: 'Special Char',
      },
      meta: {
        email: 'E-Mail',
        phone: 'Telefon',
        website: 'Website',
      },
    },
  },
  group: {
    prettyName: 'Gruppe | Gruppen',
    fields: {
      name: 'Name',
      description: 'Beschreibung',
      organisation: 'Organisation',
      profiles: 'Profile',
    },
  },
  profile: {
    prettyName: 'Benutzer | Benutzer',
    fields: {
      username: 'Benutzername',
      email: 'E-Mail',
      remaining_external_pin_requests: 'Remaining External Pin Requests',
      remaining_external_pin_trials: 'Remaining External Pin Trials',
      remaining_internal_pin_trials: 'Remaining Internal Pin Trials',
      pin_trials: 'Pin Trials',
      first_name: 'Vorname',
      last_name: 'Nachname',
      organisation: 'Organisation',
      avatar: 'Avatar',
      avatar_img: 'Avatar',
      require_vpn_2fa: 'Require VPN 2FA',
      kind: 'Typ',
      is_activated: 'Aktiviert',
      meta: {
        phone: 'Telefon',
        gender: 'Anrede',
      },
    },
  },
  token: {
    prettyName: 'Token | Tokens',
    fields: {
      profile: 'Benutzer',
      label: 'Label',
      enabled: 'Aktiv',
      key: 'Key',
      truncated_key_hash: 'Hash',
    },
    revoke: 'Widerrufen',
    showRevoked: 'Widerrufene Token anzeigen',
    confirmRevoke: 'Sind Sie sicher, dass Sie diesen Token widerrufen wollen?',
    newKey:
      'Dies ist Ihr neuer Token. Bitte speichern Sie ihn an einem sicheren Ort.',
    newHash: 'Dies ist der öffentliche Identifikator des neuen Tokens.',
  },
  groupmembership: {
    prettyName: 'Gruppenmitgliedschaft | Gruppenmitgliedschaften',
    fields: {
      group_name: 'Gruppe',
      profile_username: 'Benutzername',
      profile_email: 'E-Mail',
    },
  },
};
