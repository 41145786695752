
























import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'formly_ordering-field',
  props: ['form', 'field', 'model', 'to'],
})
export default class OrderingField extends Vue {
  manualMode = false;
  originalOrder: number;

  mounted() {
    this.manualMode =
      this.$props.model[this.$props.field.key] !== -1 &&
      this.$props.model[this.$props.field.key] !== null;
    this.originalOrder = this.$props.model[this.$props.field.key];
  }

  onInput(value) {
    if (value === false) {
      this.$props.model[this.$props.field.key] = -1;
    } else {
      if (
        this.$props.model[this.$props.field.key] === -1 ||
        this.$props.model[this.$props.field.key] === null
      ) {
        if (this.originalOrder !== -1 && this.originalOrder !== null) {
          this.$props.model[this.$props.field.key] = this.originalOrder;
        } else {
          this.$props.model[this.$props.field.key] = 0;
        }
      }
    }
  }
}
