







































import { DeliveryProcedurePlugin } from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-generate-key',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {};
  },
})
export default class GenerateKey extends Vue {
  plugin: DeliveryProcedurePlugin;
  keyIDKindOptions = ['secure-random-8hex', 'secure-random-uuid'];
  keyKindOptions = ['secure-random-256'];

  mounted() {
    this.plugin = this.$props.plugin;
  }
}
