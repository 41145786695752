





























import {
  DeliveryAttachment,
  DeliveryProcedurePlugin,
} from '@/models/firmware/models';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'builtin-load-attachment',
  props: {
    plugin: {
      required: true,
    },
  },
  data() {
    return {
      deliveryAttachments: this.$api.subscribeListInComponent(
        this,
        'delivery-attachment',
        {
          delivery_method: this.$route.params.id,
        },
      ),
    };
  },
})
export default class LoadAttachment extends Vue {
  plugin: DeliveryProcedurePlugin;
  deliveryAttachments: DeliveryAttachment;

  mounted() {
    this.plugin = this.$props.plugin;
  }
}
