














import { LifeCycleState } from '@/models/core/base';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'object-state-tag',
  props: {
    objectState: {
      required: true,
    },
  },
})
export default class ObjectStateTag extends Vue {
  get isDraft() {
    return this.$props.objectState === LifeCycleState.Draft;
  }

  get isApproved() {
    return this.$props.objectState === LifeCycleState.Approved;
  }

  get isPendingApproval() {
    return this.$props.objectState === LifeCycleState.PendingApproval;
  }

  get isPendingDeletion() {
    return this.$props.objectState === LifeCycleState.PendingDeletion;
  }

  get isDeleted() {
    return this.$props.objectState === LifeCycleState.Deleted;
  }
}
