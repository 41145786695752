import Vue from 'vue';
import VueFormly from 'vue-formly';
import VueFormlyBuefy from 'vue-formly-buefy';

Vue.use(VueFormly);
Vue.use(VueFormlyBuefy);

// TODO: This should probaly be defined in a customFormFieldRegistry (and allow clientApps to define their own)
import LabelField from '@/components/common/forms/editor-fields/LabelField.vue';
import RelatedModelField from '@/components/common/forms/editor-fields/RelatedModelField.vue';
import MarkdownField from '@/components/common/forms/editor-fields/MarkdownField.vue';
import ColorField from '@/components/common/forms/editor-fields/ColorField.vue';
import RotationField from '@/components/common/forms/editor-fields/RotationField.vue';
import JsonField from '@/components/common/forms/editor-fields/JsonField.vue';
import DateField from '@/components/common/forms/editor-fields/DateField.vue';
import DateTimeField from '@/components/common/forms/editor-fields/DateTimeField.vue';
import NumberField from '@/components/common/forms/editor-fields/NumberField.vue';
import OrderingField from '@/components/common/forms/editor-fields/OrderingField.vue';
import CheckboxField from '@/components/common/forms/editor-fields/CheckboxField.vue';

import ProfilesSelectField from '@/apps/admin/components/group/ProfilesSelectField.vue';
import { FormFieldType } from '@/components/common/forms/formBuilderHelper';

export function initForms() {
  VueFormly.addType(FormFieldType.LABEL, LabelField);
  // VueFormly.addType(FormFieldType., PictureField)
  VueFormly.addType(FormFieldType.RELATED_MODEL_SELECT, RelatedModelField);
  VueFormly.addType(FormFieldType.NUMBER_FIELD, NumberField);
  VueFormly.addType(FormFieldType.ORDERING_FIELD, OrderingField);
  VueFormly.addType(FormFieldType.PROFILES_SELECT, ProfilesSelectField);
  VueFormly.addType(FormFieldType.MARKDOWN_FIELD, MarkdownField);
  VueFormly.addType(FormFieldType.COLOR_FIELD, ColorField);
  VueFormly.addType(FormFieldType.ROTATION_FIELD, RotationField);
  VueFormly.addType(FormFieldType.JSON_FIELD, JsonField);
  VueFormly.addType(FormFieldType.DATE_FIELD, DateField);
  VueFormly.addType(FormFieldType.DATETIME_FIELD, DateTimeField);
  VueFormly.addType(FormFieldType.CHECKBOX, CheckboxField);
}
