


















import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';
import { getLocationPreservingState } from '@/apps/routingUtils';

import { dhStudyRouteName } from '../app';

import Vue from 'vue';
import Component from 'vue-class-component';
import { Location } from 'vue-router';

@Component({
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries(): {
    iconName: string;
    text: string;
    location: Location;
  }[] {
    return [
      {
        iconName: 'mdi-account-details',
        text: this.$tc('dhStudy.participant.this', 2),
        location: getLocationPreservingState(
          dhStudyRouteName('dh-study-root'),
          this.$router,
        ) as Location,
      },
      {
        iconName: 'mdi-cellphone-link',
        text: this.$tc('dhStudy.sourceDevice.this', 2),
        location: getLocationPreservingState(
          dhStudyRouteName('dh-study-device-root'),
          this.$router,
        ) as Location,
      },
      {
        iconName: 'mdi-download',
        text: this.$tc('dhStudy.dataExport.this', 1),
        location: getLocationPreservingState(
          dhStudyRouteName('dh-study-data-export-root'),
          this.$router,
        ) as Location,
      },
    ];
  }
}
