export const firmware = {
  firmware: {
    prettyName: 'Firmware | Firmware',
    fields: {
      channel: 'Channel',
      channel_name: 'Channel',
      version: 'Version',
      publish_configuration: 'Publish Configuration',
      publish_configuration_date: 'Publish Configuration',
      publish_date: 'Publish Date',
      publish_state: 'Publish State',
      img_file: 'IMG File',
      img_hash: 'IMG Hash',
      metadata: 'Metadata',
      disclaimer: 'Disclaimer',
      release_note: 'Release Note',
    },
  },
  channel: {
    prettyName: 'Channel | Channels',
    fields: {
      name: 'Name',
      latest_firmware: 'Latest Firmware',
      id_text: 'Channel ID',
      create_time: 'Create Time',
      classification_name: 'Classification',
      add_firmware: 'Add Firmware',
      view_firmware: 'View Firmware',
    },
  },
  deliveryMethod: {
    prettyName: 'Delivery Method | Delivery Methods',
    fields: {
      name: 'Name',
      description: 'Description',
    },
  },
  deliveryProcedure: {
    prettyName: 'Delivery Procedure | Delivery Procedures',
    fields: {
      name: 'Name',
      id_text: 'Handle',
      config_valid: 'Valid',
      comment: 'Comment',
    },
  },
  deliveryAttachment: {
    prettyName: 'Delivery Attachment',
    fields: {
      id_text: 'Document',
      create_time: 'Create Time',
    },
  },
};
