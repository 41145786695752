import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import axios, { AxiosRequestConfig } from 'axios';
import { Profile } from '@/models/core/profile';
import { AuthInfo, KeycloakToken, LoginRequest } from './interfaces';
import { initializeSentry } from '@/util/sentry';
import { globalStore } from '../global';
import { Dictionary } from '@/util/interfaces';
import { addCookie, getCookie } from '@/util/cookie';

export interface AuthState {
  isLoggedIn: boolean;
  profile: Profile | null;
  axiosConfig: AxiosRequestConfig;
}

/**
 * dynamic: true -> register module into store with name
 */
@Module({ dynamic: true, store: store, name: 'auth', namespaced: true })
class Auth extends VuexModule implements AuthState {
  isLoggedIn = false;
  profile: Profile | null = null;
  axiosConfig: AxiosRequestConfig = {
    headers: {},
    withCredentials: true, // adds token from cookie to header
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
  };
  csrfHeaders: Dictionary<string> = {};

  @Mutation setIsLoggedIn(value: boolean): void {
    this.isLoggedIn = value;
  }

  @Mutation setProfile(value: Profile | null): void {
    this.profile = value;
  }

  @Mutation setAxiosConfig(value: AxiosRequestConfig): void {
    this.axiosConfig = value;
  }

  @Mutation setCsrfHeaders(value: Dictionary<string>): void {
    this.csrfHeaders = value;
  }

  @Action
  async initialize(): Promise<void> {
    const authInfoResponse = await axios.get<AuthInfo>('/api/v1/auth/info');
    const authInfo = authInfoResponse.data;
    if (authInfo.sentry_dsn) {
      initializeSentry(
        authInfo.sentry_dsn,
        authInfo.environment,
        globalStore.frontendVersion,
      );
    }
    if (authInfo.id) {
      await this.initializeProfile(authInfo.id);
      await this.initializeCsrfHeaders();
    }
    this.setIsLoggedIn(authInfo.authenticated);
  }

  @Action
  async initializeCsrfHeaders(): Promise<void> {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      this.setCsrfHeaders({
        'X-CSRFTOKEN': csrfToken,
      });
    }
  }

  @Action
  async initializeProfile(id: string): Promise<void> {
    const profileResponse = await axios.get<Profile>(`/api/v1/profile/${id}/`);
    this.setProfile(profileResponse.data);
  }

  @Action
  async logIn({ username, password }: LoginRequest): Promise<void> {
    const authInfoResponse = await axios.post<AuthInfo>('/api/v1/auth/login', {
      username,
      password,
    });
    const authInfo = authInfoResponse.data;

    const token_request_params = new URLSearchParams({
      grant_type: 'password',
      username: username,
      password: password,
      client_id: 'keycloak-devicehub',
      client_secret: 'PWMGGIg73jMTx3pllGj06j3mhUpF4Khr',
      scope: 'openid',
    });

    let keycloakTokenResponse = null;
    try {
      keycloakTokenResponse = await axios.post<KeycloakToken>(
        '/keycloak/realms/study/protocol/openid-connect/token',
        token_request_params.toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accept': 'application/json',
          },
        },
      );
    } catch (error) {
      console.error('Keycloak error: ', error);
    }

    const testToken =
      'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJNekY2aXh0ZEd4dTFkZ3lTeE1MQVYySVhtMERoQmNfV0JCZmtaMklQLU1RIn0.eyJleHAiOjE2OTY4NjU4MjEsImlhdCI6MTY5Njg2NDAyMSwianRpIjoiYzRjMzI4ZWQtMmM4NS00YzUzLWI5Y2QtYWM0ZTAzOTA1NDUyIiwiaXNzIjoiaHR0cHM6Ly9kbXMtYmx1ZS1sZWl0d2VydC5kZXBsb3llZC5zcGFjZS9rZXljbG9hay9yZWFsbXMvc3R1ZHkiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiZjphNDZmNGQ0MC0wNzVmLTRjODAtOGU1NC00ZjEwMTIxMGViMGQ6MSIsInR5cCI6IkJlYXJlciIsImF6cCI6ImtleWNsb2FrLWRldmljZWh1YiIsInNlc3Npb25fc3RhdGUiOiIwODM5MjFjZC01NGFmLTRhMWEtYmQwNy1jZDIxN2IwZDhjY2YiLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6Im9wZW5pZCBlbWFpbCBwcm9maWxlIiwic2lkIjoiMDgzOTIxY2QtNTRhZi00YTFhLWJkMDctY2QyMTdiMGQ4Y2NmIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJhZG1pbiIsImVtYWlsIjoiZGV2aWNlQGxlaXR3ZXJ0LmNoIn0.wdqmCpmEZF4T6K_HXLQtin9NegSOg3UzG6VSJbmWCc5_iLQG104rstjv7AsbhIDc0CVObRRv_mRWkW5piWa_lL0iTkVX6ekzk75rHJOmfaAeaucpGezwjClr3ZEi6lFvBtsoolt_P8zmp2GpODNmYLUbssVQeE0k-B2W3ams_O3eMqDYpM7wGUMmzJlh6s7xzY5cjgO4OZLIT_HjSGrqqOJRSCA_L104Pcz5SFFLcdbye_XY3ZreunyGFgdBjBcqxMDqofbn3LpLGbyGUHN51P03W9Za8x9I5DnR59CK2F53B840VBrRNbpskQt6hKQPZO4X41vv3_l1-mOwBRrC8w';
    const keycloakToken = keycloakTokenResponse?.data;

    if (authInfo.id) {
      await this.initializeProfile(authInfo.id);
      await this.initializeCsrfHeaders();
    }
    this.setIsLoggedIn(authInfo.authenticated);
    addCookie(
      'keycloakAccessToken',
      keycloakToken?.access_token ?? testToken,
      keycloakToken?.expires_in ?? 1800,
    );
  }

  @Action
  async logOut(): Promise<void> {
    await axios.post('/api/v1/auth/logout', {}, this.axiosConfig);
    store.commit('global/reset');
    this.setIsLoggedIn(false);
    this.setProfile(null);
    this.setCsrfHeaders({});
  }
}

export const authStore = getModule(Auth);
