import {
  BASE_OBJECT_DEFAULT,
  TRANSIENT_BASE_OBJECT_DEFAULT,
} from '@/models/core/base';
import {
  Model,
  Product,
  Device,
  Classification,
  DeviceSetting,
  DeviceState,
  DeviceSettingKind,
} from '@/models/device/models';

export const MODEL_DEFAULT: Model = {
  ...BASE_OBJECT_DEFAULT,
  name: '',
  brief: '',
  product: '',
  legacy_target_id: '',
};

export const PRODUCT_DEFAULT: Product = {
  ...BASE_OBJECT_DEFAULT,
  name: '',
  organisation: '',
};

export const DEVICE_DEFAULT: Device = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  model: '',
  device_id: '',
  classification: '',
};

export const CLASSIFICATION_DEFAULT: Classification = {
  ...BASE_OBJECT_DEFAULT,
  name: '',
  model: '',
  handle: '',
};

export const DEVICE_SETTING_DEFAULT: DeviceSetting = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  key: '',
  kind: '',
  value: {},
};

export const DEVICE_STATE_DEFAULT: DeviceState = {
  ...TRANSIENT_BASE_OBJECT_DEFAULT,
  application: '',
  device: '',
  data: {},
};

export const DEVICE_SETTING_KIND_DEFAULT: DeviceSettingKind = {
  ...BASE_OBJECT_DEFAULT,
  name: '',
  model: '',
  handle: '',
  description: '',
};
