import { client as clientEn } from '@/lang/en/client';

export const client: typeof clientEn = {
  clientapp: {
    prettyName: 'Client App | Client Apps',
    fields: {
      name: 'Name',
      description: 'Beschreibung',
      handle: 'Handle',
      view_id: 'View ID',
      organisation_name: 'Organisation',
    },
    addSetting: 'Setting hinzufügen',
    addDefaultSettingKind: 'Default Setting Kind hinzufügen',
    addDefaultSettings: 'Default Settings hinzufügen',
  },
  clientappsettingkind: {
    prettyName: 'Client App Setting Kind | Client App Setting Kinds',
    fields: {
      client_app: 'Client App',
      name: 'Name',
      description: 'Beschreibung',
      handle: 'Handle',
    },
  },
  settingGetFailure: 'Fehler beim Laden von "{key}" aus Client App Settings',
};
