


















import MainMenuEntry from '@/components/navigation/MainMenuEntry.vue';
import { getLocationPreservingState } from '../../routingUtils';

import { monitoringRouteName } from '../app';

import Vue from 'vue';
import Component from 'vue-class-component';
import { RawLocation } from 'vue-router';

@Component({
  name: 'main-menu',
  components: {
    MainMenuEntry,
  },
})
export default class MainMenu extends Vue {
  get menuEntries(): {
    iconName: string;
    text: string;
    location: RawLocation;
  }[] {
    return [
      {
        iconName: 'mdi-playlist-check',
        text: this.$tc('monitoring.menu.dashboard'),
        location: getLocationPreservingState(
          monitoringRouteName('dashboard-root'),
          this.$router,
        ),
      },
      {
        iconName: 'mdi-cog',
        text: this.$tc('monitoring.menu.settings'),
        location: getLocationPreservingState(
          monitoringRouteName('settings-root'),
          this.$router,
        ),
      },
    ];
  }
}
