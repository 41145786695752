import { BASE_OBJECT_DEFAULT } from '@/models/core/base';
import {
  Channel,
  DeliveryMethod,
  DeliveryProcedure,
  Firmware,
  DeliveryAttachment,
} from '@/models/firmware/models';

export const CHANNEL_DEFAULT: Channel = {
  ...BASE_OBJECT_DEFAULT,
  id_text: '',
  name: '',
  model: '',
  classification: '',
};

export const DELIVERY_METHOD_DEFAULT: DeliveryMethod = {
  ...BASE_OBJECT_DEFAULT,
  model: '',
  name: '',
  description: '',
};

export const DELIVERY_PROCEDURE_DEFAULT: DeliveryProcedure = {
  ...BASE_OBJECT_DEFAULT,
  name: '',
  comment: '',
  id_text: '',
  config_valid: true,
  device_options: {
    required: false,
    autotracking: false,
  },
  parameters: {},
  update_options: {
    expiration_policy: {
      ready: 15 * 60,
      up_to_date: 604800,
      success: 1209600,
      fail: 2592000,
      unconfirmed: 60 * 60 * 3,
    },
    auto_start: false,
    enable_confirm_step: false,
    confirm_timeout: 10,
    concurrency_limit_retry_after: 60,
    max_concurrent_pipelines: 5,
  },
  delivery_method: '',
  stages: [],
};

export const FIRMWARE_DEFAULT: Firmware = {
  ...BASE_OBJECT_DEFAULT,
  channel: '',
  version: '',
  publish_configuration: {
    publish_date: new Date(),
  },
  publish_state: '',
  img_file: '',
  img_hash: '',
  metadata: {},
  disclaimer: '',
  release_note: '',
};

export const DELIVERY_ATTACHMENT_DEFAULT: DeliveryAttachment = {
  ...BASE_OBJECT_DEFAULT,
  id: '',
  create_time: '' || new Date(),
  id_text: '',
  delivery_method: '',
  attachment: '',
};
