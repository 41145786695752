export class PromiseConcurrencyLimiter {
  constructor(public maxConcurrency = 10) {}

  private seq = 0;
  private promises: Map<
    number,
    Promise<{ seq: number; promise: Promise<any>; error: Error | null }>
  > = new Map();
  private errors: Error[] = [];

  /**
   * Adds a promise to be run in parallel. If there are more unfinished promises than `maxConcurrency`,
   * the function will wait until one of the previously added ones finish.
   * @param promise The promise to add.
   * @return The original promise that has been provided as the argument.
   */
  async add<T>(promise: Promise<T>): Promise<{ original: Promise<T> }> {
    const seq = this.seq++;
    if (this.promises.size >= this.maxConcurrency) {
      const finished = await Promise.race(this.promises.values());
      this.promises.delete(finished.seq);
      if (finished.error !== null) {
        this.errors.push(finished.error);
      }
    }
    this.promises.set(
      seq,
      promise
        .then(() => {
          return { seq, promise, error: null };
        })
        .catch(e => {
          return { seq, promise, error: e };
        }),
    );
    return { original: promise };
  }

  /**
   * Awaits all remaining promises and returns all promises that failed during the whole operation.
   */
  async awaitAll(): Promise<Error[]> {
    const finished = await Promise.all(this.promises.values());
    for (const f of finished) {
      this.promises.delete(f.seq);
      if (f.error !== null) {
        this.errors.push(f.error);
      }
    }
    const errors = this.errors;
    this.errors = [];
    return errors;
  }

  /**
   * Awaits all remaining promises and returns all promises that failed during the whole operation.
   */
  async awaitAllAndRethrow(): Promise<void> {
    const errors = await this.awaitAll();
    if (errors.length === 1) {
      throw errors[0];
    } else if (errors.length > 1) {
      // TODO: Should not throw an Array, it should be a sub-class of Error
      throw errors;
    }
  }
}

/**
 * Sleep for a certain amount of time
 * @param time time in milliseconds
 */
export async function sleep(time: number): Promise<void> {
  return new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}
