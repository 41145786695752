import { VIEW_ID as VIEW_ID_MONITORING } from '@/apps/monitoring/app';
import { VIEW_ID as VIEW_ID_STUDY } from '@/apps/study/app';

export async function clientAppSettingSchemas() {
  const deviceModelsClientAppSetting = await import(
    '@/generated/schema/monitoring/DeviceModelsClientAppSetting.json'
  );
  const dashboardConfig = await import(
    '@/generated/schema/monitoring/DashboardConfig.json'
  );
  const studyConfig = await import('@/generated/schema/study/StudyConfig.json');
  const eulachConfig = await import(
    '@/generated/schema/study/EulachConfig.json'
  );
  const eulachDeviceSettings = await import(
    '@/generated/schema/study/EulachDeviceSettings.json'
  );

  return {
    [VIEW_ID_MONITORING]: {
      device_models: deviceModelsClientAppSetting,
      config: dashboardConfig,
    },
    [VIEW_ID_STUDY]: {
      config: studyConfig,
      eulach_config: eulachConfig,
      eulach_device_settings: eulachDeviceSettings,
    },
  };
}
