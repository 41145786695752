









































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  AlgoModeOptions,
  EVERION_SETTINGS_DEFAULT,
  EverionSettings,
} from '@/models/device/everion/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isEqual } from 'lodash';
import { Device } from '@/models/device/models';
import { deepCopy } from '@/util/util';

@Component({})
export default class EverionSettingsView extends Vue {
  @Prop({ required: true }) device: string;

  everionSettings: EverionSettings = deepCopy(EVERION_SETTINGS_DEFAULT);
  originalEverionSettings: EverionSettings = deepCopy(EVERION_SETTINGS_DEFAULT);
  algoModeOptions = AlgoModeOptions;
  lastModified = '';
  destroySubject = new Subject<void>();

  get lastModifiedFormatted() {
    return new Date(this.lastModified).toLocaleString();
  }

  get disableSave() {
    return isEqual(this.everionSettings, this.originalEverionSettings);
  }

  mounted() {
    this.load();
    this.$apiv2
      .getRefreshStream()
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.load();
      });
  }

  destroyed() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  async load() {
    const loading = this.$buefy.loading.open({});
    try {
      const response = await Device.getSetting(
        this.$apiv2,
        this.device,
        'parameters',
      );
      if (response && response.last_modified) {
        this.lastModified = response.last_modified;
      }
      if (response && response.value) {
        if (response.value.user_weight !== null) {
          this.everionSettings.user_weight = response.value.user_weight;
        }
        if (response.value.user_height !== null) {
          this.everionSettings.user_height = response.value.user_height;
        }
        if (response.value.user_age !== null) {
          this.everionSettings.user_age = response.value.user_age;
        }
        if (response.value.algo_mode !== null) {
          this.everionSettings.algo_mode = response.value.algo_mode;
        }
        if (response.value.user_gender !== null) {
          this.everionSettings.user_gender = response.value.user_gender;
        }
        this.everionSettings.gsr_on = response.value.gsr_on;
        this.everionSettings.storage_time = response.value.storage_time;
        this.originalEverionSettings = { ...this.everionSettings };
      }
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }

  async save() {
    const loading = this.$buefy.loading.open({});
    try {
      // avoid writing empty strings
      for (const key of Object.keys(this.everionSettings)) {
        if (this.everionSettings[key] === '') {
          this.everionSettings[key] = null;
        }
      }
      await Device.setSetting(
        this.$apiv2,
        this.device,
        'parameters',
        this.everionSettings,
      );
      await this.$apiv2.refreshData();
    } catch (error) {
      this.$errorHandler.handleError(error);
    }
    loading.close();
  }
}
