import { common as commonEn } from '@/lang/en/common';

export const common: typeof commonEn = {
  orderingField: {
    setAtEnd: 'Ans Ende setzen',
    setManually: 'Manuell setzen',
  },
  avatar: {
    avatar: 'Logo',
    currentAvatar: 'Aktuelles Logo',
    resolutionLimit: 'Auflösung grösser als 10x10, kleiner als 1024x1024',
    fileSizeLimit: 'Maximale Filegrösse 200kb',
  },
  fields: {
    description: {
      label: 'Brief description',
      placeholder: 'Description',
    },
  },
  date: 'Datum',
  startDate: 'Datum Start',
  endDate: 'Datum Ende',
  startTime: 'Startzeit',
  endTime: 'Endzeit',
  lastModified: 'Zuletzt geändert',
  never: 'Nie',
  unknown: 'Unbekannt',
  error: 'Fehler',
  history: 'Verlauf',
  now: 'Jetzt',
  clear: 'Zurücksetzen',
  clickToSelect: 'Klicken zum Auswählen',
  selected: 'ausgewählt',
  overview: 'Übersicht',
  yes: 'Ja',
  no: 'Nein',
  save: 'Speichern',
  saveSuccess: 'Erfolgreich gespeichert',
  cancel: 'Abbrechen',
  reset: 'Zurücksetzen',
  back: 'Zurück',
  delete: 'Löschen',
  remove: 'Entfernen',
  addItem: '{item} hinzufügen',
  deleteSuccess: 'Erfolgreich gelöscht',
  detail: 'Detail',
  enable: 'Aktivieren',
  disable: 'Deaktivieren',
  edit: 'Bearbeiten',
  actions: 'Aktionen',
  copy: 'Kopieren',
  copyDataText: 'Klick: ID kopieren.\nDoppelklick: Reihendaten kopieren.',
  copyId: 'ID kopieren',
  copyToClipboard: 'In Zwischenablage kopieren',
  copySuccess: 'In Zwischenablage kopiert',
  copyError: 'Fehler beim Kopieren in Zwischenablage',
  copiedEvent: 'Event kopiert',
  select: 'Auswählen',
  deselect: 'Abwählen',
  selectAll: 'Alle auswählen',
  deselectAll: 'Alle abwählen',
  selection: 'Auswahl',
  apply: 'Anwenden',
  empty: 'Leer.',
  required: 'Pflichtfeld',
  loading: 'Laden',
  refreshData: 'Daten neu laden',
  refreshSuccess: 'Erfolgreich neu geladen',
  searchBy: 'Suche nach',
  search: 'Suche',
  filter: 'Filter',
  filterBy: 'Filtern nach',
  noFilter: 'Kein Filter',
  noSelection: 'Keine Auswahl',
  noPrefix: 'Keine',
  found: 'gefunden',
  moveUp: 'Nach oben verschieben',
  moveDown: 'Nach unten verschieben',
  show: 'Anzeige',
  perPage: 'pro Seite',
  number: 'Anzahl',
  create: 'Create',
  createSuccess: 'Erfolgreich erstellt',
  leaveWithoutSaveQuestion:
    'Sie haben ungespeicherte Änderungen. Sind Sie sicher, dass Sie die Seite verlassen wollen?',
  confirmDelete: 'Sind Sie sicher, dass Sie dieses Objekt löschen wollen?',
  confirmRemove: 'Sind Sie sicher, dass Sie dieses Objekt entfernen wollen?',
  uploadForm: {
    dropToUpload: 'Dateien hineinziehen zum Hochladen',
    preview: 'Vorschau',
    name: 'Name',
    size: 'Grösse',
    status: 'Status',
    chooseFile: 'Datei wählen',
    noImage: 'Kein Bild',
    startUpload: 'Start Upload',
    stopUpload: 'Stopp Upload',
    invalidExtension: 'Ungültige Dateiendung',
  },
  openDataDashboard: 'Daten Dashboard öffnen',
};
