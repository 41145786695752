import Vue from 'vue';
import axios, { AxiosRequestConfig } from 'axios';
import { Observable, Subject } from 'rxjs';
import { TransientBaseObject } from '@/models/core/base';
import { deepCopy } from '@/util/util';
import { Collection } from './Collection';
import { ApiListSubscription } from './ApiListSubscription';
import { Dictionary } from '@/util/interfaces';

// TODO: Diffentiate between typeof Transient and instance of Transient
export type ModelClass = TransientBaseObject & any;
export const BASE_URL = '/api/v1/';

/**
 * Construct url from objectType
 * @returns {string}
 */
export function constructUrl(model: ModelClass): string {
  let url = BASE_URL + model.apiUrl;
  if (!model.noTrailingSlash) {
    url += '/';
  }
  return url;
}

export function constructDeleteUrl(
  modelClass: ModelClass,
  id: string,
  purge = false,
) {
  let url = constructUrl(modelClass) + id + '/';
  if (purge) {
    url = url + '?purge=true';
  }
  return url;
}

export function constructUpdateUrl(
  modelClass: ModelClass,
  id: string,
  data?: ModelClass,
) {
  if (modelClass.customUpdateUrl) {
    return BASE_URL + modelClass.customUpdateUrl(data);
  }
  return constructUrl(modelClass) + id + '/';
}

export function constructCreateUrl(modelClass: ModelClass, data?: ModelClass) {
  if (modelClass.customCreateUrl) {
    return BASE_URL + modelClass.customCreateUrl(data);
  }
  return constructUrl(modelClass);
}

function constructFindUrl(modelClass: ModelClass) {
  return constructUrl(modelClass) + 'find?';
}

export function constructGetUrl(modelClass: ModelClass, id: string) {
  let url = constructUrl(modelClass) + id;
  if (!modelClass.noTrailingSlash) {
    url += '/';
  }
  return url;
}

/**
 * Constructs url based on filter and pagination settings
 * @returns {string}
 */
export function constructListUrl(url: string, context: Context): string {
  let params = '?';
  if (context.filter) {
    for (const key of Object.keys(context.filter)) {
      if (context.filter[key] !== undefined && context.filter[key] !== null) {
        params += key + '=' + context.filter[key] + '&';
      }
    }
  }
  if (context.pagination?.page !== undefined) {
    params += 'page=' + context.pagination.page;
    if (context.pagination.pageSize !== undefined) {
      params += '&page_size=' + context.pagination.pageSize;
    }
    params += '&show_size=true';
  } else {
    params = params.slice(0, params.length - 1);
  }
  return url + params;
}

export interface AnnotationResponse {
  id: string;
  annotations: {
    [key: string]: any;
  };
}

export type AnnotationCallback<T = TransientBaseObject> = (
  object: T,
  apiClient: ApiClientV2,
) => Promise<AnnotationResponse>;

// An annotation is a simple transformation of a field or an additional
// field which only needs the object itself
export interface Annotation<T> {
  key: string;
  callback: AnnotationCallback<T>;
}

export interface RelatedAnnotation<T> {
  relatedModelClass?: ModelClass;
  relatedObjectType?: string;
  relatedObjectProperty: string;
}

export interface Filter {
  order_by?: string;
  [K: string]: string | number | undefined | null;
}

export interface Pagination {
  page?: number;
  pageSize?: number;
}

export interface Ordering {
  field: string;
  order?: 'asc' | 'desc';
}

export interface Status {
  fetching: boolean; // true when a request is underway
  ready: boolean; // true when first request is finished
  readyPromise: Promise<boolean>;
}

export interface Info {
  size?: number;
}

export interface HookOption {
  [key: string]: any;
}

export interface HookOptions {
  beforeSaveOptions?: HookOption;
  afterSaveOptions?: HookOption;
}

export interface Context {
  filter?: Filter | null;
  pagination?: Pagination | null;
}

export interface ListResponse<T = any> {
  size: number;
  page: number;
  page_size: number;
  results: T[];
}

export class ApiClientV2 {
  private axiosConfig: AxiosRequestConfig = {};
  private collections = new Map<string, Collection<ModelClass, ModelClass>>();
  private refreshSubject = new Subject<void>();

  constructor(axiosConfig?: AxiosRequestConfig) {
    if (axiosConfig) {
      this.axiosConfig = deepCopy(axiosConfig);
    }

    // MockHandler.getInstance(axios)
  }

  public setAxiosConfig(axiosConfig: AxiosRequestConfig): void {
    this.axiosConfig = deepCopy(axiosConfig);
  }

  /**
   * Get a single object
   * @param modelClass
   * @param id
   */
  public get<T = ModelClass>(modelClass: ModelClass, id: string): Promise<T> {
    if (id === undefined || id === null || id === '') {
      return Promise.resolve({} as T);
    }
    if (id === '0') {
      return Promise.resolve(modelClass.defaultModel);
    }
    const collection = this.getOrCreateCollection(modelClass);
    return collection.get(id, this.axiosConfig);
  }

  /**
   * Get list of objects as ListResponse
   * @param modelClass
   * @param context
   */
  public async getList<T>(
    modelClass: ModelClass,
    context: Context,
  ): Promise<ListResponse<T>> {
    const collection = this.getOrCreateCollection(modelClass);
    return collection.getList<T>(context, this.axiosConfig);
  }

  /**
   * Get list of objects as array
   * @param modelClass
   * @param context
   */
  public async getListItems<T>(
    modelClass: ModelClass,
    context: Context,
  ): Promise<T[]> {
    const response = await this.getList<T>(modelClass, context);
    return response.results;
  }

  /**
   * Get or create a single object
   * @param modelClass
   * @param data
   * @param find_by keys of data used to find object
   */
  public async getOrCreate<T>(
    modelClass: ModelClass,
    data: any,
    find_by: string[],
  ): Promise<T> {
    try {
      const query: Dictionary<string | number | undefined> = {};
      find_by.forEach(key => {
        query[key] = data[key];
      });
      // explicitly return await to catch 404
      return await this.find<T>(modelClass, query);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return this.create<T, T>(modelClass, data);
      } else {
        return Promise.reject(error);
      }
    }
  }

  /**
   * Custom GET for special URLs
   */
  public async customGet(
    url: string,
    query: Dictionary<string | number | undefined> = {},
  ): Promise<any> {
    url = BASE_URL + url + '?';
    for (const key in query) {
      url += '&' + key + '=' + query[key];
    }
    const response = await axios.get(url, this.axiosConfig);
    return response.data;
  }

  /**
   * Custom POST for special URLs
   */
  public async customPost(url: string, data: any = {}): Promise<any> {
    url = BASE_URL + url;
    const response = await axios.post(url, data, this.axiosConfig);
    return response.data;
  }

  /**
   * Custom DELETE for special URLs
   */
  public async customDelete(url: string): Promise<any> {
    url = BASE_URL + url;
    const response = await axios.delete(url, this.axiosConfig);
    return response.data;
  }

  /**
   * Custom PUT for special URLs
   */
  public async customPut(url: string, data: any = {}): Promise<any> {
    url = BASE_URL + url;
    const response = await axios.put(url, data, this.axiosConfig);
    return response.data;
  }

  public async find<T>(
    modelClass: ModelClass,
    query: Dictionary<string | number | undefined> = {},
  ): Promise<T> {
    let url = constructFindUrl(modelClass);
    for (const key in query) {
      url += '&' + key + '=' + query[key];
    }
    const response = await axios.get(url, this.axiosConfig);
    return response.data as T;
  }

  /**
   * Create new object of type modelClass
   * @param {ModelClass} modelClass
   * @param data
   * @returns {Promise<K>}
   */
  public async create<T, K>(modelClass: ModelClass, data: T): Promise<K> {
    const collection: Collection<T, K> = this.getOrCreateCollection(modelClass);
    const response = await collection.create(data, this.axiosConfig);
    return response.data;
  }

  /**
   * Update object of type modelClass
   * @param {ModelClass} modelClass
   * @param {T} data
   * @returns {Promise<K>}
   */
  public async update<T, K>(
    modelClass: ModelClass,
    data: T,
    hookOptions?: HookOptions,
  ): Promise<K> {
    const collection: Collection<T, K> = this.getOrCreateCollection(modelClass);
    const response = await collection.update(
      data,
      this.axiosConfig,
      hookOptions,
    );
    return response.data;
  }

  /**
   * Delete a single object
   * @param {ModelClass} modelClass
   * @param {string} id
   * @param {boolean} purge
   */
  public async delete(
    modelClass: ModelClass,
    id: string,
    purge = false,
  ): Promise<void> {
    const collection = this.getOrCreateCollection(modelClass);
    await collection.delete(id, purge, this.axiosConfig);
  }

  public async publish(modelClass: ModelClass, id: string): Promise<void> {
    const url = `${modelClass.apiUrl}/${id}/publish`;
    await this.customPost(url, undefined);
  }

  public subscribe<T, K>(
    vm: Vue | null,
    modelClass: ModelClass,
    filter: Filter | null,
    pagination: Pagination,
    annotations?: Annotation<TransientBaseObject>[],
    joins?: RelatedAnnotation<TransientBaseObject>[],
    refreshPeriod?: number,
  ): ApiListSubscription<K> {
    // Get or create collection and add new subscriber
    const collection: Collection<T, K> = this.getOrCreateCollection(modelClass);
    const apiListSubscription: ApiListSubscription<K> = collection.subscribe(
      this.axiosConfig,
      modelClass,
      filter,
      pagination,
      annotations,
      joins,
      refreshPeriod,
    );
    if (vm) {
      vm.$on('hook:destroyed', () => {
        apiListSubscription.dispose();
      });
    }
    return apiListSubscription;
  }

  private getOrCreateCollection<T, K>(
    modelClass: ModelClass,
  ): Collection<T, K> {
    let collection = this.collections.get(modelClass.objectType);
    if (!collection) {
      collection = new Collection(modelClass);
      this.collections.set(modelClass.objectType, collection);
    }
    return collection;
  }

  public getAttachmentUrl(id: string): string | undefined {
    if (id === undefined || id === null) {
      return;
    }
    return `${BASE_URL}attachment/${id}/content?${Math.random()}`;
  }

  public async refreshCollections(): Promise<void> {
    const promises: Promise<void>[] = [];
    this.collections.forEach((collection: Collection<any, any>) => {
      promises.push(collection.refresh());
    });
    await Promise.all(promises);
  }

  public async refreshData(): Promise<void> {
    this.refreshSubject.next();
    return this.refreshCollections();
  }

  public getRefreshStream(): Observable<void> {
    return this.refreshSubject.asObservable();
  }
}

export const apiClientV2 = new ApiClientV2();
