












































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import { BrushBehavior } from 'd3-brush';
import { NumberValue, ScaleLinear, ScaleTime } from 'd3-scale';
import { Area, area, Line, line } from 'd3-shape';
import { timeFormat } from 'd3-time-format';
import { debounce } from 'lodash';
import moment from 'moment';

import {
  TimeSeriesLabel,
  TimeSeriesResponse,
  TimeSeriesSegment,
  TimeSeriesSelection,
  TimeSeriesTrace,
} from '@/models/data/models';
import { deepCopy, has } from '@/util/util';
import { convertArrayOfObjectsToCSV, downloadCSV } from '@/util/download';
import { PLOT_COLORS } from './colors';

// Date, y, min max
type TimeSeriesValue = [Date, number, number, number];

interface DataSetEntry {
  name: string;
  values: TimeSeriesValue[];
  labels: TimeSeriesLabel[];
  traceKey: string;
}

@Component({})
export default class TimeSeriesExplorer extends Vue {
  @Prop({ required: true }) date!: Date;
  @Prop({ required: true }) timeSeriesSelection!: TimeSeriesSelection;
  @Prop({ required: true }) labelSourceId!: string;

  numDataPoints = 0;
  trace = undefined;
  segment = undefined;

  timeSeries: { [templateId: string]: string } = {};
  errorMessages: string[] = [];
  loadingComponent: any;
  loading = false;
  showMinMax = true;
  shouldDrawLines = true;
  shouldDrawPoints = false;
  firstLoad = true;
  settingsOpen = true;

  dataset: DataSetEntry[] = [];
  originalDataset: DataSetEntry[] = [];
  tsResponses: Map<string, TimeSeriesResponse> = new Map<
    string,
    TimeSeriesResponse
  >();
  segments: Map<string, TimeSeriesSegment> = new Map<
    string,
    TimeSeriesSegment
  >();
  userLabels: TimeSeriesLabel[] = [];
  lastLabel = '';

  availableTraces: any = {};
  availableColors = PLOT_COLORS;
  colors: any = {};

  svg: any;
  margin: { top: number; right: number; bottom: number; left: number } = {
    top: 20,
    right: 10,
    bottom: 110,
    left: 40,
  };
  margin2: { top: number; right: number; bottom: number; left: number } = {
    top: 430,
    right: 20,
    bottom: 30,
    left: 40,
  };
  legendRectSize = 15;
  width = 0;
  height = 0;
  height2 = 0;
  context: any;
  focus: any;
  legend: any;
  brush: BrushBehavior<any> | undefined;
  mainBrush: BrushBehavior<any> | undefined;
  lineFocus: Line<[Date, number]> | undefined;
  lineContext: Line<[Date, number]> | undefined;
  areaFocus: Area<[Date, number, number, number]> | undefined;
  areaContext: Area<[Date, number, number, number]> | undefined;

  parseDate = d3.timeParse('%b %Y');
  // TODO d3 type error?
  x: ScaleTime<number, number> = d3.scaleTime() as ScaleTime<number, number>;
  x2: ScaleTime<number, number> = d3.scaleTime() as ScaleTime<number, number>;
  y: ScaleLinear<number, number> = d3.scaleLinear() as ScaleLinear<
    number,
    number
  >;
  y2: ScaleLinear<number, number> = d3.scaleLinear() as ScaleLinear<
    number,
    number
  >;
  xAxis: any;
  xAxis2: any;
  yAxis: any;
  debouncedBrushed = debounce(this.brushed, 100);
  debouncedMainBrushed = debounce(this.mainBrushed, 100);

  @Watch('$props.timeSeriesSelection')
  timeSeriesSelectionChanged(): void {
    this.zoomTo(this.$props.timeSeriesSelection);
  }

  @Watch('$props.date')
  dateChanged(): void {
    this.userLabels = [];
    this.emitSelection(
      moment(this.$props.date).startOf('day'),
      moment(this.$props.date).add(1, 'days').startOf('day'),
    );
  }

  addTimeseries(templateId: string, timeSeriesId: string): void {
    // Only change if its a new timeseries id or new data source template id
    if (
      !has(this.timeSeries, templateId) ||
      this.timeSeries[templateId] !== timeSeriesId
    ) {
      Vue.set(this.timeSeries, templateId, timeSeriesId);
      this.timeSeriesChanged();
    }
  }

  clearTimeseries(templateId: string): void {
    Vue.delete(this.timeSeries, templateId);
    this.timeSeriesChanged();
  }

  timeSeriesChanged(): void {
    this.firstLoad = true;
    this.userLabels = [];
    this.prepareFocus();
    this.prepareContext();
    this.dateChanged();
  }

  @Watch('numDataPoints')
  @Watch('showMinMax')
  @Watch('shouldDrawLines')
  @Watch('shouldDrawPoints')
  timeSeriesParametersChanged(): void {
    this.loadData();
  }

  multiTimeFormat(date: Date): string {
    return (
      d3.timeSecond(date) < date
        ? d3.timeFormat('.%L')
        : d3.timeMinute(date) < date
        ? d3.timeFormat('%H:%M:%S')
        : d3.timeHour(date) < date
        ? d3.timeFormat('%H:%M')
        : d3.timeFormat('%H:%M')
    )(date);
  }

  prepareFocus(): void {
    d3.select('.focus').remove();
    d3.select('.legend').remove();
    // Leave room for legend at top
    const numTraces = Object.keys(this.availableTraces).length;
    this.margin = {
      top: 20 + numTraces * this.legendRectSize,
      right: 10,
      bottom: 110,
      left: 40,
    };
    // *2 is for context plot shown below the main focus plot
    this.margin2 = { top: 430, right: 20, bottom: 30, left: 40 };
    // This -260 seems to be necessary for IE11 and does not do anything for Chrome
    this.width = 960 - this.margin.left - this.margin.right - 260;
    this.height = 500 - this.margin.top - this.margin.bottom;
    this.height2 = 500 - this.margin2.top - this.margin2.bottom;

    // TODO d3 type error?
    this.x = d3.scaleTime().range([0, this.width]) as ScaleTime<number, number>;
    this.x2 = d3.scaleTime().range([0, this.width]) as ScaleTime<
      number,
      number
    >;
    this.y = d3.scaleLinear().range([this.height, 0]) as ScaleLinear<
      number,
      number
    >;
    this.y2 = d3.scaleLinear().range([this.height2, 0]) as ScaleLinear<
      number,
      number
    >;

    this.xAxis = d3.axisBottom(this.x).tickFormat(this.multiTimeFormat as any);
    this.xAxis2 = d3.axisBottom(this.x2).tickFormat(timeFormat('%H:%M') as any);
    this.yAxis = d3.axisLeft(this.y);

    this.mainBrush = d3
      .brushX()
      .extent([
        [0, 0],
        [this.width, this.height],
      ])
      .on('brush', () => {
        // this.mainBrushed(d3.event)
      })
      .on('end', () => {
        this.debouncedMainBrushed(d3.event);
      }) as unknown as BrushBehavior<any>; // TODO

    this.brush = d3
      .brushX()
      .extent([
        [0, 0],
        [this.width, this.height2],
      ])
      .on('end', () => {
        this.debouncedBrushed(d3.event);
      }) as unknown as BrushBehavior<any>; // TODO

    this.lineFocus = line<[Date, number]>()
      .x(d => {
        return this.x(d[0]);
      })
      .y(d => {
        return this.y(d[1]);
      });

    this.areaFocus = area<[Date, number, number, number]>()
      .x(d => {
        return this.x(d[0]);
      })
      .y0(d => {
        return this.y(d[2]);
      })
      .y1(d => {
        return this.y(d[3]);
      });

    this.areaContext = area<[Date, number, number, number]>()
      .x(d => {
        return this.x2(d[0]);
      })
      .y0(d => {
        return this.y2(d[2]);
      })
      .y1(d => {
        return this.y2(d[3]);
      });

    this.lineContext = line<[Date, number]>()
      .x(d => {
        return this.x2(d[0]);
      })
      .y(d => {
        return this.y2(d[1]);
      });

    this.focus = this.svg
      .append('g')
      .attr('class', 'focus')
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')',
      );

    this.svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', this.width)
      .attr('height', this.height);

    this.focus.append('g').attr('class', 'brush').call(this.mainBrush);

    this.focus
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(this.xAxis);

    this.focus.append('g').attr('class', 'axis axis--y').call(this.yAxis);

    this.legend = this.svg
      .append('g')
      .attr('class', 'legend')
      .attr('transform', () => {
        return 'translate(' + (this.margin.left + 10) + ',' + 0 + ')';
      });
  }

  mounted(): void {
    this.appendSvg();
    this.prepareFocus();
    this.prepareContext();
    if (Object.keys(this.timeSeries).length > 0) {
      this.loadData();
    }
  }

  appendSvg(): void {
    this.svg = d3
      .select('div#container')
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('viewBox', '0 0 760 500')
      .classed('svg-content', true);
  }

  prepareContext(): void {
    d3.select('.context').remove();
    this.context = this.svg
      .append('g')
      .attr('class', 'context')
      .attr(
        'transform',
        'translate(' + this.margin2.left + ',' + this.margin2.top + ')',
      );

    this.context
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + this.height2 + ')')
      .call(this.xAxis2);
  }

  mainBrushed(event: any): void {
    if (this.loading) {
      return;
    }
    if (event.sourceEvent && event.sourceEvent.type === 'zoom') {
      return;
    } // ignore brush-by-zoom
    if (!event.sourceEvent) {
      return;
    }
    const s = event.selection || this.x.range();

    const startDate = moment(this.x.invert(s[0]));
    const endDate = moment(this.x.invert(s[1]));

    if (event.type === 'brush') {
      if (event.sourceEvent.shiftKey) {
        // Move the selection
        /* TODO: Does not work nicely yet
          let diff = startDate.diff(endDate)
          startDate = this.$props.timeSeriesSelection.startDate.add(diff, 'ms')
          endDate = this.$props.timeSeriesSelection.endDate.add(diff, 'ms')
          this.emitSelection(startDate, endDate)
          */
      }
      return;
    }

    if (event.sourceEvent.ctrlKey) {
      // Do nothing, as we already moved selection
    } else if (event.sourceEvent.shiftKey) {
      // This creates a new user label selection
      this.$buefy.dialog.prompt({
        message: `Class name?`,
        inputAttrs: {
          placeholder: 'e.g. walking',
          value: this.lastLabel,
          maxlength: 30,
        },
        onConfirm: value => {
          this.lastLabel = value;
          this.userLabels.push({
            start: this.x.invert(s[0]),
            end: this.x.invert(s[1]),
            label: value,
          });
          this.drawUserLabels();
        },
        onCancel: () => {
          this.drawUserLabels();
        },
      });
    } else {
      this.emitSelection(startDate, endDate);
    }
  }

  emitSelection(startDate: moment.Moment, endDate: moment.Moment): void {
    const selection: TimeSeriesSelection = {
      startDate: startDate,
      endDate: endDate,
    };
    this.$emit('selection', selection);
  }

  zoomTo(selection: TimeSeriesSelection): void {
    this.x.domain([selection.startDate.toDate(), selection.endDate.toDate()]);
    this.focus.selectAll('.line').attr('d', this.lineFocus);
    this.focus.selectAll('.area').attr('d', this.areaFocus);
    this.focus.select('.axis--x').call(this.xAxis);

    const newBrushRange = [
      this.x2(this.x.domain()[0]),
      this.x2(this.x.domain()[1]),
    ];
    this.context
      .select('.brush')
      .call(this.brush)
      .call(this.brush?.move, newBrushRange);
    this.loadData();
  }

  brushed(event: any): void {
    if (event.sourceEvent && event.sourceEvent.type === 'zoom') {
      return;
    } // ignore brush-by-zoom
    if (!event.sourceEvent) {
      return;
    }
    const s = event.selection || this.x2.range();

    const startDate = moment(this.x2.invert(s[0]));
    const endDate = moment(this.x2.invert(s[1]));
    this.emitSelection(startDate, endDate);
  }

  startLoading(): void {
    this.errorMessages = [];
    if (this.firstLoad) {
      // this.loadingComponent = this.$buefy.loading.open({})
    }
    this.loading = true;
  }

  stopLoading(): void {
    if (this.loadingComponent !== undefined) {
      this.loadingComponent.close();
    }
    this.loading = false;
  }

  handleError(error: Error): void {
    this.stopLoading();
    this.errorMessages = this.$errorHandler.errorToStrings(error);
    this.$errorHandler.handleError(error, false);
  }

  clearData(): void {
    this.dataset = [];
    this.applyDataSet();
  }

  loadData(): Promise<void> {
    if (Object.keys(this.timeSeries).length < 1) {
      this.clearData();
      return Promise.resolve();
    }

    this.startLoading();

    let n = this.numDataPoints;
    if (this.numDataPoints === 0) {
      // This is auto mode.
      n = parseInt((window.innerWidth / 2).toString(), 10);
    }
    const promises = [];
    for (const templateId in this.timeSeries) {
      const id = this.timeSeries[templateId];
      promises.push(
        this.$api
          .customGet('data/time-series/' + id + '/query', {
            range_min: this.$props.timeSeriesSelection.startDate.toISOString(),
            range_max: this.$props.timeSeriesSelection.endDate.toISOString(),
            n: n,
          })
          .then(response => {
            return {
              data: response.data,
              templateId,
            };
          }),
      );
    }
    return Promise.all(promises)
      .then(responses => {
        this.tsResponses.clear();
        this.segments.clear();
        responses.forEach(response => {
          this.tsResponses.set(response.templateId, response.data);
          this.segments.set(response.templateId, response.data.segments[0]);
        });

        this.updateTracesAndColors();
        this.applyDataSet();

        this.stopLoading();
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  updateTracesAndColors(): void {
    this.dataset = [];
    this.colors = {};
    const oldAvailableTraces = deepCopy(this.availableTraces);
    this.availableTraces = {};

    this.tsResponses.forEach((tsResponse, key) => {
      const updatedAvailableTraces: {
        [key: string]: {
          trace?: TimeSeriesTrace;
          active?: boolean;
        };
      } = {};
      tsResponse.traces.forEach(trace => {
        const traceKey = key + trace.key;
        if (this.firstLoad || !has(oldAvailableTraces, traceKey)) {
          updatedAvailableTraces[traceKey] = {
            trace: trace,
            active: true,
          };
        } else {
          updatedAvailableTraces[traceKey] = {
            trace: trace,
            active: oldAvailableTraces[traceKey].active,
          };
        }
      });
      this.availableTraces = {
        ...this.availableTraces,
        ...updatedAvailableTraces,
      };

      const segment = this.segments.get(key);
      if (segment !== undefined) {
        tsResponse.traces.forEach((t, j) => {
          const traceKey = key + tsResponse.traces[j].key;
          if (!has(this.colors, traceKey)) {
            this.colors[traceKey] =
              this.availableColors[
                Object.keys(this.colors).length % this.availableColors.length
              ];
          }

          if (this.availableTraces[traceKey].active) {
            const newData: TimeSeriesValue[] = [];
            for (let i = 0; i < segment.time.length; i++) {
              const data = tsResponse.traces[j].data;
              if (data.min !== undefined && data.max !== undefined) {
                if (segment[data.y][i] !== null) {
                  newData.push([
                    moment(segment.time[i]).toDate(),
                    segment[data.y][i],
                    segment[data.min][i],
                    segment[data.max][i],
                  ]);
                }
              }
            }
            this.dataset.push({
              name: tsResponse.traces[j].name,
              values: newData,
              labels: [],
              traceKey: traceKey,
            });
          }
        });
      }
    });
  }

  drawUserLabels(): void {
    this.focus.selectAll('.labelRect').remove();

    this.focus
      .selectAll('.labelRect')
      .data(this.userLabels)
      .enter()
      .append('rect')
      .attr('class', 'foo')
      .attr('x', (d: TimeSeriesLabel) => this.x(d.start))
      .attr('y', () => 0)
      .attr('width', (d: TimeSeriesLabel) => this.x(d.end) - this.x(d.start))
      .attr('height', () => this.height)
      .attr('fill', 'teal')
      .style('opacity', 0.4)
      .lower(); // Moves back to background
  }

  get formattedUserLabels(): any {
    return this.userLabels.map(label => {
      return {
        day: moment(this.$props.date).format('YYYY-MM-DD'),
        startTime: moment(label.start).format('HH:mm:ssZ'),
        endTime: moment(label.end).format('HH:mm:ssZ'),
        id: this.$props.labelSourceId,
        className: label.label,
      };
    });
  }

  drawLegend(): void {
    // Add legend
    const legend = [];
    for (const key in this.availableTraces) {
      legend.push(key);
    }
    this.legend
      .selectAll('.legend')
      .data(legend)
      .enter()
      .append('rect')
      .attr('width', this.legendRectSize)
      .attr('height', this.legendRectSize)
      .attr(
        'y',
        (d: any, i: number) =>
          this.legendRectSize + 4 + i * this.legendRectSize,
      )
      .style('fill', (key: string) => this.colors[key])
      .style('opacity', (key: string) => {
        if (this.availableTraces[key].active) {
          return 1;
        } else {
          return 0.4;
        }
      })
      .style('stroke', (key: string) => this.colors[key])
      .on('click', (key: string) => {
        this.availableTraces[key].active = !this.availableTraces[key].active;
        this.updateTracesAndColors();
        this.applyDataSet();
      });
    this.legend
      .selectAll('.legend')
      .data(legend)
      .enter()
      .append('text')
      .attr('x', this.legendRectSize + 5)
      .attr(
        'y',
        (d: any, i: number) =>
          this.legendRectSize + 17 + i * this.legendRectSize,
      )
      .text((key: string) => {
        return this.availableTraces[key].trace.name;
      });
  }

  applyDataSet(): void {
    this.prepareFocus();
    this.drawLegend();

    if (this.dataset.length === 0) {
      // Empty dataset
      this.focus.selectAll('.line').remove();
      this.focus.selectAll('.area').remove();
      this.stopLoading();
      return;
    }

    // Scale the range of the data again
    this.x.domain([
      this.$props.timeSeriesSelection.startDate.toDate(),
      this.$props.timeSeriesSelection.endDate.toDate(),
    ]);

    let yMin;
    let yMax;
    if (this.showMinMax) {
      yMin = d3.min(this.dataset, data => {
        return d3.min(data.values, d => {
          const values = [d[1]];
          // At high zoom levels the min/max values are null because all the values are already in d[1]
          if (d[2] !== null) {
            values.push(d[2]);
          }
          if (d[3] !== null) {
            values.push(d[3]);
          }
          return Math.min(...values);
        });
      });
      yMax = d3.max(this.dataset, data => {
        return d3.max(data.values, d => {
          const values = [d[1]];
          if (d[2] !== null) {
            values.push(d[2]);
          }
          if (d[3] !== null) {
            values.push(d[3]);
          }
          return Math.max(...values);
        });
      });
    } else {
      yMin = d3.min(this.dataset, data =>
        d3.min(data.values, d => Math.min(d[1])),
      );
      yMax = d3.max(this.dataset, data =>
        d3.max(data.values, d => Math.max(d[1])),
      );
    }
    this.y.domain([yMin, yMax] as Iterable<NumberValue>); // TODO

    this.focus.selectAll('.line').remove();
    this.focus.selectAll('.area').remove();

    if (this.shouldDrawLines) {
      this.drawLines();
    }

    if (this.shouldDrawPoints) {
      this.drawPoints();
    }

    if (this.showMinMax) {
      this.drawMinMax();
    }

    // change the axis
    this.focus.select('.axis--x').call(this.xAxis);
    this.focus.select('.axis--y').call(this.yAxis);

    if (this.firstLoad) {
      this.originalDataset = this.dataset;

      this.context
        .append('g')
        .attr('class', 'brush')
        .call(this.brush)
        .call(this.brush?.move, this.x.range());
    }

    // Scale the range of the data again
    this.x2.domain([
      moment(this.$props.date).startOf('day'),
      moment(this.$props.date).add(1, 'days').startOf('day'),
    ]);

    this.y2.domain([
      d3.min(this.originalDataset, data =>
        d3.min(data.values, d => Math.min(d[1], d[2], d[3])),
      ),
      d3.max(this.originalDataset, data =>
        d3.max(data.values, d => Math.max(d[1], d[2], d[3])),
      ),
    ] as Iterable<NumberValue>); // TODO
    this.context.selectAll('.line').remove();
    this.context.selectAll('.area').remove();
    this.context
      .selectAll('.line')
      .data(this.originalDataset)
      .enter()
      .append('path')
      .attr('class', 'line')
      .attr('d', (d: DataSetEntry) =>
        this.lineContext?.(d.values.map(v => [v[0], v[1]])),
      )
      .style('stroke', (d: DataSetEntry) => this.colors[d.traceKey])
      .style('opacity', 0.8);

    this.context.select('.axis--x').call(this.xAxis2);

    // Draw min max area in context
    if (this.showMinMax) {
      this.context
        .selectAll('.area')
        .data(this.originalDataset)
        .enter()
        .append('path')
        .attr('class', 'area')
        .attr('d', (d: DataSetEntry) => this.areaContext?.(d.values))
        .style('opacity', 0.4)
        .style('fill', (d: DataSetEntry) => this.colors[d.traceKey]);
    }

    this.firstLoad = false;

    // Reset brush on top of lines
    this.focus.selectAll('.brush').remove();
    this.focus.append('g').attr('class', 'brush').call(this.mainBrush);

    this.drawUserLabels();
  }

  private drawLines(): void {
    this.focus
      .selectAll('.line')
      .data(this.dataset)
      .enter()
      .append('path')
      // .attr('clip-path', 'url(#clip)')
      .attr('class', 'line')
      .attr('d', (d: DataSetEntry) =>
        this.lineFocus?.(d.values.map(v => [v[0], v[1]])),
      )
      .style('stroke', (d: DataSetEntry) => this.colors[d.traceKey])
      .style('opacity', 0.8);
  }

  private drawPoints(): void {
    this.dataset.forEach(dataSetEntry => {
      this.focus
        .selectAll(`.circle-${dataSetEntry.traceKey}`)
        .data(
          dataSetEntry.values.map(v => {
            return {
              cx: v[0],
              cy: v[1],
            };
          }),
        )
        .enter()
        .append('circle')
        .attr('class', `circle-${dataSetEntry.traceKey}`)
        .attr('cx', (d: { cx: number; cy: number }) => {
          return this.x(d.cx);
        })
        .attr('cy', (d: { cx: number; cy: number }) => {
          return this.y(d.cy);
        })
        .attr('r', 1)
        .style('stroke', () => this.colors[dataSetEntry.traceKey])
        .style('opacity', 0.8)
        .style('fill', 'none');
    });
  }

  private drawMinMax(): void {
    this.focus
      .selectAll('.area')
      .data(this.dataset)
      .enter()
      .append('path')
      .attr('class', 'area')
      .attr('d', (d: DataSetEntry) => this.areaFocus?.(d.values))
      .style('opacity', 0.3)
      .style('fill', (d: DataSetEntry) => this.colors[d.traceKey]);
  }

  downloadLabels(): void {
    const csvData = convertArrayOfObjectsToCSV({
      data: this.formattedUserLabels,
    });
    downloadCSV({
      csv: csvData,
      filename: 'labels.csv',
    });
  }
}
