export const device = {
  product: {
    prettyName: 'Product | Products',
    fields: {
      name: 'Name',
      create_time: 'Create Time',
    },
  },
  model: {
    prettyName: 'Model | Models',
    fields: {
      name: 'Name',
      brief: 'Description',
      handle: 'Handle',
      product_name: 'Product',
    },
  },
  classification: {
    prettyName: 'Classification | Classifications',
    fields: {
      name: 'Name',
      handle: 'Handle',
    },
  },
  deviceSettingKind: {
    prettyName: 'Setting Kind | Setting Kinds',
    fields: {
      name: 'Name',
      handle: 'Handle',
      description: 'Description',
    },
  },
  device: {
    prettyName: 'Device | Devices',
    fields: {
      name: 'Name',
      device_id: 'Device ID',
      description: 'Description',
      create_time: 'Created',
      classification_name: 'Classification',
      model_name: 'Model',
      firmware_version: 'Firmware',
      last_seen: 'Last Seen',
    },
    selectDevices: 'Select Devices',
  },
  setting: {
    prettyName: 'Device Setting | Device Settings',
    applyToOtherDevices: 'Apply to other devices',
    deviceSelectionListDescription: 'All devices of the same model are shown.',
    deviceRelationSelectionListDescription:
      'All source devices in this data application with the same role (model) are shown.',
    confirmApply:
      'Are you sure that you want to apply this setting to the selected devices? Any existing settings with this key will be overwritten.',
  },
};
