import { ApiClientV2, Context, apiClientV2 } from '@/api/ApiClientV2';
import {
  DeviceRelation,
  DeviceModel,
  DeviceCreate,
} from '@/models/data/models';
import {
  Device,
  DeviceSettingKind,
  DeviceSetting,
} from '@/models/device/models';
import {
  DEVICE_SETTING_KIND_DEFAULT,
  DEVICE_DEFAULT,
} from '@/models/device/defaults';
import { DEVICE_RELATION_DEFAULT } from '@/models/data/defaults';
import { ClientApp } from '@/models/client/models';
import { PromiseConcurrencyLimiter } from '@/util/async';

export async function createDeviceRelations(
  apiClient: ApiClientV2,
  clientAppId: string,
  application: string,
  device_ids: string[],
  role: string,
) {
  // get client app settings
  const setting = await ClientApp.getSetting(
    apiClientV2,
    clientAppId,
    'device_models',
  );

  // get device model from settings
  const deviceModels: DeviceModel[] = setting.value.device_models || [];
  const deviceModel = deviceModels.find(deviceModel => {
    return deviceModel.role === role;
  });
  const model = deviceModel.id;
  const deviceCreateArray: DeviceCreate[] = deviceModel.device_create || [];

  // create device relation for all given ids
  const promises: Promise<void>[] = [];
  device_ids.forEach(device_id => {
    promises.push(
      createAndInitDeviceRelation(
        apiClient,
        application,
        deviceCreateArray,
        device_id,
        role,
        model,
      ),
    );
  });
  return Promise.all(promises);
}

/**
 * Create device if it does not exist
 * Create device relation
 * Set settings of device to defaults from client app settings
 */
async function createAndInitDeviceRelation(
  apiClient: ApiClientV2,
  application: string,
  deviceCreateArray: DeviceCreate[],
  device_id: string,
  role: string,
  model: string,
): Promise<void> {
  const device: Device = await apiClient.getOrCreate<Device>(
    Device,
    {
      ...DEVICE_DEFAULT,
      device_id,
      model,
    },
    ['device_id', 'model'],
  );
  const deviceRelation: DeviceRelation = await apiClient.create<
    DeviceRelation,
    DeviceRelation
  >(DeviceRelation, {
    ...DEVICE_RELATION_DEFAULT,
    application,
    role,
    device: device.id,
  });

  deviceCreateArray.forEach(async deviceCreate => {
    const key = deviceCreate.setting_key || 'config';
    const value = deviceCreate.setting_value || { version: 1 };

    // create setting kind handle if it does not exist
    const settingKindHandle = deviceCreate.setting_kind_handle || 'default';
    const deviceSettingKind: DeviceSettingKind =
      await apiClient.getOrCreate<DeviceSettingKind>(
        DeviceSettingKind,
        {
          ...DEVICE_SETTING_KIND_DEFAULT,
          model,
          handle: settingKindHandle,
          name: settingKindHandle,
        },
        ['model', 'handle'],
      );

    // only create if it does not exist yet
    try {
      await Device.getSetting(apiClient, device.id, key);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // only post setting if it does not exist.
        await Device.setSetting(
          apiClient,
          device.id,
          key,
          value,
          deviceSettingKind.id,
        );
      } else {
        throw error;
      }
    }
  });
}

/**
 * Add everions to device settings of gateway
 * @param apiClient
 * @param application
 * @param gateway
 * @param everions
 * @param deviceSessionConfig
 */
export async function addEverions(
  apiClient: ApiClientV2,
  application: string,
  gateway: string,
  everions: string[],
  deviceSessionConfig: string,
) {
  // get device relations of everions
  const everionDeviceRelations = await Promise.all(
    everions.map(id => {
      return apiClient.get<DeviceRelation>(DeviceRelation, id);
    }),
  );

  // get everion devices
  const devices = await Promise.all(
    everionDeviceRelations.map(rel => {
      return apiClient.get<Device>(Device, rel.device);
    }),
  );

  // write all devices to config object
  const config = {
    version: 1,
    devices: {},
  };
  devices.forEach(device => {
    config.devices[device.device_id] = {
      application: application,
      session_config: deviceSessionConfig,
      device: device.id,
    };
  });
  // write to gateway device settings
  await Device.setSetting(apiClient, gateway, 'device-plugin-everion', config);
}

/**
 * Get everions that are assigned to selected gateway and to all other gateways
 * @param apiClient
 * @param application
 * @param gateway
 */
export async function getEverionAssignments(
  apiClient: ApiClientV2,
  application: string,
  gateway: string,
) {
  const context: Context = {
    filter: {
      application,
      role: 'gateway',
    },
    pagination: {
      page: 1,
      pageSize: 500,
    },
  };
  // get all device relations of gateways
  const gateways = await apiClient.getListItems<DeviceRelation>(
    DeviceRelation,
    context,
  );

  // get settings of all other gateways
  const settingOthers: (DeviceSetting | undefined)[] = await Promise.all(
    gateways
      .filter(rel => rel.device !== gateway)
      .map(rel => {
        return Device.getSetting(
          apiClient,
          rel.device,
          'device-plugin-everion',
        ).catch(error => {
          if (error.response && error.response.status === 404) {
            return undefined;
          } else {
            throw error;
          }
        });
      }),
  );

  // get everions assigned to other gateways
  const everionIdsOthers = [];
  settingOthers.forEach(setting => {
    if (setting?.value?.devices) {
      for (const key in setting.value.devices) {
        if (setting.value.devices[key].device) {
          everionIdsOthers.push(setting.value.devices[key].device);
        }
      }
    }
  });

  const everionRelationsOthers = await Promise.all(
    everionIdsOthers.map(async id => {
      try {
        return await apiClient.find<DeviceRelation>(DeviceRelation, {
          application,
          device: id,
          role: 'everion',
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // not found
          return Promise.resolve({ id: '' });
        } else {
          throw error;
        }
      }
    }),
  );

  // get settings of selected gateway
  const setting: DeviceSetting | undefined = await Device.getSetting(
    apiClient,
    gateway,
    'device-plugin-everion',
  ).catch(error => {
    if (error.response && error.response.status === 404) {
      return undefined;
    } else {
      throw error;
    }
  });

  // get selected everions
  const everionIds = [];
  if (setting?.value?.devices) {
    for (const key in setting.value.devices) {
      if (setting.value.devices[key].device) {
        everionIds.push(setting.value.devices[key].device);
      }
    }
  }

  const everionRelations = await Promise.all(
    everionIds.map(async id => {
      try {
        return await apiClient.find<DeviceRelation>(DeviceRelation, {
          application,
          device: id,
          role: 'everion',
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // not found
          return Promise.resolve({ id: '' });
        } else {
          throw error;
        }
      }
    }),
  );

  return {
    assigned: everionRelationsOthers.map(rel => rel.id),
    selected: everionRelations.map(rel => rel.id),
  };
}

/**
 * Get devices assigned to selected gateway.
 * If checkOthers is true, also get devices assigned to other gateways.
 * TODO: this does not scale to many devices
 * @param apiClient
 * @param application
 * @param gateway
 * @param role
 * @param devicePlugin the gateway device plugin, e.g. 'device-plugin-everion'
 * @param checkOthers whether to check for devices assigned to other gateways
 */
export async function getDeviceAssignments(
  apiClient: ApiClientV2,
  application: string,
  gateway: string,
  devicePlugin: string,
  checkOthers: boolean,
) {
  // get setting of selected gateway
  const setting = await Device.getSetting(
    apiClient,
    gateway,
    devicePlugin,
  ).catch(error => {
    if (error.response && error.response.status === 404) {
      return { value: {} };
    } else {
      throw error;
    }
  });

  // get assigned devices
  const deviceIds = [];
  if (setting?.value?.devices) {
    for (const key in setting.value.devices) {
      if (setting.value.devices[key].device) {
        deviceIds.push(setting.value.devices[key].device);
      }
    }
  }

  const deviceIdsOthers: string[] = [];
  if (checkOthers) {
    const context: Context = {
      filter: {
        application,
        role: 'gateway',
      },
      pagination: {
        page: 1,
        pageSize: 500,
      },
    };
    // get all device relations of gateways
    const gateways = await apiClient.getListItems<DeviceRelation>(
      DeviceRelation,
      context,
    );
    const otherGateways = gateways.filter(rel => rel.device !== gateway);
    const settingOthers: DeviceSetting[] = [];

    const limiter = new PromiseConcurrencyLimiter();
    for (const g of otherGateways) {
      await limiter.add(
        Device.getSetting(apiClient, g.device, devicePlugin)
          .then(s => settingOthers.push(s))
          .catch(error => {
            if (error.response && error.response.status === 404) {
              return {};
            } else {
              throw error;
            }
          }),
      );
    }
    await limiter.awaitAllAndRethrow();

    // get devices assigned to other gateways
    settingOthers.forEach(setting => {
      if (setting?.value?.devices) {
        for (const key in setting.value.devices) {
          if (setting.value.devices[key].device) {
            deviceIdsOthers.push(setting.value.devices[key].device);
          }
        }
      }
    });
  }

  return {
    assigned: deviceIdsOthers,
    selected: deviceIds,
  };
}

export async function deviceIdsToDeviceRelations(
  application: string,
  deviceIds: string[],
): Promise<DeviceRelation[]> {
  const deviceRelations: DeviceRelation[] = [];
  const limiter = new PromiseConcurrencyLimiter();

  for (const d of deviceIds) {
    await limiter.add(
      apiClientV2
        .find<DeviceRelation>(DeviceRelation, {
          application,
          device: d,
        })
        .then(r => deviceRelations.push(r))
        .catch(error => {
          if (error.response && error.response.status === 404) {
            return {};
          } else {
            throw error;
          }
        }),
    );
  }
  await limiter.awaitAllAndRethrow();
  return deviceRelations;
}

/**
 * Add devices to gateway device plugin config
 * @param apiClient
 * @param application
 * @param gateway
 * @param deviceRelationIds
 * @param deviceSessionConfig
 * @param devicePlugin
 */
export async function addDevices(
  apiClient: ApiClientV2,
  application: string,
  gateway: string,
  deviceRelationIds: string[],
  deviceSessionConfig: string,
  devicePlugin: string,
) {
  // get devices assigned to selected gateway
  const limiter = new PromiseConcurrencyLimiter();
  const deviceRelations: DeviceRelation[] = [];
  for (const id of deviceRelationIds) {
    await limiter.add(
      apiClient
        .get<DeviceRelation>(DeviceRelation, id)
        .then(r => deviceRelations.push(r)),
    );
  }
  await limiter.awaitAllAndRethrow();
  const devices: Device[] = [];
  for (const r of deviceRelations) {
    await limiter.add(
      apiClient.get<Device>(Device, r.device).then(d => devices.push(d)),
    );
  }
  await limiter.awaitAllAndRethrow();

  let currentValue = { version: 1 };
  try {
    // get current setting (to not overwrite other config)
    const pluginSetting = await Device.getSetting(
      apiClient,
      gateway,
      devicePlugin,
    );
    currentValue = pluginSetting.value;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      // setting does not exist -> continue
    } else {
      throw error;
    }
  }

  // write devices to new object
  const configDevices = {};
  devices.forEach(device => {
    configDevices[device.device_id] = {
      application: application,
      session_config: deviceSessionConfig,
      device: device.id,
    };
  });
  const config = {
    ...currentValue,
    devices: configDevices,
  };
  // write to gateway device settings
  await Device.setSetting(apiClient, gateway, devicePlugin, config);
}

export async function getDeviceSessionConfig(
  apiClient: ApiClientV2,
  clientAppId: string,
  role: string,
): Promise<string> {
  // get client app settings
  const setting = await ClientApp.getSetting(
    apiClientV2,
    clientAppId,
    'device_models',
  );
  // get device model from settings
  const deviceModels: DeviceModel[] = setting?.value?.device_models || [];
  const deviceModel = deviceModels.find(deviceModel => {
    return deviceModel.role === role;
  });
  return deviceModel?.device_session_config || '';
}
